<template>
  <mdb-navbar class="flexible-navbar main-navbar" dark color="black" position="top"> 
    <mdb-navbar-brand href="/" class="text-center">
      <img :src="'../assets/tl-design-district-logo-white.png'" height="30" alt="" class="ml-2">
    </mdb-navbar-brand>

    <!-- <mdb-navbar-nav>
      <mdb-nav-item href="/users" active>Users</mdb-nav-item>
      <mdb-nav-item href="/items" active>Items</mdb-nav-item>
      <mdb-nav-item href="#">Link</mdb-nav-item>
      <mdb-nav-item href="#">Disabled</mdb-nav-item>
    </mdb-navbar-nav> -->


    <mdb-navbar-nav class="right-nav" right>
      <mdb-nav-item>
       <span class="clearfix d-none d-sm-inline-block">{{userInformation}}</span>
      </mdb-nav-item>
      
     <!--  
      <mdb-nav-item>
        <mdb-icon far icon="comments" /><span class="clearfix d-none d-sm-inline-block">Support</span>
      </mdb-nav-item> -->
      <!-- <mdb-nav-item @click="fnClickNotification">
                1
                <mdb-icon far icon="bell" />
              </mdb-nav-item> -->
      <mdb-dropdown class="nav-item" tag="li">
        <mdb-dropdown-toggle slot="toggle" tag="li" outline="none" class="btn-transparent user-avatar-icon">
          <mdb-icon far icon="user-circle" color="white"/>
        </mdb-dropdown-toggle>
        <mdb-dropdown-menu style="left:-110px!important">
          <mdb-dropdown-item>My account</mdb-dropdown-item>
          <mdb-dropdown-item @click="generateQrCode">Generate QR Code</mdb-dropdown-item>
          <mdb-dropdown-item @click="generateSignature">Digital Signature</mdb-dropdown-item>
          <mdb-dropdown-item @click="Logout">Log Out</mdb-dropdown-item>
        </mdb-dropdown-menu>
      </mdb-dropdown>

      
    </mdb-navbar-nav>
  </mdb-navbar>
</template>

<script>
import { mdbNavbar, mdbNavbarBrand, mdbNavItem, mdbNavbarNav, mdbNavbarToggler, mdbBreadcrumb, mdbDropdown, mdbDropdownToggle, mdbDropdownMenu, mdbDropdownItem, mdbIcon, mdbBadge } from 'mdbvue'
import { mapActions } from 'vuex';
import { userData } from '../helpers/user'
export default {
  name: 'Navbar',
  props: {
    page: {
      type: String
    }
  },
  components: {
    mdbNavbar,
    mdbNavbarBrand,
    mdbNavItem,
    mdbNavbarNav,
    mdbNavbarToggler,
    mdbBreadcrumb,
    mdbDropdown,
    mdbDropdownToggle,
    mdbDropdownMenu,
    mdbDropdownItem,
    mdbIcon,
    mdbBadge
  },
  mounted(){
    this.setUser()
  },
  data () {
    return {
      activePage: this.page,
      toggle: false,
      user : null
    }
  },
  methods: {
    ...mapActions('AuthModule',[
      'Logout'
    ]),
    async setUser(){
       this.user = await userData()
        
    },
    generateQrCode(){
        this.$root.$emit('generate');
    },
    generateSignature(){
        const localStorageItem = JSON.parse(localStorage.getItem('user'));
        const row = localStorageItem.user;
        this.$root.$emit('generate-signature', row);
    },
    fnClickNotification(){
      this.$emit('openNofication')
    }
  },
  computed: {
    computedPage () {
      return {
        activePage: this.page
      }
    },

    userInformation : function(){
      if(this.user != null){
        return `Hi, ${this.user.user.name} | Welcome to ${this.user.location.name}`;
      }
      return 'Hi,{user} Welcome to {location}';
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.navbar .nav-item {
  line-height: 24px;
  font-size: 16px;
}
.navbar .dropdown-toggle {
  padding: 0 10px;
  font-size: 16px;
}

.navbar ul {
  padding: 5px 0;
}

.navbar .btn {
  font-weight: 300;
}

.navbar-collapse {
  order: 0 !important;
}

.navbar .notifications-nav .dropdown-menu {
  width: 23rem; }

.navbar-expand-lg .navbar-nav {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
}

.btn-transparent {
  background-color: transparent !important;
  color: #000;
  text-transform: none;
  box-shadow: none !important;
}

.btn-transparent:hover, .btn-transparent:active {
  box-shadow: none !important;
}

.main-navbar{
  padding : 0!important
}

.main-navbar ul{
  padding: 0!important;
}

.user-avatar-icon{
  padding: .5rem 1rem!important
}


</style>

/* eslint-disable no-prototype-builtins */
<template>
    <div class="invoice-form-container">
        <ul id="right-click-menu" tabindex="-1" ref="right" v-if="viewMenu" v-on:blur="closeMenu"  v-bind:style="{top:top, left:left}">
            <li  @click="insertRow">Insert</li>
            <template v-if="form.items.length > 2">
                <li @click="moveUpRow">Move Up</li>
                <li @click="moveDownRow">Move Down</li>
            </template>
            <li @click="deleteRow">Delete</li>
        </ul>
        <ValidationObserver class="needs-validation form-custom-format " v-bind:class="{'was-validated' : validated}" tag="form" ref="observer" v-slot="{passes}" @submit.prevent="onSubmitForm()">
            <ThatsWindow ref="window" title="Sales Transaction" :parentContainerStyle="parentContainerStyle" :windowClass="windowClass" scrollClass='collection height-auto' @deleteYes="emitDelete" :height="800" :width="1300" :formStatus="formStatus" @dialog-state="dialogState" :group="group" @history="openHistory">
                <template #left-button>
                    <template v-if="formLoading">
                        <mdb-btn color="elegant" size="sm" disabled>
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            Loading...
                        </mdb-btn>
                    </template>
                    <template v-else>



                        <template v-if="(formStatus == 'update' || formStatus == 'create') && returnItem === false && modify === false && cancelItem === false && (active === 0 || active === 2)">
                            <mdb-btn color="elegant" size="sm" @click="passes(onSubmitForm)" :disabled="viewLoading || requestedCancel || requestedExchange || refundRequested || exchangeItemLoading || formOriginal.status === 'cancel'">Save</mdb-btn>
                            <mdb-btn color="elegant" size="sm" @click="issueVoucher" :disabled="viewLoading || requestedCancel || requestedExchange || refundRequested || exchangeItemLoading || formOriginal.status === 'cancel'">ISSUE VOURCHER</mdb-btn>
                            <mdb-btn color="elegant" size="sm" @click="requestDelivery" :disabled="viewLoading || requestedCancel || requestedExchange || refundRequested || exchangeItemLoading || formOriginal.status === 'cancel'">Request Delivery</mdb-btn>
                        </template>
                        <template v-if="(formStatus == 'update' || formStatus == 'create') && returnItem === false && modify === true && active === 0">
                            <mdb-btn color="elegant" size="sm" @click="passes(onSubmitForm)" :disabled="viewLoading || requestedCancel || requestedExchange || refundRequested || exchangeItemLoading">Save Changes</mdb-btn>
                        </template>
                        <template v-if="selectedTbo.length > 0  && active === 0">
                            <mdb-btn color="elegant" size="sm" @click="passes(onSubmitForm)" :disabled="viewLoading || requestedCancel || requestedExchange || refundRequested || exchangeItemLoading">Save & Create Customer PO</mdb-btn>
                        </template>
                        <template v-if="formStatus == 'update'">
                            <template v-if="invoice.payments.length > 0">
                                <mdb-btn @click="fnPrint('/sales/invoice/'+form.token_id)" color="elegant" size="sm" :disabled="viewLoading || formOriginal.status === 'cancel'">Print Invoice</mdb-btn>
                            </template>
                            <template v-else>
                                <mdb-btn @click="fnPrint('/sales/transactions/'+form.token_id)" color="elegant" size="sm" :disabled="viewLoading">Print Quotation</mdb-btn>
                            </template>
                        </template>
                    </template>

                    <template v-if="selected.length > 0 && modify === true && returnItem === false">
                        <mdb-btn color="elegant" size="sm" @click="deleteItem">Delete</mdb-btn>
                    </template>
                </template>

                <template #right-button>

                    <!-- <template v-if="formStatus == 'update' && $helpers.isSalesExecutive()">

              </template>
              <template v-else> -->
                    <template v-if="invoice.series === null || parseFloat(invoice.balance) > 0 && modify === false && returnItem === false && cancelItem === false  && exchangeItem === false && (active === 0  || active === 1)">
                        <mdb-btn color="elegant" size="sm" @click="openPaymentWindow" :disabled="(viewLoading || formLoading || requestedCancel || refundRequested || requestedExchange || formOriginal.status === 'cancel')">Make Payment</mdb-btn>
                    </template>
                    <!-- <template v-if="invoice.series !== null && checkSystemSupport && modify === false && returnItem === false && cancelItem === false  && exchangeItem === false && (active === 0  || active === 1)">
                  <mdb-btn color="elegant" size="sm" @click="openPaymentWindow" :disabled="(viewLoading || formLoading || requestedCancel || refundRequested || requestedExchange)">Add Payment</mdb-btn>
                </template> -->
                    <template v-if="invoice.series !== null && (checkSystemSupport) && modify === false && cancelItem === false && returnItem === false && exchangeItem === false && active === 0">
                        <mdb-btn color="elegant" size="sm" @click="enableModify" :disabled="(viewLoading || formLoading || requestedCancel || refundRequested || requestedExchange || formOriginal.status === 'cancel')">Modify</mdb-btn>
                        <!-- <template v-if="form.enable_push === true && (form.attr.hasOwnProperty('document') && (form.attr.document.entry === null || form.attr.document.entry === '') && ($helpers.isSystemAdministrator() || $helpers.isAccounting()))">

                    <template v-if="pushInvoiceToSapLoading">
                      <mdb-btn color="elegant" size="sm" disabled>
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Loading...
                      </mdb-btn>
                    </template>
                    <template v-else>
                        <mdb-btn color="elegant" size="sm" @click="pushToSap" :disabled="(viewLoading || formLoading || requestedCancel || refundRequested || requestedExchange || formOriginal.status === 'cancel')">Push to SAP</mdb-btn>
                    </template>
                  </template>
                  <template v-else>
                      <mdb-btn color="elegant" size="sm" :disabled="true">Push to SAP</mdb-btn>
                  </template> -->
                    </template>



                    <template v-if="invoice.series !== null && checkSystemSupport && active === 3">

                        <!-- <mdb-btn color="elegant" size="sm" :disabled="true">Modify Credit Note</mdb-btn> -->

                        <template v-if="modify === false && returnItem === false && modifyReturmItem === false && pullout === false">
                            <mdb-btn color="elegant" size="sm" @click="enablePullOut" :disabled="viewLoading || modifyReturmItem">Pull-out Order</mdb-btn>
                        </template>
                        <template v-if="modify === false && returnItem === false && modifyReturmItem === false && pullout === true">
                            <mdb-btn color="elegant" size="sm" @click="requestPullOut" :disabled="formLoading">Request Pull-Out</mdb-btn>
                        </template>
                    </template>
                    <template v-if="invoice.series !== null && (checkSystemSupport || checkShowroomManager) && (modify === true || modifyReturmItem === true ) && active === 3">
                        <mdb-btn color="elegant" size="sm" @click="passes(fnUpdateCreditNote)" :disabled="(viewLoading || formLoading)">Save Credit Note</mdb-btn>
                        <mdb-btn color="elegant" size="sm" @click="cancelModification" :disabled="(viewLoading || formLoading)">Cancel</mdb-btn>
                    </template>


                    <template v-if="(invoice.series !== null && (enableReturn.includes(formOriginal.status) && (formOriginal.attr.payment_status === 'partial-payment' || formOriginal.attr.payment_status === 'full-payment'))) && (checkSystemSupport || checkShowroomManager || checkSalesExecutive || $helpers.isInteriorDesigner()) && modify === false && returnItem === false && cancelItem === false && exchangeItem === false && active === 0">
                        <mdb-btn color="elegant" size="sm" :disabled="(viewLoading || formLoading || requestedCancel || requestedExchange || formOriginal.status === 'cancel')" @click="returnItems">Return</mdb-btn>
                    </template>
                    <!--
                <template v-if="(invoice.series !== null && (cancelItemEnable.includes(formOriginal.status) && ['partial-payment','full-payment'].includes(formOriginal.attr.payment_status))) && (checkSystemSupport || checkShowroomManager || checkSalesExecutive) && modify === false && returnItem === false && cancelItem === false && exchangeItem === false && refundItem === false && active === 0">
                  <mdb-btn color="elegant" size="sm" :disabled="(viewLoading || formLoading || requestedCancel || requestedExchange)" @click="cancelItems">Cancel Item</mdb-btn>
                </template> -->

                    <template v-if="(invoice.series !== null && (enableReturn.includes(formOriginal.status) && (formOriginal.attr.payment_status === 'partial-payment' || formOriginal.attr.payment_status === 'full-payment'))) && (checkSystemSupport || checkShowroomManager || checkSalesExecutive || $helpers.isInteriorDesigner()) && returnItem === true && modify === false && cancelItem === false && exchangeItem === false &&  refundItem === false &&active === 0">
                        <mdb-btn color="elegant" size="sm" :disabled="(hasSelected || viewLoading || formLoading)" @click="passes(requestCreditNote)">Request Credit Note</mdb-btn>

                        <!--    <mdb-dropdown class="thats-dropdown" dropup>
                        <mdb-dropdown-toggle class="mt-2" slot="toggle" color="elegant" size="sm" :disabled="(hasSelected || viewLoading || formLoading)">Request Credit Note</mdb-dropdown-toggle>
                        <mdb-dropdown-menu class="inactive-reactive-menu">
                            <mdb-dropdown-item @click="requestCreditNote">Exchange</mdb-dropdown-item>
                            <div class="dropdown-divider"></div>
                            <mdb-dropdown-item @click="emitDelete">Refund</mdb-dropdown-item>
                        </mdb-dropdown-menu>
                    </mdb-dropdown> -->

                        <mdb-btn color="elegant" size="sm" :disabled="(viewLoading || formLoading)" @click="cancelModification">Cancel</mdb-btn>
                    </template>


                    <!--   <template v-if="(invoice.series !== null && (exchangeItemEnable.includes(formOriginal.status) && ['partial-payment','full-payment'].includes(formOriginal.attr.payment_status))) && (checkSystemSupport || checkShowroomManager || checkSalesExecutive) && modify === false && returnItem === false && cancelItem === false && exchangeItem === false && refundItem === false && active === 0">
                  <mdb-btn color="elegant" size="sm" :disabled="(viewLoading || formLoading || requestedExchange)" @click="exchangeItems">Exchange Item</mdb-btn>
                </template>

                <template v-if="(invoice.series !== null && (refundItemEnable.includes(formOriginal.status) && ['partial-payment','full-payment'].includes(formOriginal.attr.payment_status))) && (checkSystemSupport || checkShowroomManager || checkSalesExecutive) && modify === false && returnItem === false && cancelItem === false && exchangeItem === false && refundItem === false && active === 0">
                  <mdb-btn color="elegant" size="sm" :disabled="(viewLoading || formLoading || requestedExchange)" @click="refundItems">Refund Item</mdb-btn>
                </template>
 -->


                    <!-- <template v-if="cancelItemLoading">
                  <mdb-btn color="elegant" size="sm" disabled>
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Loading...
                  </mdb-btn>
                </template>
                <template v-else>
                  <template v-if="(invoice.series !== null && (cancelItemEnable.includes(formOriginal.status) &&  ['partial-payment','full-payment'].includes(formOriginal.attr.payment_status))) && (checkSystemSupport || checkShowroomManager || checkSalesExecutive) && returnItem === false && modify === false && cancelItem === true && active === 0">
                    <mdb-btn color="elegant" size="sm" :disabled="(hasSelected || viewLoading || formLoading || cancelItemLoading)" @click="requestCancelItem">Request Item Cancel</mdb-btn>
                    <mdb-btn color="elegant" size="sm" :disabled="(viewLoading || formLoading || cancelItemLoading)" @click="cancelModification">Cancel</mdb-btn>
                  </template>
                </template>
 -->


                    <!-- <template v-if="exchangeItemLoading">
                  <mdb-btn color="elegant" size="sm" disabled>
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Loading...
                  </mdb-btn>
                </template>
                <template v-else>
                 <template v-if="(invoice.series !== null && (exchangeItemEnable.includes(formOriginal.status) && (formOriginal.attr.payment_status === 'partial-payment' || formOriginal.attr.payment_status === 'full-payment'))) && (checkSystemSupport || checkShowroomManager || checkSalesExecutive) && returnItem === false && modify === false && cancelItem === false && exchangeItem === true && active === 0">
                  <mdb-btn color="elegant" size="sm" :disabled="(hasSelected || viewLoading || formLoading || exchangeItemLoading)" @click="requestExchange">Request Item Exchange</mdb-btn>
                  <mdb-btn color="elegant" size="sm" :disabled="(viewLoading || formLoading || exchangeItemLoading)" @click="cancelModification">Cancel</mdb-btn>
                </template>
                </template>
 -->

                    <!--   <template v-if="refundItemLoading">
                  <mdb-btn color="elegant" size="sm" disabled>
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Loading...
                  </mdb-btn>
                </template>
                <template v-else>
                 <template v-if="(invoice.series !== null && (refundItemEnable.includes(formOriginal.status) && (formOriginal.attr.payment_status === 'partial-payment' || formOriginal.attr.payment_status === 'full-payment'))) && (checkSystemSupport || checkShowroomManager || checkSalesExecutive) && returnItem === false && modify === false && cancelItem === false && exchangeItem === false && refundItem === true && active === 0">
                  <mdb-btn color="elegant" size="sm" :disabled="(hasSelected || viewLoading || formLoading || refundItemLoading)" @click="requestRefund">Request Item Refund</mdb-btn>
                  <mdb-btn color="elegant" size="sm" :disabled="(viewLoading || formLoading || refundItemLoading)" @click="cancelModification">Cancel</mdb-btn>
                </template>
                </template> -->

                    <!--   </template> -->

                    <template v-if="invoice.payments.length> 0">
                        <span class="invoice-balance">Total Balance : {{ $helpers.addSeparatorsNF(invoice.balance)}} QAR</span>
                    </template>


                </template>
                <template v-if="invoice.payments.length> 0">
                    <span class="invoice-number">Sales Invoice #: {{invoice.series}}</span>
                </template>
                <div class="mt-1 pl-3 pr-3">
                    <mdb-tabs class="table-tabs mb-0 form" :active="active" default :links="tabs" @activeTab="getActiveTabIndex" >
                        <template :slot="'form-slot'">

                            <mdb-row class="tab-row">
                                <mdb-col col="6" class="tab-col pl-0">
                                    <template v-if="form !== null && form.hasOwnProperty('invoice') && form.invoice.date !== null">
                                        <ThatsDatePicker :show_error="showError" tabindex="1" labelClass="col-md-4" fieldClass="col-md-8 pl-0" label="Date" placeholder="Date" vid="date" name="date" v-model="form.invoice.date" :validated="validated" :rules="'required'" size="sm" :disabled="disabled"/>
                                    </template>
                                    <template v-if="(modify === true && $helpers.isSystemAdministrator())">
                                        <ThatsInput tabindex="2" labelClass="col-sm-4" fieldClass="col-sm-8 pl-0" label="Customer" vid="name" name="name" v-model="form.attr.name" size="sm" :disabled="true"/>
                                        <a href="javascript:void(0)" @click="editCustomer" class="edit">Edit</a>
                                    </template>
                                    <template v-else>
                                        <ThatsCustomMultiSelect :multiple="false"  labelClass="col-md-4" fieldClass="col-md-8  pl-0" label="Customer" v-model="form.customer_id" :reduce="customer => customer.value" :reduceLabel="'opt'" :options="customer" :rules="'required'" :clearable="false" @select="getCustomerSelected($event)" :addButton="true" @newWindow="openCustomer" @onSearch="searchCustomer" :selected_option="true" :disabled="true" ></ThatsCustomMultiSelect>


                                    </template>
                                    <ThatsInput tabindex="2" labelClass="col-sm-4" fieldClass="col-sm-8 pl-0" label="Customer Mobile No." vid="mobile" name="mobile" v-model="form.attr.mobile" size="sm" :disabled="!editCustomerField"/>
                                    <ThatsInput tabindex="2" labelClass="col-sm-4" fieldClass="col-sm-8 pl-0" label="Customer Address" vid="address" name="address" v-model="displayAddress" size="sm" :disabled="true" :showEditBtn="allowEditAddress" @open="openAddressForm"/>
                                    <ThatsInput tabindex="2" labelClass="col-sm-4" fieldClass="col-sm-8 pl-0" label="Contact Person" vid="contact_person" name="contact_person" v-model="contactPersonName" size="sm" :disabled="true"/>


                                    <!--        <ThatsTextarea tabindex="2" labelClass="col-sm-4" fieldClass="col-sm-8 pl-0" label="Instructions" vid="instructions" name="instructions" v-model="form.attr.instructions" size="sm" :disabled="disabled" :rows="2"/> -->

                                </mdb-col>
                                <mdb-col col="6" class="tab-col pl-0 pr-0">
                                    <ThatsSeriesInput :show_error="showError" tabindex="1" labelClass="col-md-4" fieldClass="col-md-8 pl-0" label="Transaction ID" placeholder="Transaction ID" vid="series" name="series" v-model="form.series" :validated="validated" :rules="''" :keycode="false" size="sm" :disabled="true" />
                                    <mdb-row>
                                        <mdb-col sm="4" class="tab-col">
                                            <label v-bind:class="'col-form-label'">Sold By</label>
                                        </mdb-col>
                                        <mdb-col sm="4" class="tab-col">
                                            <ThatsInput :showLabel="false" tabindex="2" labelClass="col-sm-6" fieldClass="col-sm-12 pl-0" label="Sold By" :placeholder="form.sold_by" vid="sold_by" name="sold_by" v-model="form.sold_by" size="sm" :disabled="true" />
                                        </mdb-col>
                                        <mdb-col sm="4" class="tab-col">
                                            <ThatsMultiSelect :multiple="true"  labelClass="col-md-4" fieldClass="col-md-8  pl-0" label="partner" v-model="form.attr.partner" :reduce="label => label.code" :reduceLabel="'label'" :options="partner" :rules="''" :filterable="false" :clearable="false" ></ThatsMultiSelect>
                                        </mdb-col>
                                    </mdb-row>
                                    <ThatsMultiSelect :multiple="false"  labelClass="col-md-4" fieldClass="col-md-8  pl-0" label="Customer Service" v-model="form.attr.customer_service" :reduce="label => label.label" :reduceLabel="'label'" :options="customerService" :rules="''" :disabled="noInvoiceDisabled"></ThatsMultiSelect>

                                    <ThatsMultiSelect :multiple="false"  labelClass="col-md-4" fieldClass="col-md-8  pl-0" label="Order Status" v-model="form.status" :reduce="label => label.code" :reduceLabel="'label'" :options="status" :rules="'required'" :filterable="false" :clearable="false" :disabled="noInvoiceDisabled" :selectable="orderStatusSelectable"></ThatsMultiSelect>

                                    <template v-if="form.status === 'for-delivery-schedule'">
                                        <template v-if="form.hasOwnProperty('delivery_schedule')">
                                            <ThatsDatePicker :show_error="showError" tabindex="1" labelClass="col-md-4" fieldClass="col-md-8 pl-0" label="Date Request" placeholder="Date Request" vid="date_request" name="date_request" v-model="form.delivery_schedule.date" :validated="validated" :rules="'required'" size="sm" :disabled="noInvoiceDisabled"/>
                                            <ThatsInput tabindex="2" labelClass="col-sm-4" fieldClass="col-sm-8 pl-0" label="Delivery Remarks" :placeholder="form.attr.delivery_remarks" vid="delivery_remarks" name="delivery_remarks" v-model="form.delivery_schedule.remarks" size="sm" :disabled="noInvoiceDisabled" />
                                        </template>
                                    </template>
                                    <template v-if="form.status ==='to-be-order'">
                                        <ThatsDatePicker :show_error="showError" tabindex="1" labelClass="col-md-4" fieldClass="col-md-8 pl-0" label="Expected Date" placeholder="Expected Date" vid="expected_date" name="expected_date" v-model="form.attr.expected_date" :validated="validated" :rules="'required'" size="sm" :disabled="noInvoiceDisabled"/>
                                        <ThatsInput tabindex="2" labelClass="col-sm-4" fieldClass="col-sm-8 pl-0" label="TBO Details" :placeholder="form.attr.tbo_details" vid="tbo_details" name="tbo_details" v-model="form.attr.tbo_details" size="sm" :disabled="noInvoiceDisabled" />
                                    </template>

                                    <template v-if="form.status === 'cancel'">
                                        <ThatsTextarea tabindex="2" labelClass="col-sm-4" fieldClass="col-sm-8 pl-0" label="Remarks" vid="description" name="description" v-model="form.description" size="sm" :disabled="false" :rules="'required'"/>
                                    </template>
                                    <template v-if="$helpers.isSystemAdministrator()">
                                        <ThatsTextarea tabindex="2" labelClass="col-sm-4" fieldClass="col-sm-8 pl-0" label="Instructions" vid="instructions" name="instructions" v-model="form.attr.instructions" size="sm" :disabled="disabled" :rows="2"/>
                                    </template>
                                    <template v-else>
                                        <ThatsMultiSelect  :multiple="false" labelClass="col-md-4" fieldClass="col-md-8  pl-0" label="Instructions"  vid="instructions" name="instructions" v-model="form.attr.instructions" :reduce="label => label.code" :reduceLabel="'label'" :options="instructions" :rules="''" :disabled="disabled"></ThatsMultiSelect>
                                    </template>
                                </mdb-col>
                            </mdb-row>
                            <template v-if="returnItem === true">
                                <mdb-row class="tab-row">
                                    <mdb-col col="6" class="tab-col pl-0">
                                        <ThatsDatePicker :show_error="showError" tabindex="1" labelClass="col-md-4" fieldClass="col-md-8 pl-0" label="Return Date" placeholder="Date" vid="return_date" name="return_date" v-model="returnForm.date" :validated="validated" :rules="'required'" size="sm"/>
                                    </mdb-col>
                                </mdb-row>
                            </template>
                            <el-table id="itemTable" ref="itemTable" :key="tblKey" border :data="form.items" class="input-table mt-2 itemTable" style="width: 100%" @selection-change="handleSelectionChange" :summary-method="getSummaries" show-summary :cell-class-name="cell" @row-contextmenu="rowContectMenu" v-mdb-click-outside="handleOutsideClick">
                                <template v-if="showCheckBox">
                                    <el-table-column type="selection" width="35" :selectable="canSelectRow"> </el-table-column>


                                </template>
                                <el-table-column type="index" width="50" label="SQ" align="center"> </el-table-column>
                                <el-table-column label="SKU" :width="150">
                                    <template slot-scope="scope">
                                        <template v-if="disabled">
                                            {{scope.row.code}}
                                        </template>
                                        <template v-else>
                                            <GridVueSelect v-model="scope.row.code" :reduce="item => item.code" :reduceLabel="'code'" :options="items" :filterable="false" :clearable="false" @onSearch="statusKeyUp" @onFocus="statusKeyUpFocus" @select="getSelected($event,scope.$index)" :disabled="disabled"></GridVueSelect>
                                        </template>

                                    </template>
                                </el-table-column>
                                <el-table-column label="Image" width="90">
                                    <template slot-scope="scope">
                                        <!-- <img :src="scope.row.default" style="width:100%" /> -->
                                        <img :src="scope.row.default" style="width:100%" @click="clickShowSearch(scope.row,scope.$index)" />
                                        <template v-if="scope.row.show_search">
                                            <div class="search-fabric">
                                                <label>Enter/Scan Fabric Code</label>
                                                <button type="button" class="close" aria-label="Close" @click="closeFabric(scope.$index)">
                                                    <span aria-hidden="true">×</span>
                                                </button>
                                                <GridFabricSelect :reduce="item => item.id" :reduceLabel="'id'" :options="fabrics" :filterable="false" :clearable="false" @onSearch="statusKeyUpFabric" @select="getSelectedFabric($event,scope.$index,scope.row)"></GridFabricSelect>
                                            </div>
                                        </template>
                                    </template>
                                </el-table-column>
                                <el-table-column label="Item Name" :row-class-name="''">
                                    <template slot-scope="scope">
                                        <template v-if="disabled">

                                            {{scope.row.name}}
                                        </template>
                                        <template v-else>
                                            <input type="text" class="form-control grid-input thats-form-control" v-model="scope.row.name" @keydown.enter="addNewRow(scope.$index)" :disabled="disabled" />
                                        </template>


                                    </template>
                                </el-table-column>
                                <template v-if="form.invoice.series === null">
                                    <el-table-column label="Qty" :width="70" align="right">
                                        <template slot-scope="scope">
                                            <template v-if="enableQuantity(scope.row)">

                                                {{scope.row.qty}}
                                            </template>
                                            <template v-else>
                                                <input type="number" min="1" class="form-control grid-input thats-form-control text-right" v-on:change="quantityChangeOnReturn(scope.row,$event)" v-model="scope.row.qty" @keyup="calculate(scope.$index,$event)" @change="calculate(scope.$index,$event)" @keydown.enter="addNewRow(scope.$index)" :disabled="enableQuantity(scope.row)" />
                                            </template>

                                        </template>

                                    </el-table-column>
                                    <el-table-column label="Price" :width="100" align = "right">
                                        <template slot-scope="scope">
                                            <input type="text" class="form-control grid-input thats-form-control text-right" v-model="scope.row.formatted_price" @keydown.enter="addNewRow(scope.$index)" :disabled="enablePrice(scope.row)" />
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="Amount" :width="100" align = "right">
                                        <template slot-scope="scope">
                                            <input type="text" class="form-control grid-input thats-form-control text-right" v-model="scope.row.amount" @keydown.enter="addNewRow(scope.$index)" :disabled="true" />
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="Discount" :width="100" align = "right">
                                        <template slot-scope="scope">
                                            <span v-html="scope.row.discount"></span>
                                            <template v-if="scope.row.code !== null && scope.row.name !== null && disabled === false">
                                                <a href="javascript:void(0)" class="discount-a" @click="openDiscount(scope.$index,scope.row)"><mdb-icon icon="ellipsis-v" /></a>
                                            </template>
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="Total Amount" :width="120" align = "right">
                                        <template slot-scope="scope">
                                            <input type="text" class="form-control grid-input thats-form-control text-right" v-model="scope.row.total" @keydown.enter="addNewRow(scope.$index)" :disabled="true" />
                                        </template>
                                    </el-table-column>
                                </template>
                                <template v-else>
                                    <el-table-column label="Qty" :width="70" align="right">
                                        <template slot-scope="scope">
                                            <template v-if="enableQuantity(scope.row)">
                                                {{scope.row.qty}}
                                            </template>
                                            <!--  <template v-else><input type="number" min="1" class="form-control grid-input thats-form-control text-right" v-model="scope.row.qty" @keyup="calculate(scope.$index)" @change="calculate(scope.$index)" @keydown.enter="addNewRow(scope.$index)" :disabled="enableModifyAndCreditNote" /> -->
                                            <template v-else>
                                                <input type="number" min="1" class="form-control grid-input thats-form-control text-right" v-on:change="quantityChangeOnReturn(scope.row,$event)" v-model="scope.row.qty" @keyup="calculate(scope.$index,$event)" @change="calculate(scope.$index,$event)" @keydown.enter="addNewRow(scope.$index)" :disabled="enableQuantity(scope.row)" />

                                            </template>
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="Price" :width="100" align = "right">
                                        <template slot-scope="scope">

                                            <input type="text" class="form-control grid-input thats-form-control text-right" v-model="scope.row.formatted_price" :disabled="!enablePrice(scope.row)" @keydown.enter="addNewRow(scope.$index)"  @input="calculatePrice(scope.$index,$event)" @focus="removeFormat(scope.$index)" @blur="formatOnBlur(scope.$index)"/>

                                        </template>
                                    </el-table-column>
                                    <el-table-column label="Amount" :width="100" align = "right">
                                        <template slot-scope="scope">
                                            <input type="text" class="form-control grid-input thats-form-control text-right" v-model="scope.row.amount" :disabled="true" />
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="Discount" :width="100" align = "right">
                                        <template slot-scope="scope">
                                            <span v-html="scope.row.discount"></span>
                                            <template v-if="scope.row.code !== null && scope.row.name !== null && disabled === false">
                                                <a href="javascript:void(0)" class="discount-a" @click="openDiscount(scope.$index,scope.row)"><mdb-icon icon="ellipsis-v" /></a>
                                            </template>
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="Total Amount" :width="120" align = "right">
                                        <template slot-scope="scope">
                                            <input type="text" class="form-control grid-input thats-form-control text-right" v-model="scope.row.total" :disabled="true" />
                                        </template>
                                    </el-table-column>
                                </template>

                                <template v-if="returnItem === true">
                                    <el-table-column :width="90" label="Delivered">
                                        <!--   <template slot-scope="{}" slot="header">
                                  <el-checkbox :indeterminate="isIndeterminateDelivered" v-model="checkAllDelivered" @change="handleCheckAllChangeDelivered">Delivered</el-checkbox>
                                </template> -->
                                        <template slot-scope="scope">
                                            <ValidationProvider :rules="(checkIfRowSelected(scope.row.id) === true ? 'required' : '')" :vid="`delivery-${scope.row.id}`" :name="`delivery-${scope.row.id}`" v-slot="{ errors }">
                                                <select class="thats-form-control" :key="scope.row.id" v-model="scope.row.delivered">
                                                    <option value=""></option>
                                                    <option value="false">No</option>
                                                    <option value="true">Yes</option>
                                                </select>

                                                <span class="text-sm small text-red block text-danger ">{{ errors[0] }}</span>
                                            </ValidationProvider>
                                            <!-- <el-checkbox v-model="scope.row.delivered" style="margin-left:6px" @change="handleCheckedTboChangeDelivered">Delivered</el-checkbox> -->
                                        </template>
                                    </el-table-column>
                                </template>

                                <el-table-column :label="(refundItem === true || returnItem === true  || exchangeItem === true ? 'Reason' : 'Remarks')" :width="120">
                                    <template slot-scope="scope">
                                        <ValidationProvider :rules="(checkIfRowSelected(scope.row.id) === true ? 'required' : '')" :vid="`delivery-remarks-${scope.row.id}`" :name="`delivery-remarks-${scope.row.id}`" v-slot="{ errors }">

                                            <input type="text" class="form-control grid-input thats-form-control" v-model="scope.row.remarks" :disabled="enableReason(scope.row)" />
                                            <span class="text-sm small text-red text-danger block">{{ errors[0] }}</span>
                                        </ValidationProvider>
                                    </template>
                                </el-table-column>
                                <template v-if="form.invoice.series === null">
                                    <el-table-column :width="75">
                                        <template slot-scope="{}" slot="header">
                                            <el-checkbox :indeterminate="isIndeterminate" v-model="checkAllTbo" @change="handleCheckAllChange">TBO</el-checkbox>
                                        </template>
                                        <template slot-scope="scope">
                                            <el-checkbox v-model="scope.row.tbo" style="margin-left:6px" @change="handleCheckedTboChange">TBO</el-checkbox>
                                        </template>
                                    </el-table-column>


                                </template>
                                <!--  <el-table-column :width="120" align="center">
                              <template slot-scope="scope" slot="header">
                                <el-checkbox :indeterminate="isIndeterminateFromDisplay" v-model="checkAllFromDisplay" @change="handleCheckAllFromDisplayChange" :disabled="enableQuantity(scope.row)">Fr. Display</el-checkbox>
                              </template>
                              <template slot-scope="scope">

                                <template v-if="enableQuantity(scope.row)">
                                    <template v-if="scope.row.from_display">Yes</template>
                                </template>
                                <template v-else>
                                  <el-checkbox v-model="scope.row.from_display" style="margin-left:6px" @change="handleCheckedFromDisplayChange"></el-checkbox>
                                </template>



                              </template>
                            </el-table-column> -->

                            </el-table>






                            <table class="el-table">
                                <tr>
                                    <template v-if="!disabled">
                                        <td></td>
                                    </template>

                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td style="width:100px"><el-checkbox v-model="form.more_discount.enable" :disabled="disabled">Invoice Discount </el-checkbox></td>
                                    <td style="width:200px">
                                        <input type="text" class="form-control grid-input thats-form-control text-right inv-discount-percent" style="padding-right : 5px" v-model="form.more_discount.percent" @keyup="invoiceDiscountPercent" :disabled="disabled" />
                                        <span class="inv-discount-percent-lbl">%</span>
                                        <input type="text" class="form-control grid-input thats-form-control text-right inv-discount" style="padding-right : 5px" v-model="form.more_discount.discount" @keyup="invoiceDiscount" @focus="removeDiscountFormat()" @blur="formatOnBlurMoreDiscount()" @keydown.enter="addMoreDiscount()" :disabled="disabled" />



                                    </td>
                                    <td style="width:120px" class="text-right">
                                        <strong>{{form.total}}</strong>
                                    </td>
                                    <template v-if="disabled">
                                        <td style="width:130px"></td>
                                    </template>
                                    <template v-else>
                                        <td style="width:114px"></td>
                                    </template>
                                </tr>
                            </table>

                            <mdb-row class="tab-row">
                                <mdb-col col="6" class="tab-col pl-0">
                                </mdb-col>
                                <mdb-col col="6" class="tab-col">
                                    <ThatsInput tabindex="2" labelClass="col-sm-4" fieldClass="col-sm-8 pl-0 pr-0 text-right" label="Discount Remarks" vid="discount_remarks" name="discount_remarks" v-model="form.attr.discount_remarks" size="sm" :disabled="disabled"/>
                                </mdb-col>
                            </mdb-row>

                        </template>
                        <template :slot="'credit-notes'">

                            <el-table :key="'cn'+tblKey" border :data="form.credit_note_items" @selection-change="handleCreditNoteSelectionChange" class="input-table mt-0" style="width: 100%" :summary-method="getCreditNoteSummaries" show-summary>

                                <template v-if="pullout">
                                    <el-table-column type="selection" width="35" :selectable="canSelectRow"> </el-table-column>
                                </template>

                                <el-table-column label="SKU" width="150">
                                    <template slot-scope="scope">
                                        {{scope.row.code}}
                                    </template>
                                </el-table-column>
                                <el-table-column label="Image" width="90">
                                    <template slot-scope="scope">
                                        <img :src="scope.row.default" style="width:90px" />
                                    </template>
                                </el-table-column>
                                <el-table-column label="Item Name" :row-class-name="''">
                                    <template slot-scope="scope">
                                        {{scope.row.name}}
                                    </template>
                                </el-table-column>

                                <el-table-column label="Qty" :width="70" align="right">
                                    <template slot-scope="scope">
                                        <template v-if="(checkSystemSupport === true && modifyReturmItem === true) || (checkSystemSupport === true && scope.row.status === 'for-approval' && modifyReturmItem === true)">
                                            <input type="number" min="1" v-on:change="quantityChange(scope.row,$event)" :max="scope.row.max" class="form-control grid-input thats-form-control text-right" v-model="scope.row.qty" @keyup="calculateCreditNote(scope.$index,$event)" @change="calculateCreditNote(scope.$index,$event)" @keydown.enter="addNewRow(scope.$index)" />
                                        </template>
                                        <template v-else>
                                            {{scope.row.qty}}
                                        </template>
                                        <!-- {{scope.row.qty}} -->
                                    </template>
                                </el-table-column>
                                <el-table-column label="Price" :width="100" align = "right">
                                    <template slot-scope="scope">
                                        {{scope.row.price | amount}}
                                    </template>
                                </el-table-column>
                                <el-table-column label="Amount" :width="100" align = "right">
                                    <template slot-scope="scope">
                                        {{scope.row.amount}}
                                    </template>
                                </el-table-column>
                                <el-table-column label="Discount" :width="100" align = "right">
                                    <template slot-scope="scope">
                                        {{scope.row.discount}}
                                    </template>
                                </el-table-column>
                                <el-table-column label="Total Amount" :width="120" align = "right">
                                    <template slot-scope="scope">
                                        {{scope.row.total}}
                                    </template>
                                </el-table-column>
                                <el-table-column label="Reason" :width="120">
                                    <template slot-scope="scope">
                                        {{scope.row.remarks}}
                                    </template>
                                </el-table-column>
                                <el-table-column label="Status" :width="120">
                                    <template slot-scope="scope">
                                        {{scope.row.status_label}}
                                    </template>
                                </el-table-column>
                            </el-table>

                            <template v-if="form.credit_note !== null && Object.keys(form.credit_note).length > 0 && form.credit_note.constructor === Object">
                                <table class="el-table">
                                    <tr>

                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                        <td style="width:100px"  class="text-right"></td>

                                        <td style="width:200px" class="text-right">
                                            <template v-if="form.credit_note.status === 'for-approval'">
                                                Credit Note Amount
                                            </template>
                                            <template v-else>
                                                Approved Credit Note Amount
                                            </template>
                                        </td>
                                        <td style="width:120px" class="text-right">
                                            <template v-if="form.hasOwnProperty('credit_notes') && form.credit_notes.length > 0">
                                                <strong>{{form.credit_notes.filter((i) => i.status === 'approved',[]).map((i) => i.total,0).reduce((a,b) => a + b, 0) | amount}}</strong>
                                            </template>
                                        </td>
                                        <td style="width:114px">&nbsp;</td>
                                        <td style="width:114px">&nbsp;</td>

                                    </tr>
                                </table>

                                <label class="mt-1"><strong>CREDIT NOTE PAYMENTS</strong></label>
                                <el-table :key="'cnreference'+tblKey" border :data="form.credit_note.credit_note_reference" class="input-table mt-1" style="width: 100%" :summary-method="getCreditNotePaymentSummaries" show-summary :cell-class-name="creditNoteCell">


                                    <el-table-column label="Date" width="150">
                                        <template slot-scope="scope">
                                            {{scope.row.date}}
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="Invoice Ref. No.">
                                        <template slot-scope="scope">
                                            {{scope.row.invoice.series}}
                                        </template>
                                    </el-table-column>


                                    <el-table-column label="Invoice Amount" :width="150" align = "right">
                                        <template slot-scope="scope">
                                            {{scope.row.invoice.total | amount}}
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="Credit note" :width="100" align = "right">
                                        <template slot-scope="scope">
                                            {{scope.row.amount | amount}}
                                        </template>
                                    </el-table-column>

                                </el-table>

                                <table class="el-table">
                                    <tr>

                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>

                                        <td style="width:200px" class="text-right">
                                            Remaining Credit Note
                                        </td>
                                        <td style="width:120px" class="text-right">

                                            <template v-if="form.credit_note.hasOwnProperty('credit_note_reference') && form.credit_note.credit_note_reference.length > 0">
                                                <strong>{{ remainingCreditNote | amount}}</strong>
                                            </template>
                                        </td>

                                    </tr>
                                </table>

                                <label class="mt-1"><strong>CREDIT NOTE</strong></label>
                                <el-table :key="'cnsreference'+tblKey" border :data="form.credit_notes" class="input-table mt-1" style="width: 100%" :summary-method="getCreditNotesSummaries" show-summary>


                                    <el-table-column label="Series" width="150">
                                        <template slot-scope="scope">
                                            {{scope.row.series}}
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="Approved Date" width="150">
                                        <template slot-scope="scope">
                                            {{scope.row.attr.approved_date}}
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="Approved By" width="150">
                                        <template slot-scope="scope">
                                            {{scope.row.attr.approved_by}}
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="Date" width="150">
                                        <template slot-scope="scope">
                                            {{scope.row.invoice.date}}
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="Invoice Ref. No.">
                                        <template slot-scope="scope">
                                            {{scope.row.invoice.series}}
                                        </template>
                                    </el-table-column>


                                    <el-table-column label="Invoice Amount" :width="150" align = "right">
                                        <template slot-scope="scope">
                                            {{scope.row.invoice.total | amount}}
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="Credit note" :width="100" align = "right">
                                        <template slot-scope="scope">
                                            {{scope.row.total | amount}}
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="Status" :width="100" align = "right">
                                        <template slot-scope="scope">
                                            {{scope.row.status}}
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="" :width="200" align = "left">
                                        <template slot-scope="scope">
                                            <mdb-btn color="elegant" size="sm" @click="fnPrint('/sales-return/print/'+scope.row.token_id)">Print</mdb-btn>
                                            <!-- <template v-if="$helpers.isSystemAdministrator() || $helpers.isAccounting()">
                            <template v-if="scope.row.status === 'approved' && (typeof scope.row.attr.document === 'undefined' || (scope.row.attr.hasOwnProperty('document') && (scope.row.attr.document.entry === null || scope.row.attr.document.entry === 'null')) )">
                              <template v-if="scope.row.loading">
                                <mdb-btn color="elegant" size="sm" disabled>
                                  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                  Loading...
                                </mdb-btn>
                              </template>
                              <template v-else>
                                        <mdb-btn @click="creditNotePushToSap(scope.row)" class="m-0" color="elegant" size="sm">Push to SAP</mdb-btn>
                              </template>
                          </template>
                          <template v-else>
                             <mdb-btn :disabled="true" class="m-0" color="elegant" size="sm">Push to SAP</mdb-btn>
                          </template>
                        </template> -->
                                        </template>
                                    </el-table-column>
                                </el-table>


                            </template>
                        </template>


                        <template :slot="'cancelled-items'">

                            <el-table :key="'cn'+tblKey" border :data="form.cancellation_item" @selection-change="handleCreditNoteSelectionChange" class="input-table mt-0" style="width: 100%" :summary-method="getItemCancellationSummaries" show-summary>



                                <el-table-column label="SKU" width="150">
                                    <template slot-scope="scope">
                                        {{scope.row.code}}
                                    </template>
                                </el-table-column>
                                <el-table-column label="Image" width="90">
                                    <template slot-scope="scope">
                                        <img :src="scope.row.default" style="width:90px" />
                                    </template>
                                </el-table-column>
                                <el-table-column label="Item Name" :row-class-name="''">
                                    <template slot-scope="scope">
                                        {{scope.row.name}}
                                    </template>
                                </el-table-column>

                                <el-table-column label="Qty" :width="70" align="right">
                                    <template slot-scope="scope">
                                        {{scope.row.qty}}
                                    </template>
                                </el-table-column>
                                <el-table-column label="Price" :width="100" align = "right">
                                    <template slot-scope="scope">
                                        {{scope.row.price | amount}}
                                    </template>
                                </el-table-column>
                                <el-table-column label="Amount" :width="100" align = "right">
                                    <template slot-scope="scope">
                                        {{scope.row.amount}}
                                    </template>
                                </el-table-column>
                                <el-table-column label="Discount" :width="100" align = "right">
                                    <template slot-scope="scope">
                                        {{scope.row.discount}}
                                    </template>
                                </el-table-column>
                                <el-table-column label="Total Amount" :width="120" align = "right">
                                    <template slot-scope="scope">
                                        {{scope.row.total}}
                                    </template>
                                </el-table-column>
                                <el-table-column label="Reason" :width="120" align = "right">
                                    <template slot-scope="scope">
                                        {{scope.row.remarks}}
                                    </template>
                                </el-table-column>

                            </el-table>

                            <template v-if="form.cancellation.length > 0">

                                <label class="mt-1"><strong>CANCEL REQUEST</strong></label>
                                <el-table :key="'cnreference'+tblKey" border :data="form.cancellation" class="input-table mt-1" style="width: 100%">


                                    <el-table-column label="Date" width="150">
                                        <template slot-scope="scope">
                                            {{scope.row.date}}
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="Invoice Ref. No.">
                                        <template slot-scope="scope">
                                            {{scope.row.series}}
                                        </template>
                                    </el-table-column>

                                    <el-table-column label="Amount" :width="150" align = "right">
                                        <template slot-scope="scope">
                                            {{scope.row.total | amount}}
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="Status" :width="100" align = "right">
                                        <template slot-scope="scope">
                                            {{scope.row.status}}
                                        </template>
                                    </el-table-column>

                                    <el-table-column label="" :width="80">
                                        <template slot-scope="scope">
                                            <mdb-btn @click="fnPrint('/cancel-item/print/'+scope.row.token_id)" class="m-0" color="elegant" size="sm">Print</mdb-btn>
                                        </template>
                                    </el-table-column>

                                </el-table>

                            </template>
                        </template>


                        <template :slot="'exchange-items'">

                            <el-table :key="'exc'+tblKey" border :data="form.exchange_item" @selection-change="handleCreditNoteSelectionChange" class="input-table mt-0" style="width: 100%" :summary-method="getItemCancellationSummaries" show-summary>



                                <el-table-column label="SKU" width="150">
                                    <template slot-scope="scope">
                                        {{scope.row.code}}
                                    </template>
                                </el-table-column>
                                <el-table-column label="Image" width="90">
                                    <template slot-scope="scope">
                                        <img :src="scope.row.default" style="width:90px" />
                                    </template>
                                </el-table-column>
                                <el-table-column label="Item Name" :row-class-name="''">
                                    <template slot-scope="scope">
                                        {{scope.row.name}}
                                    </template>
                                </el-table-column>

                                <el-table-column label="Qty" :width="70" align="right">
                                    <template slot-scope="scope">
                                        {{scope.row.qty}}
                                    </template>
                                </el-table-column>
                                <el-table-column label="Price" :width="100" align = "right">
                                    <template slot-scope="scope">
                                        {{scope.row.price | amount}}
                                    </template>
                                </el-table-column>
                                <el-table-column label="Amount" :width="100" align = "right">
                                    <template slot-scope="scope">
                                        {{scope.row.amount}}
                                    </template>
                                </el-table-column>
                                <el-table-column label="Discount" :width="100" align = "right">
                                    <template slot-scope="scope">
                                        {{scope.row.discount}}
                                    </template>
                                </el-table-column>
                                <el-table-column label="Total Amount" :width="120" align = "right">
                                    <template slot-scope="scope">
                                        {{scope.row.total}}
                                    </template>
                                </el-table-column>
                                <el-table-column label="Reason" :width="120" align = "right">
                                    <template slot-scope="scope">
                                        {{scope.row.remarks}}
                                    </template>
                                </el-table-column>

                            </el-table>

                            <template v-if="form.hasOwnProperty('exchange') && form.exchange.length > 0">

                                <label class="mt-1"><strong>EXCHANGE</strong></label>
                                <el-table :key="'cnreference'+tblKey" border :data="form.exchange" class="input-table mt-1" style="width: 100%">


                                    <el-table-column label="Date" width="150">
                                        <template slot-scope="scope">
                                            {{scope.row.date}}
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="Invoice Ref. No.">
                                        <template slot-scope="scope">


                                            <a href="javascript:void(0)">{{scope.row.series}}</a>
                                        </template>
                                    </el-table-column>

                                    <el-table-column label="Amount" :width="150" align = "right">
                                        <template slot-scope="scope">
                                            {{scope.row.total | amount}}
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="Status" :width="100" align = "right">
                                        <template slot-scope="scope">
                                            {{scope.row.status}}
                                        </template>
                                    </el-table-column>

                                    <el-table-column label="" :width="200">
                                        <template slot-scope="scope">

                                            <mdb-btn @click="fnPrint('/exchange-item/print/'+scope.row.token_id)" class="m-0" color="elegant" size="sm">Print</mdb-btn>


                                            <!-- <template v-if="scope.row.status === 'approved' && unformatBalance(form.invoice.balance) < 0 && scope.row.reference_transaction === null">
                                    <a href="javascript:void(0)" @click="viewExchange(scope.row.series,scope.row,scope.$index,false)" class="btn btn-elegant btn-sm">Exchange With</a>
                                  </template> -->
                                            <template v-if="scope.row.status === 'approved' && scope.row.reference_transaction !== null">
                                                <a href="javascript:void(0)" @click="viewExchange(scope.row.series,scope.row,scope.$index,true)" class="btn btn-elegant btn-sm">View</a>
                                            </template>
                                        </template>
                                    </el-table-column>

                                </el-table>

                            </template>
                        </template>

                        <template :slot="'refund-items'">

                            <el-table :key="'exc'+tblKey" border :data="form.refund_item" @selection-change="handleCreditNoteSelectionChange" class="input-table mt-0" style="width: 100%" :summary-method="getItemCancellationSummaries" show-summary>

                                <el-table-column label="SKU" width="150">
                                    <template slot-scope="scope">
                                        {{scope.row.code}}
                                    </template>
                                </el-table-column>
                                <el-table-column label="Image" width="90">
                                    <template slot-scope="scope">
                                        <img :src="scope.row.default" style="width:90px" />
                                    </template>
                                </el-table-column>
                                <el-table-column label="Item Name" :row-class-name="''">
                                    <template slot-scope="scope">
                                        {{scope.row.name}}
                                    </template>
                                </el-table-column>

                                <el-table-column label="Qty" :width="70" align="right">
                                    <template slot-scope="scope">
                                        {{scope.row.qty}}
                                    </template>
                                </el-table-column>
                                <el-table-column label="Price" :width="100" align = "right">
                                    <template slot-scope="scope">
                                        {{scope.row.price | amount}}
                                    </template>
                                </el-table-column>
                                <el-table-column label="Amount" :width="100" align = "right">
                                    <template slot-scope="scope">
                                        {{scope.row.amount}}
                                    </template>
                                </el-table-column>
                                <el-table-column label="Discount" :width="100" align = "right">
                                    <template slot-scope="scope">
                                        {{scope.row.discount}}
                                    </template>
                                </el-table-column>
                                <el-table-column label="Total Amount" :width="120" align = "right">
                                    <template slot-scope="scope">
                                        {{scope.row.total}}
                                    </template>
                                </el-table-column>
                                <el-table-column label="Reason" :width="120" align = "right">
                                    <template slot-scope="scope">
                                        {{scope.row.remarks}}
                                    </template>
                                </el-table-column>

                            </el-table>

                            <template v-if="form.hasOwnProperty('refund') && form.refund.length > 0">

                                <label class="mt-1"><strong>REFUND</strong></label>
                                <el-table :key="'cnreference'+tblKey" border :data="form.refund" class="input-table mt-1" style="width: 100%">


                                    <el-table-column label="Date" width="150">
                                        <template slot-scope="scope">
                                            {{scope.row.date}}
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="Invoice Ref. No.">
                                        <template slot-scope="scope">


                                            <a href="javascript:void(0)">{{scope.row.series}} </a>
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="Collected By">
                                        <template slot-scope="scope">
                                            {{scope.row.collected_by}}
                                        </template>
                                    </el-table-column>

                                    <el-table-column label="Approved Refund Amount" :width="150" align = "right">
                                        <template slot-scope="scope">
                                            {{scope.row.total | amount}}
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="Status" :width="100" align = "right">
                                        <template slot-scope="scope">
                                            {{scope.row.status}}
                                        </template>
                                    </el-table-column>

                                    <el-table-column label="" :width="200">
                                        <template slot-scope="scope">
                                            <mdb-btn @click="fnPrintDoc(`refund-item/print/${scope.row.token_id}`)" class="m-0" color="elegant" size="sm">Print</mdb-btn>
                                        </template>
                                    </el-table-column>

                                </el-table>

                            </template>
                        </template>

                        <template :slot="'delivery-notes'">
                            <el-table ref="itemsTable" border :data="form.delivery_notes" class="input-table mt-0" style="width: 100%">

                                <el-table-column label="Series" width="150">
                                    <template slot-scope="scope">
                                        {{scope.row.series}}
                                    </template>
                                </el-table-column>
                                <el-table-column label="Date" width="100">
                                    <template slot-scope="scope">
                                        {{scope.row.date}}
                                    </template>
                                </el-table-column>
                                <el-table-column label="Item Details">
                                    <template slot-scope="scope">
                                        {{scope.row.delivered_items}}
                                    </template>
                                </el-table-column>
                                <el-table-column label="Delivery Remarks">
                                    <template slot-scope="scope">
                                        {{scope.row.remarks}}
                                    </template>
                                </el-table-column>
                                <el-table-column label="Status" width="100">
                                    <template slot-scope="scope">
                                        {{scope.row.status | uppercase}}
                                    </template>
                                </el-table-column>
                                <el-table-column label="Delivery Note" width="120" align="center">
                                    <template slot-scope="scope">
                                        <mdb-btn @click="fnPrint('/delivery/print-note/'+scope.row.token_id)" class="m-0" color="elegant" size="sm">Print</mdb-btn>



                                    </template>
                                </el-table-column>
                                <!--  <template v-if="$helpers.isSystemAdministrator() || $helpers.isAccounting()">
                      <el-table-column label="" width="120" align="center">
                        <template slot-scope="scope">
                          <template v-if="scope.row.push === true && scope.row.status === 'delivered' && (typeof scope.row.attr.document === 'undefined' || (scope.row.attr.hasOwnProperty('document') && (scope.row.attr.document.entry === null || scope.row.attr.document.entry === 'null')) )">
                            <template v-if="scope.row.loading">
                              <mdb-btn color="elegant" size="sm" disabled>
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                Loading...
                              </mdb-btn>
                            </template>
                            <template v-else>
                                <mdb-btn @click="deliveryNotePushToSap(scope.row)" class="m-0" color="elegant" size="sm">Push to SAP</mdb-btn>
                            </template>
                          </template>

                        </template>
                      </el-table-column>
                      </template> -->
                            </el-table>

                            <label class="mt-1"><strong>PULL-OUT DETAILS</strong></label>
                            <el-table :key="'cnreference'+tblKey" border :data="form.pull_out" class="input-table mt-1" style="width: 100%">

                                <el-table-column label="Ref. No." width="170">
                                    <template slot-scope="scope">
                                        <a href="javascript:void(0)" @click="openPullOut(scope.row)">{{scope.row.series}}</a>
                                    </template>
                                </el-table-column>

                                <el-table-column label="Date" width="150">
                                    <template slot-scope="scope">
                                        {{scope.row.date}}
                                    </template>
                                </el-table-column>

                                <el-table-column label="Items">
                                    <template slot-scope="scope">
                                        {{scope.row.item_name}}
                                    </template>
                                </el-table-column>
                                <el-table-column label="Remarks">
                                    <template slot-scope="scope">
                                        {{scope.row.remarks}}
                                    </template>
                                </el-table-column>
                                <el-table-column label="Status">
                                    <template slot-scope="scope">
                                        {{scope.row.status | pullOutStatus}}
                                    </template>
                                </el-table-column>

                                <el-table-column label="Pull-Out Order" width="130" align="center">
                                    <template slot-scope="scope">
                                        <mdb-btn @click="fnPrint(`/invoice/pull-out/${scope.row.token_id}`)" class="m-0" color="elegant" size="sm">Print</mdb-btn>
                                    </template>
                                </el-table-column>

                            </el-table>
                        </template>

                        <template :slot="'purchase-request'">
                            <el-table ref="purchaseRequestTable" border :data="form.purchase_request_item" class="input-table mt-0" style="width: 100%" :summary-method="getPurchaseRequestSummaries" show-summary>

                                <el-table-column label="SKU" width="150">
                                    <template slot-scope="scope">
                                        {{scope.row.code}}
                                    </template>
                                </el-table-column>
                                <el-table-column label="Image" width="90">
                                    <template slot-scope="scope">
                                        <img :src="scope.row.default" style="width:90px" />
                                    </template>
                                </el-table-column>
                                <el-table-column label="Item Name" :row-class-name="''">
                                    <template slot-scope="scope">
                                        {{scope.row.name}}
                                    </template>
                                </el-table-column>

                                <el-table-column label="Qty" :width="70" align="right">
                                    <template slot-scope="scope">
                                        {{scope.row.qty}}
                                    </template>
                                </el-table-column>
                                <el-table-column label="Price" :width="100" align = "right">
                                    <template slot-scope="scope">
                                        {{scope.row.price|amount}}
                                    </template>
                                </el-table-column>
                                <el-table-column label="Amount" :width="100" align = "right">
                                    <template slot-scope="scope">
                                        {{scope.row.amount|amount}}
                                    </template>
                                </el-table-column>
                                <el-table-column label="Discount" :width="100" align = "right">
                                    <template slot-scope="scope">
                                        {{scope.row.discount|amount}}
                                    </template>
                                </el-table-column>
                                <el-table-column label="Total Amount" :width="120" align = "right">
                                    <template slot-scope="scope">
                                        {{scope.row.total|amount}}
                                    </template>
                                </el-table-column>
                                <el-table-column label="Remarks" :width="120" align = "right">
                                    <template slot-scope="scope">
                                        {{scope.row.remarks}}
                                    </template>
                                </el-table-column>
                                <el-table-column label="Status" :width="120" align = "right">
                                    <template slot-scope="scope">
                                        {{scope.row.status_label}}
                                    </template>
                                </el-table-column>

                            </el-table>
                        </template>

                        <template :slot="'invoice-slot'">
                            <mdb-row >
                                <mdb-col col="6">
                                    <template v-if="form !== null && form.hasOwnProperty('invoice') && form.invoice.date !== null">
                                        <ThatsDatePicker :show_error="showError" tabindex="1" labelClass="col-md-4" fieldClass="col-md-8 pl-0" label="Date" placeholder="Date" vid="date" name="date" v-model="form.invoice.date" :validated="validated" :rules="'required'" size="sm" :disabled="true"/>
                                    </template>
                                    <ThatsInput tabindex="2" labelClass="col-sm-4" fieldClass="col-sm-8 pl-0" label="Customer Mobile No." vid="mobile" name="mobile" v-model="form.attr.mobile" size="sm" :disabled="true"/>
                                    <ThatsInput tabindex="2" labelClass="col-sm-4" fieldClass="col-sm-8 pl-0" label="Customer Address" vid="address" name="address" v-model="form.attr.address" size="sm" :disabled="true"/>
                                </mdb-col>
                                <mdb-col col="6">

                                    <ThatsInput tabindex="2" labelClass="col-sm-4" fieldClass="col-sm-8 pl-0" label="Instructions" vid="instruction" name="instruction" v-model="form.attr.instruction" size="sm" :disabled="true"/>
                                    <ThatsInput tabindex="2" labelClass="col-sm-4" fieldClass="col-sm-8 pl-0" label="Other Details" vid="other_details" name="other_details" v-model="form.attr.other_details" size="sm" :disabled="true"/>
                                </mdb-col>

                            </mdb-row>

                            <el-table border :data="invoice.payments" class="input-table mt-2" style="width: 100%" :summary-method="getPaymentsSummaries" show-summary :cell-class-name="tableRowClassName">
                                <el-table-column label="Series">
                                    <template slot-scope="scope">
                                        <template v-if="scope.row.method !== 'reverse'">
                                            <a href="javascript:void(0)" @click="openPayment(scope.row)" v-html="scope.row.series"></a>
                                        </template>
                                        <template v-else>
                                            {{scope.row.series}}
                                        </template>

                                    </template>
                                </el-table-column>
                                <el-table-column label="Date">
                                    <template slot-scope="scope">
                                        {{scope.row.date}}
                                    </template>
                                </el-table-column>
                                <el-table-column label="Canceled">
                                    <template slot-scope="scope">
                                        {{scope.row.canceled.toUpperCase()}}
                                    </template>
                                </el-table-column>
                                <el-table-column label="Mode of Payment" width="200">
                                    <template slot-scope="scope">
                                        {{scope.row.payment_method}}

                                        <template v-if="scope.row.method == 'cheque' && scope.row.attr.realized == false">
                                            (To be cleared)
                                        </template>
                                    </template>
                                </el-table-column>
                                <el-table-column label="Payment Ref" width="160">
                                    <template slot-scope="scope">
                                        <template v-if="scope.row.attr != null && scope.row.attr.hasOwnProperty('payment_reference')">
                                            <template v-if="scope.row.method === 'credit-note'">
                                                <a href="javascript:void(0)" @click="openReferenceInvoice(scope.row.attr.payment_reference)">{{scope.row.attr.payment_reference}}</a>
                                            </template>
                                            <template v-else>
                                                {{scope.row.attr.payment_reference}}
                                            </template>
                                        </template>
                                    </template>
                                </el-table-column>
                                <el-table-column label="Amount" :width="100" align="right">
                                    <template slot-scope="scope">
                                        {{scope.row.formatted_amount}}
                                    </template>
                                </el-table-column>
                                <el-table-column :width="100" label="Receipt" align="center">
                                    <template slot-scope="scope">
                                        <template v-if="scope.row.canceled !== 'yes'">
                                            <a href="javascript:void(0)" @click="fnPrint('/sales/receipt/'+scope.row.token_id)" color="elegant" size="sm"><span class="badge badge-dark text-uppercase">Print</span></a>
                                        </template>
                                    </template>
                                </el-table-column>
                                <!-- <template v-if="$helpers.isSystemAdministrator() || $helpers.isAccounting()">
                      <el-table-column label="" width="120" align="center">
                        <template slot-scope="scope">

                          <template v-if="!['credit-note','by-invoice'].includes(scope.row.method) && scope.row.amount > 0 && scope.row.push === true">
                              <template v-if="scope.row.loading">
                                <mdb-btn color="elegant" size="sm" disabled>
                                  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                  Loading...
                                </mdb-btn>
                              </template>
                              <template v-else>
                               <template v-if="scope.row.canceled !== 'yes'">
                                  <template v-if="scope.row.attr.hasOwnProperty('document') === true && scope.row.attr.document.entry === null">
                                      <mdb-btn @click="paymentPushToSap(scope.row)" class="m-0" color="elegant" size="sm">Push to SAP</mdb-btn>
                                  </template>
                                  <template v-else>
                                    <template v-if="scope.row.attr.hasOwnProperty('document') === false">
                                      <mdb-btn @click="paymentPushToSap(scope.row)" class="m-0" color="elegant" size="sm">Push to SAP</mdb-btn>
                                    </template>
                                    <template v-else>
                                      <mdb-btn disabled class="m-0" color="elegant" size="sm">Push to SAP</mdb-btn>
                                    </template>
                                  </template>
                              </template>
                              </template>
                          </template>
                          <template v-else>
                            --
                          </template>



                        </template>
                      </el-table-column>
                      </template> -->



                            </el-table>

                            <template v-if="form.hasOwnProperty('credit_memo') && form.credit_memo.length > 0">
                                <label class="small">OVERPAID</label>

                                <el-table border :data="form.credit_memo" class="input-table mt-2" style="width: 100%" :summary-method="getPaymentSummaries" show-summary>
                                    <el-table-column label="Series">
                                        <template slot-scope="scope">
                                            <a href="javascript:void(0)" v-html="scope.row.series"></a>

                                        </template>
                                    </el-table-column>
                                    <el-table-column label="Date">
                                        <template slot-scope="scope">
                                            {{scope.row.date}}
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="Mode of Payment">
                                        <template slot-scope="scope">
                                            {{scope.row.mode_of_payment}}
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="Payment Description" width="160">
                                        <template slot-scope="scope">
                                            {{scope.row.payment_method}}
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="Payment Ref" width="160">
                                        <template slot-scope="scope">
                                            <template v-if="scope.row.attr != null && scope.row.attr.hasOwnProperty('payment_reference')">
                                                {{scope.row.attr.payment_reference}}
                                            </template>
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="Amount" :width="100" align="right">
                                        <template slot-scope="scope">
                                            {{scope.row.formatted_amount}}
                                        </template>
                                    </el-table-column>
                                    <el-table-column :width="100" label="Receipt" align="center">
                                        <template slot-scope="scope">
                                            <a href="javascript:void(0)" @click="fnPrint('/sales/receipt/'+scope.row.token_id)" color="elegant" size="sm"><span class="badge badge-dark text-uppercase">Print</span></a>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </template>

                        </template>
                        <template :slot="'form-attachment'">
                            <ThatsFile labelClass="col-sm-5" fieldClass="col-sm-7 pl-0" label="Attachment" vid="sales-transaction-attachment" name="sales-transaction-attachment" v-model="form.attachment" :disabled="false" :showLabel="false" :accept="'*'"></ThatsFile>
                        </template>
                    </mdb-tabs>


                </div>
            </ThatsWindow>
        </ValidationObserver>
        <ContactsForm ref="contactForm" :group="(group + 1)" @success="contactSuccess" @update="contactUpdateSuccess"></ContactsForm>
        <PaymentForm ref="paymentForm" :group="(group + 1)" @success="paymentSuccess" @print="fnPrint"></PaymentForm>
        <UpdatePaymentForm ref="updatePaymentForm" :data="paymentForm" :group="(group + 1)" @success="updatePaymentSuccess" @print="fnPrint" :formStatus="paymentFormStatus"></UpdatePaymentForm>
        <UpdatePaymentFormVerTwo ref="updatePaymentFormVerTwo" :group="(group + 1)" @success="updatePaymentSuccess" @print="fnPrint"></UpdatePaymentFormVerTwo>
        <DiscountForm ref="discount" :group="(group + 1)" @apply="applyDiscount"></DiscountForm>
        <PrintWindow ref="printWindow" :group="3" :url="printUrl"></PrintWindow>
        <PullOutForm ref="pullOutForm" :group="(group + 1)"></PullOutForm>
        <ContactsFormUpdate ref="contactsFormUpdate" :group="(group + 1)" @success="contactUpdateSuccess"></ContactsFormUpdate>
        <PromptPassword ref="promptPasswordForm" :group="(group + 1)" @isAuthorized="isAuthorized"></PromptPassword>
        <ExchangeForm ref="exchangeForm" :group="(group + 1)" @request-exchange="requestExchangeReplacement"></ExchangeForm>
        <ExchangeViewForm ref="exchangeViewForm" :group="(group + 1)"></ExchangeViewForm>
        <HistoryWindow :group="(group + 1)" ref="historyWindow" title="History" :width="900"></HistoryWindow>

        <AddressForm :group="(group + 1)" ref="addressForm" title="Address" :width="900" :formDataProps="form" @update="updateAddress"></AddressForm>
        <ConfirmCreditNoteForm :group="(group + 1)" ref="confirmCreditNoteForm" title="Confirm" :width="900" @action-selected="confirmCreditNote"></ConfirmCreditNoteForm>


        <el-dialog title="Send Delivery Request" :visible.sync="requestDeliveryDialog" :close-on-click-modal="false" width="40%" size="small" class="text-left">
            <!-- <p class="m-0">
        <strong>To : </strong> <el-select class="border-0" v-model="requiredDeliveryForm.recipient" multiple placeholder="Select" :disabled="true">
        <el-option
          v-for="item in [{
              value: 'delivery@thatliving.com',
              label: 'delivery@thatliving.com'
            }]"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>
      </p>

      <p class="m-0">
        <strong>From : </strong> <el-select class="border-0" v-model="requiredDeliveryForm.from" multiple placeholder="Select" :disabled="true">
        <el-option
          v-for="item in [{
              value: requiredDeliveryForm.from[0],
              label: requiredDeliveryForm.from[0]
            }]"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>
      </p> -->
            <mdb-alert color="warning">
                The message will be sent to the delivery team
            </mdb-alert>
            <br />
            <p>Dear Team,</p>
            <p>Please schedule delivery for invoice # : <strong>{{form.invoice.series }}</strong></p>

            <p class="text-muted text-danger"><i> <strong>Reminder</strong>: Our delivery team requires the customer's delivery location. Please provide a complete address as much as possible.<br />
                Insert your additional message below. </i></p>
            <mdbWysiwygTextarea ref="descriptionEditor" class="mt-3 mb-4" placeholder="Additional Message"></mdbWysiwygTextarea>
            <p>Thank you</p>


            <mdb-btn color="elegant" size="sm" @click="sendDeliveryRequestForm" :disabled="requestDeliveryLoading">Send</mdb-btn>

        </el-dialog>

        <PaymentDialogForm ref="paymentDialogForm" @success="paymentSuccess"></PaymentDialogForm>
        <IssueVoucher ref="issueVoucherDialogForm" @success="issueVoucherSuccess"></IssueVoucher>



    </div>
</template>
<script>

/*eslint no-prototype-builtins: "error"*/

// var hasBarProperty = Object.prototype.hasOwnProperty.call(foo, "bar");

// var isPrototypeOfBar = Object.prototype.isPrototypeOf.call(foo, bar);

// var barIsEnumerable = {}.propertyIsEnumerable.call(foo, "bar");
import { mdbClickOutside,mdbBtn, mdbRow,mdbCol, mdbIcon, mdbTabs,   mdbDropdown,
    mdbDropdownItem,
    mdbDropdownMenu,
    mdbDropdownToggle,mdbAlert} from "mdbvue";

import { ValidationObserver, ValidationProvider } from "vee-validate";
import { mapActions,mapGetters, mapMutations } from 'vuex'


import { userData } from '../../helpers/user'
// import { isGeneralManager } from '../../helpers/headers'

import { ThatsWindow,ThatsInput, ThatsSeriesInput, ThatsDatePicker, ThatsMultiSelect, ThatsFile, ThatsCustomMultiSelect, ThatsTextarea, HistoryWindow} from '../../components/thats/Thats'
import mdbWysiwygTextarea from '../../components/wysiwyg/mdbWysiwyg'
import { MessageBox } from 'element-ui';
import Fuse from 'fuse.js'
import unformat from 'accounting-js/lib/unformat.js'
import formatMoney from 'accounting-js/lib/formatMoney.js'
import ContactsForm from '../contacts/ContactsForm'
import DiscountForm from './DiscountForm'
import PaymentForm from './PaymentForm'
import UpdatePaymentForm from './UpdatePaymentForm'
import PullOutForm from './PullOutForm'
import PrintWindow from '../../components/thats/PrintWindow'
import GridVueSelect from './GridVueSelect'
import GridFabricSelect from './GridFabricSelect'
import Sortable from 'sortablejs'
import ContactsFormUpdate from './ContactsFormUpdate'
import axios from 'axios'
import PromptPassword from '../../components/PromptPassword'

import ExchangeForm from './ExchangeForm';
import ExchangeViewForm from './ExchangeViewForm';
import ConfirmCreditNoteForm from './ConfirmCreditNoteForm.vue'
import UpdatePaymentFormVerTwo from './UpdatePaymentFormVerTwo.vue'
import AddressForm from './AddressForm.vue'
import PaymentDialogForm from './PaymentDialogForm'
import IssueVoucher from './IssueVoucher'
export default {
    directives: {
        mdbClickOutside
    },
    props : {
        group : {
            type : Number,
            default : 2
        },
        windowClass : {
            type : String,
            default : 'collection height-auto invoice-form'
        },
        parentContainerStyle  : {
            type : String,
            default : ''
        }
    },
    components: {
        AddressForm,
        UpdatePaymentFormVerTwo,
        ConfirmCreditNoteForm,
        ExchangeViewForm,
        ExchangeForm,
        PromptPassword,
        ContactsFormUpdate,
        mdbBtn,
        mdbRow,
        mdbCol,
        mdbIcon,
        mdbTabs,
        mdbAlert,
        mdbDropdown,
        mdbDropdownItem,
        mdbDropdownMenu,
        mdbDropdownToggle,
        ValidationProvider,
        ValidationObserver,
        ThatsWindow,
        ThatsInput,
        ThatsSeriesInput,
        ThatsDatePicker,
        ThatsMultiSelect,
        ThatsFile,
        ThatsCustomMultiSelect,
        PrintWindow,
        GridVueSelect,
        ContactsForm,
        DiscountForm,
        PaymentForm,
        UpdatePaymentForm,
        GridFabricSelect,
        PullOutForm,
        ThatsTextarea,
        HistoryWindow,mdbWysiwygTextarea,
        PaymentDialogForm,
        IssueVoucher

    },

    data : () => ({
        isIndeterminateFromDisplay : false,
        checkAllFromDisplay : false,
        requestDeliveryDialog : false,
        dialogTableVisible: false,
        dialogFormVisible: false,
        requestedExchange : false,
        requestedCancel : false,
        customerField : false,
        testing : false,
        isIndeterminate : false,
        checkAllTbo : false,
        isIndeterminateDelivered : false,
        checkAllDelivered : false,
        modifyReturmItem : false,
        returnItem : false,
        request : null,
        selectedTbo : [],
        requests : [],
        disabled : false,
        modify : false,
        pullout : false,
        printUrl : '',
        active : 1,
        validated : false,
        form : null,
        formOriginal : {
            status : 'no-remarks'
        },
        formLoading : false,
        response : {
            error : null,
            message : null
        },
        selected : [],
        tabs : [
            { text: 'Sales Details', slot: 'form-slot' }
        ],
        delivery_status : [],
        showError : false,
        status : [],
        paymentMethod : [],
        items : [],
        fabrics : [],
        customer : [],
        partnerSalesPerson : [],
        partner : [],
        discountForm : null,
        discountFormIndex : null,
        paymentForm : {
            series : 0,
            date : "",
            mode : "full-payment",
            method : "cash",
            customer : "",
            attr : {},
            description : null,
            invoice_series : null,
            amount : 0,
            reference : null,
            transaction_id : null,
            invoice : {
                series : null,
                balance : 0,
                paid : 0,
                amount : 0,
            },

        },
        paymentFormStatus : 'update',
        customFooter : {rows : [{name : "demo"},{remarks : "remarks"}], columns : [{
                field : 'name'
            },{
                field : 'remarks'
            }]},
        total : 0,
        context: null,
        viewMenu: false,
        top: '0px',
        left: '0px',
        newArrange : [],
        sortableState : {},
        sortableStateStatus : true,


        creditNoteSelection : [],

        enableReturn : ["delivered",'collected','cancel','partial-returned','rendered'],
        returnItem : false,


        cancelItemEnable : ["request-item-cancel"],
        cancelItem : false,
        cancelItemLoading : false,

        exchangeItemEnable : ["request-item-exchange"],
        exchangeItem : false,
        exchangeItemLoading : false,

        refundRequested : false,
        refundItemEnable : ["request-item-refund"],
        refundItem : false,
        refundItemLoading : false,

        pushInvoiceToSapLoading : false,

        returnForm : {
            date : null
        },
        instructions : [
            {"label" : "Items by order", "code" : "Items by order"}
        ],
        displayAddress : '',

        requestDeliveryForm : {
            recipient : ["delivery@thatsliving.com"],
            from : [],
            subject : "",
            message : "",
            invoice : "",
            id : ""
        },
        requestDeliveryLoading : false,
        customerService : [],
    }),


    created : function() {
        const formItemVar = this.formItemVar();


        if(this.formStatus == 'create' || (this.formStatus == 'update' && this.form === null)){
            this.form = this.formVar();

            this.form.items.push(formItemVar);

            this.nextCode()
            this.currentDateSetter();
            this.tabs = [
                { text: 'Sales Details', slot: 'form-slot' },

            ]

        }
        this.optionsHandler();
        this.discountForm = this.formItemVar();

        const {settings,user} = JSON.parse(localStorage.getItem('user'));


        this.paymentMethod = settings.payment_mode
        this.status = settings.sales_transaction_order_status;
        this.requestDeliveryForm.from.push(user.email)
        // console.log(this.form)
        // alert(this.form)
        // alert(this.formStatus)

//         this.status = [
//     { code: 'collected-x', label: '___________________________________', seperator : true },
//     { code: 'bar', label: 'This is bar' },
//     { code: 'collected-1', label: '___________________________________' , seperator : true },
//     { code: 'disabled', label: 'This option is disabled', disabled: true },
// ];
    },

    mounted(){

        const arr = [
            {name : "test1", code : "test-1"},
            {name : "test2", code : "test-2"},
            {name : "test3", code : "test-3"},
            {name : "test4", code : "test-4"},
            {name : "test5", code : "test-5"},
            {name : "test6", code : "test-6"},
            {name : "test7", code : "test-7"},
        ];

        arr.splice(0, 0, arr.splice(2, 1)[0]);

    },

    updated(){
        this.$refs.itemTable.$el.querySelector(
            ".el-table__body-wrapper tbody"
        );
    },
    methods: {

        ...mapActions('LoyaltyMemberModule',[
            'getCustomerLoyaltyMembership'
        ]),
        ...mapMutations('HistoryModule',[
            'setModule',
            'setRecordId',
        ]),
        ...mapMutations('ItemExchangeFormModule',[ 'setItemToExchange','setViewForm','setItemToReplace','exchangeForm']),
        ...mapActions('ItemExchangeFormModule',{
            getExchangeRecord : 'getRecord'
        }),
        ...mapActions('ContactsModule',[ 'GetContacts' ]),
        ...mapActions('ContactsModule',{
            getContact : 'getRecord',
            storeContactRecord : 'StoreRecord',
        }),
        ...mapMutations('ContactsModule',{
            setFormContactStatusUpdate : 'SetFormStatusUpdate',
            setFormRecordUpdate : 'setFormRecordUpdate'
        }),
        ...mapActions('InvoiceModule',[
            'getPartner',
            'storeRecord',
            'updateRecord',
            'updateCreditNote',
            'filterItems',
            'updateStatus',
            'getRecord',
            'getRecordSeries',
            'storeCreditNote',
            'storeItemCancellation',
            'storeItemExchange',
            'storeItemReplacement',
            'storePullOut',
            'storeRefund',
            'sapIntegration',
            'sapCreditNoteIntegration',
            'sapDeliveryNoteIntegration',
            'sapPaymentIntegration',
            'sendDeliveryRequest'
        ]),
        ...mapActions('MakePaymentModule',[
            'getCustomerCreditNotes',
            'getCustomerOverPaid',
        ]),
        ...mapMutations('MakePaymentModule',[

            'setPaymentForm',
        ]),
        ...mapActions('PullOutModule',{
            getPullOutRecord : 'getRecord',
        }),
        ...mapMutations('PullOutModule',{
            setPullOutFormRecord : 'setFormRecord',
            setPullOutStatusUpdate: 'setFormStatusUpdate',
        }),
        ...mapMutations('InvoiceModule',[
            'setFormStatusCreate',
            'setFormRecord',
            'setFormStatusUpdate',
            'setTblKey',
        ]),
        ...mapMutations('DiscountModule',[
            'setDiscountForm',
        ]),
        ...mapMutations('TableModule',[
            'SetPage'
        ]),
        ...mapActions('TransactionModule',[
            'filterFabric',
            'getCustomerService',
        ]),

        ...mapMutations('PaymentModule',['setFormData']),
        ...mapMutations('PaymentModule',{
            setPaymentStatusUpdate : 'formUpdate',
            setPaymentStatusCreate : 'formCreate'
        }),
        ...mapActions('DataIntegrationModule',['integrate']),

        ...mapMutations('IssueVoucherModule',{
            setIssueVoucherFormData : 'setFormData'
        }),
        formVar(){
            return {
                series : "",
                date : "",
                status_index : 0,
                status : "no-remarks",
                attr : {
                    partner : [],
                    name : null,
                    mobile : null,
                    address: null,
                    address2 : {
                        street : null,
                        building : null,
                        zone : null,
                        area : null,
                        city : null,
                    },
                    instructions : null,
                    other_details : null,
                    payment_status : 'no-payment',
                    expected_date : null,
                    tbo_details : null,
                    document : {
                        entry : null,
                        number : null,
                        status : false
                    },
                    more_discount : {
                        enable : false,
                        discount : 0,
                        percent : 0
                    },
                    corporate : false,
                    contact_person : {
                        name : null,
                        mobile : null,
                        attr : {

                            address: null,
                            address2 : {
                                street : null,
                                building : null,
                                zone : null,
                                area : null,
                                city : null,
                            },
                            designation : null,
                            email : null,
                            first_name : null,
                            middle_name : null,
                            last_name : null,
                            salutation : null
                        }
                    },
                    from_display: false,
                    quantity_origin:[]
                },
                customer : {
                    name : null,
                    mobile : null,
                    address : null,
                },
                user_id : null,
                sold_by : null,
                customer_id : null,
                payment_method : 'cash',
                items : [],
                pull_out : [],
                purchase_request_item : [],
                delivery_notes : [],
                attachment : [],
                invoice : {
                    series : null,
                    date : "",
                    balance : 0,
                    paid : 0,
                    attachment : [],
                    payments : [],
                },
                delivery_schedule : {
                    date : "",
                    remarks : null,
                    status : 'for-delivery-schedule',
                },
                total : 0,
                more_discount : {
                    enable : false,
                    discount : 0,
                    percent : 0
                },
                credit_notes : [],
                credit_note_items : [],
                cancellation : [],
                cancellation_item : [],
                refund : [],
                refund_item : [],
                exchange : [],
                exchange_item : [],
                credit_memo : [],
                enable_push : false,
                description: "",
                credit_note : {
                    total : 0,
                    status : null,
                    credit_note_reference : []
                },
            }
        },

        formItemVar(){
            return {
                default: "",
                item_id: null,
                code: null,
                name : null,
                category : null,
                description: null,
                reference: null,
                qty: 1,
                max: 1,
                formatted_price: 0,
                price: 0,
                amount: 0,
                discount: 0,
                discount_type: null,
                custom_discount: {
                    apply : true,
                    discount : 0,
                    off_price : 0,
                    promo : 0,
                },
                total: 0,
                tbo: false,
                show_search: false,
                remarks: null,
                delivered : null,
                from_display : false
            }
        },
        /* start */
        paymentSuccess(response){
            this.setFormRecord(response.data)
            this.$emit('success')
        },
        updatePaymentSuccess(){
            const self = this;

            self.getRecordSeries({token_id : self.form.token_id, series : self.form.invoice.series}).then(() => {
                self.active = 1
            })
        },
        applyDiscount(v){
            const self = this;
            const applyDiscountType = v.form.custom_discount.apply
            const applyDiscountValue = v.form.custom_discount.discount
            const frm = v.form;
            const qty = frm.qty;
            let price = frm.price;

            if(frm.promotions.length > 0 && frm.discount_type === 'promo'){
                const itemPromo = frm.promotions[0];
                const discountValue = this.computeDiscount(qty,price,itemPromo,frm.discount_type,frm.custom_discount);

                const discount = unformat(discountValue);
                frm.discount = formatMoney(discount, { symbol: "", precision: 2, thousand: ",", decimal: "." });


                frm.discount = this.computeDiscount(qty,price,itemPromo,frm.discount_type,frm.custom_discount);
                // frm.remarks = this.setRemarks(qty,itemPromo);
                frm.total = this.computeTotalAmount(qty, price, frm.discount)
            }

            if(frm.discount_type === 'discount'){
                const itemPromo = frm.promotions[0];

                const discountValue = this.computeDiscount(qty,price,itemPromo,frm.discount_type,frm.custom_discount);

                const discount = unformat(discountValue);
                frm.discount = formatMoney(discount, { symbol: "", precision: 2, thousand: ",", decimal: "." });


                // frm.discount = this.computeDiscount(qty,price,itemPromo,frm.discount_type,frm.custom_discount);
                // frm.remarks = this.setRemarks(qty,frm.custom_discount);
                frm.total = this.computeTotalAmount(qty, price, frm.discount)
            }
            if(v.apply_all){
                const items = this.form.items;
                console.log(items)
                if(items.length > 0){
                    items.forEach((item, index)=>{


                        // const itemForm = JSON.parse(JSON.stringify(item));

                        const unFormatPrice = unformat(item.price);
                        let unFormatDiscount = unformat(applyDiscountValue);
                        item.custom_discount.apply = applyDiscountType
                        item.custom_discount.discount = applyDiscountValue
                        if(unFormatDiscount !== null){
                            if(parseInt(unFormatDiscount) > 0){
                                item.discount_type = 'discount'
                            }else{
                                item.discount_type = null
                            }

                            if(unFormatDiscount === ""){
                                unFormatDiscount = 0
                            }

                            if(applyDiscountType === true || applyDiscountType === 1){
                                const off = (unFormatDiscount / 100) * unFormatPrice;
                                const promo = unFormatPrice - off;

                                item.custom_discount.promo = self.formatNumber(promo)
                                item.custom_discount.off_price = self.formatNumber(off)
                            }else{

                                if(unFormatDiscount === ""){
                                    unFormatDiscount = 0;
                                }
                                const promo = unFormatPrice - unFormatDiscount;
                                item.custom_discount.promo = self.formatNumber(promo)
                                item.custom_discount.off_price = self.formatNumber(unFormatDiscount)
                            }

                        }

                        const qty = item.qty;
                        let price = item.price;

                        if(item.promotions.length > 0 && item.discount_type === 'promo'){
                            const itemPromo = item.promotions[0];
                            const discountValue = self.computeDiscount(qty,price,itemPromo,item.discount_type,item.custom_discount);

                            const discount = unformat(discountValue);
                            item.discount = formatMoney(discount, { symbol: "", precision: 2, thousand: ",", decimal: "." });


                            item.discount = self.computeDiscount(qty,price,itemPromo,item.discount_type,item.custom_discount);
                            // item.remarks = self.setRemarks(qty,itemPromo);
                            item.total = self.computeTotalAmount(qty, price, item.discount)
                        }


                        const itemPromo = item.promotions[0];

                        const discountValue = self.computeDiscount(qty,price,itemPromo,item.discount_type,item.custom_discount);

                        const discount = unformat(discountValue);
                        item.discount = formatMoney(discount, { symbol: "", precision: 2, thousand: ",", decimal: "." });

                        // item.remarks = self.setRemarks(qty,item.custom_discount);
                        item.total = self.computeTotalAmount(qty, price, item.discount)


                        self.$set(self.form.items, index, item)

                    })
                }

            }else{
                this.$set(this.form.items, v.index, frm)
            }



        },
        cell({columnIndex}) {
            const self = this;
            if(self.formStatus === 'update'){
                if(columnIndex ===5){
                    return 'cell-align-center';
                }
            }

            if(self.formStatus === 'create'){
                if(columnIndex ===6){
                    return 'cell-align-center';
                }
            }

            return null;
        },
        creditNoteCell({row}) {
            const self = this;


            /*   if(row.series === self.form.invoice.sereies){
        return 'text-red'
      } */
            return 'text-red';
        },

        tableRowClassName({ row }) {
            if (row.canceled === 'yes' ) {
                return 'text-red'
            }

            if (row.method === 'cheque' && row.attr.realized == false ) {
                return 'row-background-warning'
            }
            return ''
        },


        openDiscount(index,row){
            this.setDiscountForm({index : index, form: row});
            this.$refs.discount.openDialog();
        },

        getCreditNoteSummaries(param) {
            const { columns, data } = param;
            const sums = [];

            let totalText= 6;
            let totalAmount= 7;


            columns.forEach((_column, index) => {
                if (index === totalText) {
                    sums[index] = 'Total';
                    return;
                }

                /*    if (index === totalQty) {
            const qty = data.map(item => {
                return (item.code !== null && item.name !== null) ? parseInt(item.qty) : 0
              })

              if (!qty.every(qty => isNaN(qty))) {
                  sums[index] = qty.reduce((prev, curr) => {
                  const value = Number(curr);
                  if (!isNaN(value)) {
                    return prev + curr;
                  } else {
                    return prev;
                  }
                }, 0);
              }
            return;
          }
 */
                if (index === totalAmount) {
                    const total = data.map(item => {
                        return unformat(item.total);
                    });

                    if (!total.every(total => isNaN(total))) {
                        const sum = total.reduce((prev, curr) => {
                            const value = Number(curr);
                            if (!isNaN(value)) {
                                return prev + curr;
                            } else {
                                return prev;
                            }
                        }, 0);


                        this.total =  this.formatNumber(sum)
                        sums[index] = this.formatNumber(sum)

                    }


                    return;
                }



            });

            // this.toggleCheck()

            return sums;
        },
        getItemCancellationSummaries(param) {
            const self = this;
            const { columns, data } = param;
            const sums = [];

            let totalText= 6;
            let totalAmount= 7;


            columns.forEach((_column, index) => {
                if (index === totalText) {
                    sums[index] = 'Total';
                    return;
                }

                if (index === totalAmount) {
                    const total = data.map(item => {
                        return unformat(item.total);
                    });

                    if (!total.every(total => isNaN(total))) {
                        const sum = total.reduce((prev, curr) => {
                            const value = Number(curr);
                            if (!isNaN(value)) {
                                return prev + curr;
                            } else {
                                return prev;
                            }
                        }, 0);


                        this.total =  this.formatNumber(sum)
                        sums[index] = this.formatNumber(sum)

                    }


                    return;
                }



            });

            return sums;
        },
        getPaymentSummaries(param) {
            const self = this;
            const { columns, data } = param;
            const sums = [];

            let totalText= 4;
            let totalAmount= 5;


            columns.forEach((_column, index) => {
                if (index === totalText) {
                    sums[index] = 'Total';
                    return;
                }

                if (index === totalAmount) {
                    const total = data.map(item => {
                        // return unformat(item.amount);

                        let amount = unformat(item.amount);

                        if(item.canceled === 'yes' || item.attr.realized ===  false){
                            amount = 0
                        }
                        return amount;
                    });

                    if (!total.every(total => isNaN(total))) {
                        const sum = total.reduce((prev, curr) => {
                            const value = Number(curr);
                            if (!isNaN(value)) {
                                return prev + curr;
                            } else {
                                return prev;
                            }
                        }, 0);

                        sums[index] = this.formatNumber(sum)

                    }

                    return;
                }



            });

            return sums;
        },getPaymentsSummaries(param) {
            const self = this;
            const { columns, data } = param;
            const sums = [];


            console.log("data, columns")
            console.log(data, columns)


            columns.forEach((_column, index) => {
                if (index === 4) {
                    sums[index] = 'Total';
                    return;
                }

                if (index === 5) {

                    const total = data.map(item => {
                        let amount = parseFloat(item.amount);
                        if(item.canceled === 'yes' || (item.method === "cheque" && item.attr.realized ===  false)){
                            amount = 0
                        }
                        return amount;
                    });
                    if (!total.some(isNaN)) {
                        const sum = total.reduce((prev, curr) => prev + curr, 0);
                        sums[index] = this.$helpers.formatMoney(sum, { symbol: '', precision: 2, thousand: ',', decimal: '.' });
                    }
                    return;
                }



            });

            return sums;
        },
        getCreditNotePaymentSummaries(param) {
            const self = this;
            const { columns, data } = param;
            const sums = [];

            let totalText= 2;
            let totalAmount= 3;


            columns.forEach((_column, index) => {
                if (index === totalText) {
                    sums[index] = 'Total';
                    return;
                }

                if (index === totalAmount) {
                    const total = data.map(item => {
                        return unformat(item.amount);
                    });

                    if (!total.every(total => isNaN(total))) {
                        const sum = total.reduce((prev, curr) => {
                            const value = Number(curr);
                            if (!isNaN(value)) {
                                return prev + curr;
                            } else {
                                return prev;
                            }
                        }, 0);

                        sums[index] = this.formatNumber(sum)

                    }

                    return;
                }



            });

            return sums;
        },
        getCreditNotesSummaries(param) {
            const self = this;
            const { columns, data } = param;
            const sums = [];

            let totalText= 3;
            let totalAmount= 4;


            columns.forEach((_column, index) => {
                if (index === totalText) {
                    sums[index] = 'Total';
                    return;
                }

                if (index === totalAmount) {
                    const total = data.filter((i) => i.status === 'approved',[]).map(item => {
                        return unformat(item.total);
                    },0);

                    if (!total.every(total => isNaN(total))) {
                        const sum = total.reduce((prev, curr) => {
                            const value = Number(curr);
                            if (!isNaN(value)) {
                                return prev + curr;
                            } else {
                                return prev;
                            }
                        }, 0);

                        sums[index] = this.formatNumber(sum)

                    }

                    return;
                }



            });

            return sums;
        },

        roundAmount(amount) {
            let integerPart = Math.floor(amount); // Get the integer part of the number
            let fractionalPart = amount % 1; // Get the fractional part of the number

            if (fractionalPart <= 0.49) {
                fractionalPart = 0.00;
            } else if (fractionalPart >= 0.50) {
                fractionalPart = 0.50;
            }

            return integerPart + fractionalPart;
        },

        removeCommas(amountString) {
            return parseFloat(amountString.replace(/,/g, ''));
        },
        addCommas(amount) {
            // Split the amount into integer and decimal parts
            let [integerPart, decimalPart] = amount.toString().split('.');

            // Add commas to the integer part
            integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

            // Ensure the decimal part always has two digits
            decimalPart = decimalPart ? decimalPart.padEnd(2, '0') : '00';

            // Return the formatted amount with the decimal part
            return `${integerPart}.${decimalPart}`;
        },

        getSummaries(param) {
            const self = this;
            const { columns, data } = param;
            const sums = [];
            const numberColumns = [2,3,4,5,6,7,8]

            let totalCol = 3;
            let totalQty= 4;
            let totalPrice= 5;
            let totalAmount= 6;
            let totalDiscount= 7;
            let totalTotal= 8;

            if((self.formStatus === 'create')){
                totalCol = 4;
                totalQty= 5;
                totalPrice= 6;
                totalAmount= 7;
                totalDiscount= 8;
                totalTotal= 9;
            }

            columns.forEach((column, index) => {
                if (index === totalCol) {
                    sums[index] = 'Total';
                    return;
                }


                if (index === totalQty) {
                    const qty = data.map(item => {
                        return (item.code !== null && item.name !== null) ? parseInt(item.qty) : 0
                    })

                    if (!qty.every(qty => isNaN(qty))) {
                        sums[index] = qty.reduce((prev, curr) => {
                            const value = Number(curr);
                            if (!isNaN(value)) {
                                return prev + curr;
                            } else {
                                return prev;
                            }
                        }, 0);
                    }
                    return;
                }

                if (index === totalAmount) {
                    const amount = data.map(item => {
                        return parseInt(item.qty) * parseFloat(item.price);
                    });

                    if (!amount.every(amount => isNaN(amount))) {
                        const sum = amount.reduce((prev, curr) => {
                            const value = Number(curr);
                            if (!isNaN(value)) {
                                return prev + curr;
                            } else {
                                return prev;
                            }
                        }, 0);

                        sums[index] = this.formatNumber(sum)

                    }

                    return;
                }
                if (index === totalDiscount) {
                    const discount = data.map(item => {
                        let d = 0;
                        // if(item.hasOwnProperty("promotions") && item.promotions.length > 0){
                        //   const itemPromo = item.promotions[0];
                        //   d = self.computeDiscount(item.qty,item.price,itemPromo,item.discount_type,item.custom_discount);
                        //   // alert(d)
                        // }else{
                        d = unformat(item.discount)
                        // }
                        return d;
                    })




                    if (!discount.every(discount => isNaN(discount))) {
                        const sum = discount.reduce((prev, curr) => {
                            const value = Number(curr);
                            if (!isNaN(value)) {
                                return prev + curr;
                            } else {
                                return prev;
                            }
                        }, 0);

                        sums[index] = this.formatNumber(sum)

                    }
                    return;
                }

                if (index === totalTotal) {
                    const total = data.map(item => {
                        let d = 0;

                        d = unformat(item.discount)
                        // if(item.hasOwnProperty("promotions") && item.promotions.length > 0){
                        //   const itemPromo = item.promotions[0];
                        //   d = this.computeDiscount(item.qty,item.price,itemPromo,item.discount_type,item.custom_discount);
                        // }
                        //
                        // if(item.discount_type === 'discount'){
                        //     const itemPromo = item.promotions[0];
                        //     d = this.computeDiscount(item.qty,item.price,itemPromo,item.discount_type,item.custom_discount);
                        //
                        //   }


                        return self.computeTotalAmountNumber(item.qty,item.price,d);
                    });

                    if (!total.every(total => isNaN(total))) {
                        const sum = total.reduce((prev, curr) => {
                            const value = Number(curr);
                            if (!isNaN(value)) {
                                return prev + curr;
                            } else {
                                return prev;
                            }
                        }, 0);


                        //  this.form.total =  this.formatNumber(sum)
                        this.total =  this.formatNumber(sum)
                        sums[index] = this.formatNumber(sum)

                    }


                    return;
                }



            });

            // this.toggleCheck()
            console.log(columns,"getSummery")
            return sums;
        },
        getActiveTabIndex(index){
            this.active = index
        },

        openPaymentWindow(){
            console.log("swaraj form Invoice form", this.form)
            const paymentformVar = {
                // v.transaction.customer.customer.loyalty_number
                transaction : {
                    ...this.form,
                    customer : {
                        ...this.form.customer,
                        customer : {
                            ...this.form.customer.customer,
                            loyalty_number : this.form.attr.loyalty_number ? this.form.attr.loyalty_number : "",
                        }
                    }
                },
                payment_reference : [],

            }

            console.log("paymentformVar")
            console.log(paymentformVar)

            this.getCustomerCreditNotes(this.form)
            // this.getCustomerOverPaid(this.form)
            this.getCustomerLoyaltyMembership(this.form.attr.cx_series)

            this.setPaymentForm(paymentformVar);
            this.$refs.paymentDialogForm.openDialog()
        },


        array_move(arr, old_index, new_index) {
            if (new_index >= arr.length) {
                var k = new_index - arr.length + 1;
                while (k--) {
                    arr.push(undefined);
                }
            }
            arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
            return arr; // for testing
        },

        move(arr, old_index, new_index) {
            while (old_index < 0) {
                old_index += arr.length;
            }
            while (new_index < 0) {
                new_index += arr.length;
            }
            if (new_index >= arr.length) {
                var k = new_index - arr.length;
                while ((k--) + 1) {
                    arr.push(undefined);
                }
            }
            arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
            return arr;
        },

        optionsHandler(){
            const self = this;

            // alert(this.formStatus)
            /*  if(this.form !== null){
        this.dispatchFilterItems({transaction_date : this.form.date})
      } */
            this.getPartner().then((response) => {
                if(response.length > 0){
                    // self.partner = response

                    self.partner = response.filter((item) => item.isDesigner == 1 || item.isShowMan == 1, []);
                    self.partnerSalesPerson = response

                }
            })
            // this.linksHandler();


        },
        destroySortable(){
            this.sortableStateStatus = false
            this.sortableState = null
        },
        initSortable(){
            const self = this;
            this.disableSortable();

            // if(this.form.items.length > 0){


            const tbody = this.$refs.itemTable.$el.querySelector(
                ".el-table__body-wrapper tbody"
            );

            self.sortableState = Sortable.create(tbody,{
                onEnd: function (evt) {
                    //   const oldIndex = evt.oldIndex;
                    //   const newIndex = evt.newIndex;
                    //   const toArrange = JSON.parse(JSON.stringify(self.form.items));
                    //  self.newArrange = self.move(toArrange,oldIndex,newIndex);

                    const oldIndex = evt.oldIndex;
                    const newIndex = evt.newIndex;
                    const toArrange = JSON.parse(JSON.stringify(self.form.items));
                    const targetRow = toArrange.splice(oldIndex, 1)[0];
                    toArrange.splice(newIndex, 0, targetRow);
                    self.newArrange = toArrange

                },
                sort : true
            });



        },
        disableSortable(){
            const self = this;
            if(Object.keys(self.sortableState).length !== 0){
                const tbody = this.$refs.itemTable.$el.querySelector(
                    ".el-table__body-wrapper tbody"
                );

                self.sortableState = Sortable.create(tbody,{
                    onEnd: function (evt) {

                        const oldIndex = evt.oldIndex;
                        const newIndex = evt.newIndex;
                        const toArrange = JSON.parse(JSON.stringify(self.form.items));
                        const targetRow = toArrange.splice(oldIndex, 1)[0];
                        toArrange.splice(newIndex, 0, targetRow);
                        self.form.items = toArrange
                    },
                    sort : true
                });
            }


        },
        enableSortable(){
            const self = this;

            /*   const tbody = document.querySelector('#itemTable .el-table__body-wrapper table.el-table__body tbody')
        self.sortableState = Sortable.create(tbody,{
          onEnd: function (evt) {


            const oldIndex = evt.oldIndex;
            const newIndex = evt.newIndex;

            const targetRow = self.form.items.splice(oldIndex, 1)[0];
            self.form.items.splice(newIndex, 0, targetRow);

            const toArrange = JSON.parse(JSON.stringify(self.form.items));
           self.newArrange = self.move(toArrange,oldIndex,newIndex);
          },
          sort : self.sortableStateStatus
        }); */
        },
        customLabel({label , mobile}){
            return `${label} – ${mobile}`
        },
        fnGetContacts(params){
            const self = this;
            const contacts = []
            this.GetContacts(params).then((response) => {
                if(response.length > 0){
                    response.forEach((element) => {
                        contacts.push({
                            opt : element.label + " " + element.mobile,
                            label : element.label,
                            value : element.value,
                            mobile : element.mobile,
                            address : element.address,
                        })
                    })
                }

            })

            self.customer = contacts;
        },
        async setPreparedBy(){
            var self = this;
            const loggedUser = await userData()

            const frm = this.form
            frm.sold_by = loggedUser.user.name
            this.form = frm;
        },


        deleteItem(){

            if(this.selected.length){
                if(this.formStatus === 'update'){
                    MessageBox.confirm('This will permanently delete the file. Continue?', 'Warning', {
                        confirmButtonText: 'OK',
                        cancelButtonText: 'Cancel',
                        type: 'warning'
                    }).then(() => {
                        this.selected.forEach(element => {
                            var removeIndex = this.form.items.map(function(item) { return item.code; }).indexOf(element.code);
                            this.form.items.splice(removeIndex, 1);
                        });

                        if(this.form.items.length <= 0){

                            const formItemVar = this.formItemVar();
                            this.form.items.push(formItemVar)
                        }
                    })
                }else{
                    this.selected.forEach(element => {
                        var removeIndex = this.form.items.map(function(item) { return item.code; }).indexOf(element.code);
                        this.form.items.splice(removeIndex, 1);
                    });

                    if(this.form.items.length <= 0){
                        const formItemVar = this.formItemVar();
                        this.form.items.push(formItemVar)
                    }
                }



            }


// remove object


        },

        handleSelectionChange(v){

            this.selected = v

        },
        addNewRow(index){
            /*   */

            var dataLength = this.form.items.length - 1;

            if(dataLength === index){
                const item = this.form.items[index];
                if(item.code !== null && item.description !== null){
                    const formItemVar = this.formItemVar();
                    this.form.items.push(formItemVar)

                }
            }

        },
        getSelectedFabric(v,index,row){
            const getItem = this.fabrics.filter( e => {
                return (e.id === v);
            })[0]
            if(getItem){

                // console.log(getItem)
                const name = `${row.name}/${getItem.code}/${getItem.label}`;
                this.$set(this.form.items[index], 'default', getItem.thumb)
                this.$set(this.form.items[index], 'name', name)
            }

            this.handleOutsideClick();
        },
        statusKeyUpFocusFabric(){

            const localItems = this.fabrics;
            const filterOnFocus = [];
            if(localItems.length > 0){
                localItems.forEach(item => {
                    if(this.itemSeries.indexOf(item.code) < 0){
                        filterOnFocus.push(item)
                    }
                });

                this.fabrics = filterOnFocus
            }


        },

        getSelected(v,index){
            const getSelected = this.items.filter(item => {
                return (item.code === v)
            })[0];

            if(getSelected){

                const qty = 1;
                let price = getSelected.price;
                let discount = 0;
                let remarks = null;

                if(getSelected.promotions.length > 0){
                    const itemPromo = getSelected.promotions[0];

                    const cd = {
                        apply: true,
                        discount: 0,
                        off_price: 0,
                        promo: 0,
                    }

                    discount = this.computeDiscount(qty,price,itemPromo,'promo',cd);
                    // remarks = this.setRemarks(qty,itemPromo);
                    this.$set(this.form.items[index], 'discount_type','promo');
                }

                this.$set(this.form.items[index], 'category', getSelected.category)
                this.$set(this.form.items[index], 'item_id', getSelected.id)
                this.$set(this.form.items[index], 'name', getSelected.label)
                this.$set(this.form.items[index], 'default', getSelected.default)
                this.$set(this.form.items[index], 'qty', qty)
                this.$set(this.form.items[index], 'promotions', getSelected.promotions)
                this.$set(this.form.items[index], 'formatted_price', getSelected.formatted_price)
                this.$set(this.form.items[index], 'price', price)
                this.$set(this.form.items[index], 'amount', this.computeAmount(qty, price))
                this.$set(this.form.items[index], 'discount', discount)
                // this.$set(this.form.items[index], 'remarks', remarks)
                this.$set(this.form.items[index], 'delivered', false)
                this.$set(this.form.items[index], 'total', this.computeTotalAmount(qty, price, discount))
                // this.newArrange = this.move(this.form.items,index,index);

                var dataLength = this.form.items.length - 1;
                if(dataLength === index){
                    const formItemVar = this.formItemVar();
                    this.form.items.push(formItemVar)
                }
            }

            this.handleOutsideClick();
        },

        statusKeyUpFocus(){

            const localItems = this.items;
            const filterOnFocus = [];
            if(localItems.length > 0){
                localItems.forEach(item => {
                    if(this.itemSeries.indexOf(item.code) < 0){
                        filterOnFocus.push(item)
                    }
                });

                this.items = filterOnFocus
            }


        },

        dispatchFilterItems(params){
            const results = [];
            this.filterItems(params).then((response) =>{
                if(response.length > 0){
                    response.forEach(element => {
                        results.push({label : element.name, code : element.series,formatted_price : element.formatted_price,price : element.sale_price, id : element.mdbID,promotions : element.promotions,eta : element.eta,qty : element.qty,future : element.future_stock, default : element.default,category : element.category,  remaining : element.remaining, status : element.status})
                    });
                }
            })
            this.items = results

        },

        statusKeyUpFabric(v){
// this.getPurchaseOrder(v)

            const params = {term : v};
            const results = [];
            this.filterFabric(params).then((response) =>{
                if(response.length > 0){
                    response.forEach(element => {
                        results.push({thumb: element.thumb,label : element.name, code : element.series,formatted_price : element.formatted_price,price : element.price, id : element.id, default : element.default, description : element.description})
                    });
                }
            })
            this.fabrics = results
        },

        statusKeyUp(v){
// this.getPurchaseOrder(v)

            const params = {term : v, items : this.itemSeries,transaction_date : this.form.date};
            this.dispatchFilterItems(params)
        },
        openDialog(){
            this.$refs.window.openDialog();
        },
        nextCode(){
            var self = this;
        },
        /* end */
        closeDialog(){
            this.$refs.window.closeDialog()
        },

        fnUpdateCreditNote(){
            const self = this;
            if(self.formStatus == 'update'){
                self.formLoading = true
                self.customerField = false
                self.updateCreditNote(self.form).then(() => {
                    self.$ThatsNotify.success({
                        message : "Record was successfully updated"
                    })
                    self.$emit('success')
                    self.setFormRecord(response)
                    self.formLoading = false
                    self.newArrange = []
                    self.selected = []
                    self.returnItem = false
                    self.modify = false

                    self.modifyReturmItem = false
                    self.disabled = false
                    self.customerField = false


                }).catch((error) => {
                    self.formLoading = false
                    self.modify = false
                    self.response.error = 1;
                    if (error.response) {
                        if(error.response.data.hasOwnProperty('errors')){
                            self.$refs.observer.setErrors(error.response.data.errors);
                            this.showError = true
                        }
                    } else if (error.request) {
                        self.response.message = error.request
                    } else {
                        self.response.message = error.message
                    }
                    return;
                })
            }
        },


        onSubmitForm(){
            var self =this
            this.validated = true
            const isValid = this.$refs.observer.validate();

            console.log(isValid);
            this.showError = false
            if (isValid) {
                // this.form.description = this.getText();
                if(self.formLoading === false){
                    self.formLoading = true
                    if(self.formStatus == 'create'){

                        /* if(self.newArrange.length > 0){
                          self.form.items = self.newArrange
                        } */
                        self.storeRecord(self.form).then((response) => {
                            self.$ThatsNotify.success()
                            self.$emit('success')
                            self.setFormStatusUpdate()
                            self.setFormRecord(response)
                            self.formLoading = false
                            self.SetPage(1)
                            self.newArrange = []
                        }).catch((error) => {
                            self.formLoading = false
                            self.response.error = 1;
                            if (error.response) {
                                if(error.response.data.hasOwnProperty('errors')){
                                    self.$refs.observer.setErrors(error.response.data.errors);
                                    this.showError = true
                                }
                            } else if (error.request) {
                                self.response.message = error.request
                            } else {
                                self.response.message = error.message
                            }
                            return;
                        })
                    }

                    if(self.formStatus == 'update'){

                        /* if(self.newArrange.length > 0){
                          self.form.items = self.newArrange
                        } */

                        if(this.form.status === 'cancel'){
                            const getAllCreditNotePayments = this.form.credit_note.credit_note_reference.filter(i => i.mode !== 'cancelled');
                            const getAllPayments = this.form.invoice.payments.filter(i => i.canceled !== 'yes');
                            const getAllCreditNotes = this.form.credit_notes.filter(i => i.status !== 'cancelled');
                            const getAllDelivery= this.form.delivery_notes.filter(i => i.status !== 'cancelled');


                            if(getAllCreditNotePayments.length > 0 || getAllPayments.length > 0 || getAllCreditNotes.length > 0 || getAllDelivery.length > 0){
                                self.formLoading = false
                                if(getAllCreditNotePayments.length > 0 || getAllCreditNotes.length > 0 || getAllDelivery.length > 0){
                                    MessageBox({
                                        message: 'The record could not be deleted because the record is associated with other document.',
                                        type: 'error'
                                    })
                                    return false;
                                }

                                if(getAllPayments.length > 0){
                                    MessageBox({
                                        message: 'The record could not be deleted. Please cancel payment first.',
                                        type: 'error'
                                    })


                                    return false;
                                }

                                return false;
                            }
                        }






                        self.modify = false
                        self.customerField = false
                        self.updateRecord(self.form).then(() => {
                            self.$ThatsNotify.success({
                                message : "Record was successfully updated"
                            })
                            self.$emit('success')
                            self.setFormRecord(response)
                            self.formLoading = false
                            self.newArrange = []
                            self.selected = []
                            self.returnItem = false
                            self.refundItem = false
                            self.modify = false
                            self.customerField = false
                            self.cancelItem = false

                        }).catch((error) => {
                            self.formLoading = false
                            self.modify = false
                            self.cancelItem = false
                            self.response.error = 1;
                            if (error.response) {
                                if(error.response.data.hasOwnProperty('errors')){
                                    self.$refs.observer.setErrors(error.response.data.errors);
                                    this.showError = true
                                }
                            } else if (error.request) {
                                self.response.message = error.request
                            } else {
                                self.response.message = error.message
                            }
                            return;
                        })
                    }
                }

            }
        },

        getText() {
            let text = this.$refs.descriptionEditor.$children;
            text = text.$el.innerHTML;
            return text;
        },
        FormResetter(){
            this.form = this.formVar()
            const formItemVar = this.formItemVar()
            this.form.items.push(formItemVar)
            this.setPreparedBy()
            requestAnimationFrame(() => {
                this.$refs.observer.reset();
            });
            this.validated = false
            this.formLoading = false
            this.sendLoading = false
            this.setFormStatusCreate();
            this.showError = false
            this.currentDateSetter();
            this.tabs = [
                { text: 'Sales Details', slot: 'form-slot' }
            ]
            this.disabled = false
            this.modify = false
            this.returnItem = false
            this.modifyReturmItem = false
            this.customerField = false
            this.itemCancellation = false
            this.cancelItem = false
            this.requestedCancel = false
            this.requestedExchange = false
            this.refundRequested = false
            this.refundItem = false
            this.exchangeItem = false
            this.newArrange = []

        },

        currentDateSetter(){
            var MyDate = new Date();
            var date =MyDate.getFullYear()  + '-' + ('0' + (MyDate.getMonth()+1)).slice(-2) +'-'+ ('0' + MyDate.getDate()).slice(-2);
            this.form.date = date;
        },
        dialogState(state){
            this.active = 0;
            this.optionsHandler();


            if(!state){
                this.FormResetter();
                // this.disableSortable()
                return
            }

            if(this.formStatus == 'create'){
                this.FormResetter();
                // this.initSortable();
                this.nextCode();
                this.tabs = [
                    { text: 'Sales Details', slot: 'form-slot' }
                ]

                this.$refs.historyWindow.closeDialog();

            }

            // alert("dialog State " + this.formStatus)

        },
        emitDelete(){
            this.$emit('delete',this.FormRecord)
        },

        computeDiscount(qty,price,promo,discount_type,custom_discount){

            if(discount_type !== null){
                let discountPrice = 0
                let apply = false;
                let discount = 0;
                if(discount_type === 'promo'){
                    apply = promo.apply
                    discount = promo.discount
                }else{
                    if(custom_discount !== null){
                        if(custom_discount.hasOwnProperty("apply")){
                            apply = custom_discount.apply;
                        }
                        if(custom_discount.hasOwnProperty("discount")){
                            discount = custom_discount.discount;
                        }
                    }


                }
                const p = parseFloat(price) * qty;

                if(apply === true || apply === 1){
                    discountPrice = parseFloat(p) * (discount / 100);
                }else{
                    discountPrice = parseFloat(discount);
                }
                return discountPrice;
            }
            return 0;
        },


        setRemarks(_qty,promo){
            if(promo.apply === true || promo.apply === 1){
                if(promo.discount > 0){
                    return `${promo.discount}% Discount`;
                }else{
                    return "";
                }
            }else{
                if(promo.discount > 0){
                    return `${this.formatNumber(promo.discount)} QAR Off`;
                }else{
                    return "";
                }
            }
        },
        computeAmount(qty,price){
            return this.formatNumber((qty * parseFloat(price)));
        },
        computeTotalAmountNumber(qty,price,discount){
            const d = parseFloat(discount);
            const amount = qty * parseFloat(price);
            const totalAmount = amount - d;
            return ((d > 0) ? totalAmount : amount);
        },
        computeTotalAmount(qty,price,discount){
            const d = unformat(discount);
            const amount = qty * unformat(price);
            const totalAmount = amount - d;
            return this.formatNumber(((d > 0) ? totalAmount : amount));
        },

        calculate(index,_event){
            const self = this;

            this.calculateTable();
            // this.newArrange = this.move(this.form.items, index, index);

            /* if(typeof self.form.items[index] !== 'undefined') {
              const item = self.form.items[index];

              let discount = 0;
              let remarks = null;

              if(item.promotions.length > 0){
                  const itemPromo = item.promotions[0];
                  discount = this.computeDiscount(item.qty,item.price,itemPromo,item.discount_type,item.custom_discount);
                }

                if(item.discount_type === 'discount' || (item.attr.hasOwnProperty("applied_discount") && item.attr.applied_discount.type === 'loyalty-discount')){
                  const itemPromo = [];
                  let custom_discount = item.custom_discount;
                  let discount_type = item.discount_type;
                  if(item.discount_type === 'discount'){
                      custom_discount = item.attr.applied_discount;
                      discount_type = item.attr.applied_discount.type;
                  }
                  discount = this.computeDiscount(item.qty,item.price,itemPromo,item.discount_type,custom_discount);
                }





              if(item.code !== null && item.name !== null){
                 const amount = this.computeAmount(item.qty,item.price);
                 const totalAmount = this.computeTotalAmount(item.qty,item.price,discount);
                 this.$set(self.form.items[index], 'discount', this.formatNumber(discount))
                 this.$set(self.form.items[index], 'amount', amount)
                 this.$set(self.form.items[index], 'total', totalAmount)

              }
          } */
        },


        calculateTable(){

            // let filterBenefits = this.benefits?.filter(benefit => benefit.benefit_type === 'discount');

            // const filterBenefit = JSON.parse(JSON.stringify(filterBenefits));
            let applied_tier_discount = null;
            let benefitDiscountPercentage = 0;
            let unitDiscountPercent = 0;
            // if(filterBenefit.length > 0){
            //   applied_tier_discount = filterBenefit[0];
            //   benefitDiscountPercentage = parseFloat(filterBenefit[0].discount) / 100;
            //       const tierBenefitAdditionalDiscount = parseFloat(filterBenefit[0].additional_discount) || 0;
            //       unitDiscountPercent = tierBenefitAdditionalDiscount/100;
            // }

            const itemItems = this.form.items.map((item) => {


                let price = (item.price !== null) ? unformat(item.price) : 0;
                let qty = (item.qty !== null) ? parseInt(item.qty) : 0;
                let amount = price * qty;
                let discount = 0;
                let total = amount;
                let discount_type = item.discount_type;
                let applied_discount = { type: item.discount_type, percent: 0, price: 0, value: 0 },
                    appliedDiscountObject = { type: null, percent: 0, price: 0, value: 0 };
                let remarks = (item.remarks !== null) ? item.remarks : "";
                let benefit_type = item.benefit_type;

                if(item.code !== null){


                    if(item.discount_type !== null){
                        switch (item.discount_type) {
                            case 'promo':
                            case null:
                                if(item.hasOwnProperty('promotions') && item.promotions.length > 0){
                                    discount_type = 'promo';
                                    console.log("Calculating Promo")
                                    const itemPromo = item.promotions[0];
                                    price = unformat(itemPromo.price);
                                    const discountPercent = unformat(itemPromo.discount)/100;
                                    discount = (price * discountPercent) * item.qty;
                                    total = amount - discount;
                                    applied_discount.percent = unformat(itemPromo.discount)/100;
                                    applied_discount.price = itemPromo.price;
                                    applied_discount.value = itemPromo.price * discountPercent;

                                    appliedDiscountObject = {
                                        type : 'promo',
                                        percent : discountPercent,
                                        price : unformat(itemPromo.price),
                                        value : unformat((itemPromo.price * discountPercent))
                                    }
                                    remarks = itemPromo;
                                }

                                break;

                            case 'discount':

                                const itemDiscount = item.custom_discount;
                                price = unformat(item.price);
                                const discountPercent = unformat(itemDiscount.off_price)/price;
                                discount = (price * discountPercent) * item.qty;
                                total = amount - discount;
                                applied_discount.percent = discountPercent;
                                applied_discount.price = item.price;
                                applied_discount.value = item.price * discountPercent;

                                appliedDiscountObject = {
                                    type : 'discount',
                                    percent : discountPercent,
                                    price : unformat(item.price),
                                    value : unformat(itemDiscount.off_price)
                                }

                                remarks = itemDiscount;
                                // remarks = `${discountPercent} Discount`;
                                break;
                        }
                    }else{

                        console.log("item.attr.applied_discount")
                        console.log(item.attr.applied_discount)
                        if(item.attr.hasOwnProperty("applied_discount") && item.attr.applied_discount.type === 'loyalty-discount'){
                            const itemDiscount = item.attr.applied_discount;
                            price = unformat(item.price);
                            const discountPercent = itemDiscount.percent;
                            discount = (price * discountPercent) * item.qty;
                            total = amount - discount;

                            // remarks = itemDiscount;
                            // remarks = `${discountPercent} Discount`;
                        }
                    }


                    /* const applyMultipleTierDiscount = this.form.attr.multiple_tier_discount.apply;

                if (filterBenefits.length > 0) {

                        if(item.hasOwnProperty("is_service_item") && item.is_service_item === false){

                          if(appliedDiscountObject.value > 0){


                            const itemAppliedDiscount = price - appliedDiscountObject.value

                            console.log("itemDiscountTotal",price,itemAppliedDiscount,unitDiscountPercent, item.code, applied_tier_discount)

                            if(unitDiscountPercent > 0){

                              const additionalDiscount = itemAppliedDiscount * unitDiscountPercent;
                              applied_tier_discount.apply = 'additional_discount';
                              applied_tier_discount.value = additionalDiscount;
                              const unitDiscount = (appliedDiscountObject.value + additionalDiscount);
                              discount = unitDiscount * qty;
                              total = (price * qty) - discount;
                              const benefitDiscountPercentage = discount/amount;
                              applied_discount.value = unitDiscount;
                              applied_discount.percent = benefitDiscountPercentage;

                              console.log("itemAppliedAdditionalDiscountTotal",item.code, itemAppliedDiscount, unitDiscountPercent, additionalDiscount, unitDiscount, discount, total,applied_discount)

                              remarks = `${applied_tier_discount.name}`;
                              applied_discount.type = 'loyalty-additional-discount';
                              benefit_type = 'benefit-additional-discount';
                            }

                          }else{

                            applied_discount.percent = benefitDiscountPercentage;
                            console.log("benefitDiscountPercentage",benefitDiscountPercentage)
                            let benefitDiscount = price * benefitDiscountPercentage;
                            console.log("benefitDiscount",price,benefitDiscount)
                            discount = qty * benefitDiscount;
                            console.log("discount",discount)
                            total = (price * qty) - discount;
                            remarks = `${applied_tier_discount.name}`;
                            benefit_type = 'benefit-discount';
                            applied_tier_discount.discount = benefitDiscountPercentage;
                            applied_tier_discount.additional_discount = 0;
                            console.log("itemAppliedDiscountTotal",item.code, price,benefitDiscountPercentage,benefitDiscount, discount, total)
                            applied_discount.type = 'loyalty-discount';
                            applied_discount.value = benefitDiscount;
                          }

                          applied_discount.price = price;
                        }



                } */
                }





                console.log("return",item.code,discount_type,price,discount,amount,total,remarks,applied_discount,{
                    ...item,
                    price : price,
                    discount_type,
                    benefit_type,
                    applied_discount,
                    discount : this.formatNumber(discount),
                    amount : this.formatNumber(amount),
                    total : this.formatNumber(total),
                    remarks : this.setLineRemarks(remarks),
                })
                return {
                    ...item,
                    price : price,
                    discount_type,
                    benefit_type,
                    applied_discount,
                    attr : {
                        ...item.attr
                    },
                    discount : this.formatNumber(discount),
                    amount : this.formatNumber(amount),
                    total : this.formatNumber(total),
                    remarks : this.setLineRemarks(remarks),
                }
            });

            this.form.items = itemItems;
        },
        setLineRemarks(value = null) {
            if (value!== null && typeof value === 'object' && value.apply !== undefined && value.discount !== undefined) {
                if (value.apply && value.discount > 0) {
                    return `${value.discount}% Discount`;
                } else {
                    return value.discount > 0 ? `${this.formatNumber(value.discount)} QAR Off` : "";
                }
            } else {
                return value;
            }
        },
        calculateCreditNote(index){
            const self = this;

            if(typeof self.form.credit_note_items[index] !== 'undefined') {
                const item = self.form.credit_note_items[index];

                let discount = 0;
                let remarks = null;

                if(item.promotions.length > 0){
                    const itemPromo = item.promotions[0];
                    discount = this.computeDiscount(item.qty,item.price,itemPromo,item.discount_type,item.custom_discount);
                    // remarks = this.setRemarks(item.qty,itemPromo)
                }

                if(item.discount_type === 'discount'){
                    const itemPromo = item.promotions[0];
                    discount = this.computeDiscount(item.qty,item.price,itemPromo,item.discount_type,item.custom_discount);
                    // remarks = this.setRemarks(item.qty,item.custom_discount);
                    // frm.total = this.computeTotalAmount(qty, price, frm.discount)
                }


                if(item.code !== null && item.name !== null){
                    const amount = this.computeAmount(item.qty,item.price);
                    const totalAmount = this.computeTotalAmount(item.qty,item.price,discount);
                    //  this.$set(self.form.credit_note_items[index], 'remarks',remarks)
                    this.$set(self.form.credit_note_items[index], 'discount', this.formatNumber(discount))
                    this.$set(self.form.credit_note_items[index], 'amount', amount)
                    this.$set(self.form.credit_note_items[index], 'total', totalAmount)
                }
            }
        },

        formatNumber(n) {
            return parseFloat(n).toFixed(2).replace(/./g, function(c, i, a) {
                return i > 0 && c !== "." && (a.length - i) % 3 === 0 ? "," + c : c;
            });
        },
        fnPrint(url){



            let session = JSON.parse(localStorage.getItem('user'));
            let token = session.token;

            const locationId = session.location.id

            var strWindowFeatures = "location=yes,height=600,width=900,scrollbars=yes,status=yes";
            const win = window.open(`${url}?location=${locationId}&token=${token}`, "_blank", strWindowFeatures);
            win.document.title = 'Print';
            win.print()

        },
        fnPrintDoc(url){


            var strWindowFeatures = "location=yes,height=600,width=900,scrollbars=yes,status=yes";
            const win = window.open(`https://docs.thatsliving.com/${url}`, "_blank", strWindowFeatures);
            win.document.title = 'Print';
            win.print()

        },


        toggleCheck(){
            const self = this;
            if(this.form.transaction.items.length > 0){
                const getCode = this.form.items.map(i => i.code);

                if(getCode.length > 0){
                    self.$nextTick(() => {
                        self.form.transaction.items.forEach((item,_index) => {
                            if(getCode.includes(item.code)){
                                self.$refs.itemsTable.toggleRowSelection(item,true);
                                // alert(index)
                            }
                        })

                    })



                }else{
                    self.$refs.itemsTable.clearSelection();
                }

            }
        },

        getRowKey(row){
            return row.code
        },
        fuseSearch(options, search) {
            const fuse = new Fuse(options, {
                keys: ["mobile", "label"],
                shouldSort: true
            });
            return search.length
                ? fuse.search(search).map(({ item }) => item)
                : fuse.list;
        },

        searchCustomer(v){
            const self = this;
            if(v !== ""){
                this.fnGetContacts({term : v})
            }
        },
        getCustomerSelected(v){
            const selectedCustomer = this.customer.filter(c => {
                return c.value === v;
            })[0];

            if(selectedCustomer !== null){
                this.form.attr.name = selectedCustomer.label
                this.form.attr.mobile = selectedCustomer.mobile
                this.form.attr.address = selectedCustomer.address
            }

        },

        openCustomer(){
            this.$refs.contactForm.openDialog();
        },
        contactSuccess(v){
            const contact = { label: v.name, value: v.mdbID, mobile: v.mobile, address : v.address }
            this.customer.unshift(contact);
            this.form.customer_id = v.mdbID
            this.form.mobile = v.mobile
            this.form.attr.name = v.name;
            this.form.attr.mobile = v.mobile;
            this.form.attr.address = v.address;

            // alert(v.name)
        },

        openPayment(paymentRow){

            const paymentFromData = {...paymentRow,invoice : {
                    series : this.invoice.series,
                    balance : this.invoice.balance,
                    paid : this.invoice.paid,
                    total : this.invoice.total
                },
                customer : this.form.attr.name }
            this.setPaymentStatusUpdate()
            this.setFormData(paymentFromData)
            this.$refs.updatePaymentFormVerTwo.openDialog();
        },
        openUpdatePaymentWindow(){
            this.paymentFormStatus = 'create'
            const pForm = {...this.paymentForm,invoice_series : this.form.seires, customer : this.form.attr.name + " "+ this.form.attr.mobile}
            // this.paymentForm.invoice_series = this.form.series
            this.paymentForm = pForm
            this.$refs.updatePaymentForm.openDialog();
        },
        addMoreDiscount(){

        },
        sendRequest(){
            if (this.request) this.cancelRequest();
            this.makeRequest()
        },
        cancelRequest(){
            this.request.cancel();
            this.clearOldRequest("Cancelled");
        },

        makeRequest(){
            const axiosSource = axios.CancelToken.source();
            this.request = { cancel: axiosSource.cancel, msg: "Loading..." };
            axios
                .get("/api/user/filter", { cancelToken: axiosSource.token })
                .then(() => {
                    this.clearOldRequest("Success");
                })
                .catch(this.logResponseErrors);
        },
        logResponseErrors(err) {
            if (axios.isCancel(err)) {
                // console.log("Request cancelled");
            }
        },
        clearOldRequest(msg) {
            this.request.msg = msg;
            this.requests.push(this.request);
            this.request = null;
        },


        paymentPushToSap(obj){
            const self = this;


            const {document} = obj.attr;

            if(typeof document === 'undefined' || (document?.entry === null || document?.entry === '')){
                MessageBox.confirm(`Payment ${obj.series} will be push to SAP. Are you sure want to continue?`, 'Warning', {
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Cancel',
                    type: 'warning'
                }).then(() => {
                    obj.loading = true


                    self.integrate({endpoint : 'payments',series : obj.series, index : 0}).then((response) => {
                        self.$ThatsNotify.success({
                            message : response.message
                        });
                        obj.loading = false
                    }).catch((error) => {
                        self.$ThatsNotify.warning({
                            message : error.response.data.message
                        });
                        obj.loading = false
                    });


                    /*  self.sapPaymentIntegration({series : obj.series}).then(function(response){
                          self.$ThatsNotify.success({
                            message : `${response.message}`
                        });
                        obj.loading = false
                  }).catch((error) => {

                    self.formLoading = false
                    self.modify = false
                    self.response.error = 1;
                    obj.loading = false

                    if(error.response.status === 500){
                          self.$ThatsNotify.error({
                          message : error.message,
                          timeOut: 20000
                        })
                    }
                    if(error.response.status === 422){
                      if(error.response.data.hasOwnProperty('message')){
                          self.$ThatsNotify.error({
                          message : error.response.data.message,
                          timeOut: 20000
                        })
                      }
                    }
                    if (error.response) {
                        if(error.response.data.hasOwnProperty('errors')){

                          if(error.response.status === 400){
                               self.$ThatsNotify.error({
                                message : `Unable to push to sap. Please contact System Administrator. <br /><strong>Details</strong> : ${error.response.data.errors.value}`,
                                timeOut: 20000
                              })
                          }
                          self.$refs.observer.setErrors(error.response.data.errors);
                          this.showError = true
                        }



                      } else if (error.request) {
                          self.response.message = error.request
                      } else {
                          self.response.message = error.message
                      }
                      return;
                  }) */
                })
            }


        },
        deliveryNotePushToSap(obj){
            const self = this;


            const {document} = obj.attr;

            if(typeof document === 'undefined' || (document?.entry === null || document?.entry === '')){
                MessageBox.confirm(`Delivery Note ${obj.series} will be push to SAP. Are you sure want to continue?`, 'Warning', {
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Cancel',
                    type: 'warning'
                }).then(() => {
                    obj.loading = true
                    self.sapDeliveryNoteIntegration({series : obj.series}).then(function(response){
                        self.$ThatsNotify.success({
                            message : `${response.message}`
                        });
                        obj.loading = false
                    }).catch((error) => {

                        self.formLoading = false
                        self.modify = false
                        self.response.error = 1;
                        obj.loading = false

                        if(error.response.status === 500){
                            self.$ThatsNotify.error({
                                message : error.message,
                                timeOut: 20000
                            })
                        }
                        if(error.response.status === 422){
                            if(error.response.data.hasOwnProperty('message')){
                                self.$ThatsNotify.error({
                                    message : error.response.data.message,
                                    timeOut: 20000
                                })
                            }
                        }
                        if (error.response) {
                            if(error.response.data.hasOwnProperty('errors')){

                                if(error.response.status === 400){
                                    self.$ThatsNotify.error({
                                        message : `Unable to push to sap. Please contact System Administrator. <br /><strong>Details</strong> : ${error.response.data.errors.value}`,
                                        timeOut: 20000
                                    })
                                }
                                self.$refs.observer.setErrors(error.response.data.errors);
                                this.showError = true
                            }



                        } else if (error.request) {
                            self.response.message = error.request
                        } else {
                            self.response.message = error.message
                        }
                        return;
                    })
                })
            }


        },
        creditNotePushToSap(obj){
            const self = this;


            const {document} = obj.attr;

            if(typeof document === 'undefined' || (document?.entry === null || document?.entry === '')){
                MessageBox.confirm(`Credit Note ${obj.series} will be push to SAP. Are you sure want to continue?`, 'Warning', {
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Cancel',
                    type: 'warning'
                }).then(() => {
                    obj.loading = true
                    self.sapCreditNoteIntegration({series : obj.series}).then(function(response){
                        self.$ThatsNotify.success({
                            message : `${response.message}`
                        });
                        obj.loading = false
                    }).catch((error) => {

                        self.formLoading = false
                        self.modify = false
                        self.response.error = 1;
                        obj.loading = false

                        if(error.response.status === 500){
                            self.$ThatsNotify.error({
                                message : error.message,
                                timeOut: 20000
                            })
                        }

                        if(error.response.status === 422){
                            if(error.response.data.hasOwnProperty('message')){
                                self.$ThatsNotify.error({
                                    message : error.response.data.message,
                                    timeOut: 20000
                                })
                            }
                        }

                        if (error.response) {
                            if(error.response.data.hasOwnProperty('errors')){

                                if(error.response.status === 400){
                                    self.$ThatsNotify.error({
                                        message : `Unable to push to sap. Please contact System Administrator. <br /><strong>Details</strong> : ${error.response.data.errors.value}`,
                                        timeOut: 20000
                                    })
                                }
                                self.$refs.observer.setErrors(error.response.data.errors);
                                this.showError = true
                            }



                        } else if (error.request) {
                            self.response.message = error.request
                        } else {
                            self.response.message = error.message
                        }
                        return;
                    })
                })
            }


        },

        pushToSap(){
            // this.modify = true
            // this.disabled = false
            // this.returnItem = false
            // if(this.form.items.length === 0){
            //   const formItemVar = this.formItemVar();
            //     this.form.items.push(formItemVar)
            // }
            // this.initSortable();

            const self = this;


            if(this.formStatus === 'update'){


                const {document} = this.formOriginal.attr;


                if(document.entry === null || document.entry === ''){
                    MessageBox.confirm('This will push to SAP. Are you sure want to continue?', 'Warning', {
                        confirmButtonText: 'OK',
                        cancelButtonText: 'Cancel',
                        type: 'warning'
                    }).then(() => {
                        self.pushInvoiceToSapLoading = true


                        self.integrate({endpoint : 'invoices',series : self.form.invoice.series, index : 0}).then((response) => {
                            self.$ThatsNotify.success({
                                message : response.message
                            });
                            self.pushInvoiceToSapLoading = false
                        }).catch((error) => {
                            self.$ThatsNotify.warning({
                                message : error.response.data.message
                            });
                            self.pushInvoiceToSapLoading = false
                        });

                        /* self.sapIntegration({...self.form.invoice}).then(function(response){
                          self.$ThatsNotify.success({
                            message : response.message
                        });

                        self.pushInvoiceToSapLoading = false
                  }).catch((error) => {
                        self.$ThatsNotify.warning({
                            message : error.response.data.message
                        });
                        self.pushInvoiceToSapLoading = false
                  }) */
                    })
                }


            }

        },
        enableModify(){
            this.modify = true
            this.disabled = false
            this.returnItem = false
            if(this.form.items.length === 0){
                const formItemVar = this.formItemVar();
                this.form.items.push(formItemVar)
            }
            // this.initSortable();
        },
        enableExchange(){
            this.modifyReturmItem = false
            this.modify = false
            this.disabled = false
            this.returnItem = false
            this.exchangeItem = true
        },
        enableModifyCreditNote(){
            this.modifyReturmItem = true
            this.modify = false
            this.disabled = false
            this.returnItem = false
            this.exchangeItem = false
        },

        openLink(_target) {
            // console.log(target);
        },
        copyLink(target) {
            console.log(target);
        },

        rowContectMenu(row,_col,e){
            const self = this

            if((row.code !== null && this.formStatus === 'create') || (this.formStatus === 'update' && row.code !== null && (this.modify === true || this.form.invoice.series === null))){
                this.context = row
                this.viewMenu = true;
                this.$nextTick(function() {
                    self.$refs.right.focus();
                    self.setMenu(e.y, e.x)
                }.bind(this));
                e.preventDefault();
            }
        },

        setMenu: function(top, left) {

            const largestHeight = window.innerHeight - this.$refs.right.offsetHeight - 75;
            const largestWidth = window.innerWidth - this.$refs.right.offsetWidth - 75;

            if (top > largestHeight) top = largestHeight;

            if (left > largestWidth) left = largestWidth;

            this.top = top + 'px';
            this.left = left + 'px';
        },

        closeMenu: function() {
            this.viewMenu = false;
            this.context = null
        },

        deleteRow(){
            const self = this
            if(this.context != null){

                if(self.context.hasOwnProperty('id')){
                    const matchItem = this.form.items.filter((i) => {
                        return i.id != self.context.id;
                    })
                    self.form.items = matchItem
                }
                if(self.context.hasOwnProperty('item_id')){
                    const matchItem = this.form.items.filter((i) => {
                        return i.item_id != self.context.item_id;
                    })
                    self.form.items = matchItem
                }

                this.closeMenu()
            }

        },
        moveUpRow(){
            const self = this
            if(this.context != null){
                const index = this.form.items.findIndex(e => e.code == self.context.code);

                if (index > 0) {
                    const el = this.form.items[index];
                    this.$set(this.form.items, index, this.form.items[index - 1]);

                    this.$set(this.form.items, index - 1, el);
                    this.closeMenu()
                }
            }
        },
        moveDownRow(){
            const self = this
            if(this.context != null){
                const index = this.form.items.findIndex(e => e.code == self.context.code);

                if (index !== -1 && index < self.form.items.length - 2) {
                    const el = self.form.items[index];
                    this.$set(self.form.items, index, self.form.items[index + 1]);
                    this.$set(self.form.items, index + 1, el);
                    this.closeMenu()
                }

            }
        },

        cancelModification(){

            const self = this;
            self.modifyReturmItem = false
            self.exchangeItem = false
            self.returnItem = false
            self.cancelItem = false
            self.refundItem = false
            self.modify = false
            self.disabled = true
            self.requestedExchange = false
            self.refundRequested = false
            self.selected = []
            // self.getRecord({token_id : self.form.token_id})

            const frmOriginal = JSON.parse(JSON.stringify(this.formOriginal));

            self.setFormRecord(frmOriginal)

        },

        insertRow(){
            const self = this
            if(this.context != null){
                if(self.context.hasOwnProperty('id')){
                    const index = this.form.items.findIndex(e => e.id == self.context.id);
                    const formItemVar = this.formItemVar();
                    this.form.items.splice((index + 1), 0, formItemVar);
                }
                if(self.context.hasOwnProperty('item_id')){
                    const index = this.form.items.findIndex(e => e.item_id == self.context.item_id);
                    const formItemVar = this.formItemVar();
                    this.form.items.splice((index + 1), 0, formItemVar);
                }
                this.closeMenu()
            }


        },

        returnItems(){
            const lastTblKey = this.tblKey + 1;
            this.setTblKey(lastTblKey)
            this.returnItem = true;
        },
        cancelItems(){
            const self = this;
            this.form.items.forEach((row, index) => {

                const getAllCancelItems = [];

                let itemDeductQty = 0;

                if(self.form.credit_note_items.length > 0){
                    const returnItemsQty = self.form.credit_note_items.filter((i) => {
                        return  i.transaction_item_id === row.id && (i.status === 'for-approval' || i.status ==='approved')
                    });

                    if(returnItemsQty.length > 0){
                        const totalReturnItem = returnItemsQty.map((i) => i.qty).reduce((a,b) => a + b);

                        console.log(`${totalReturnItem} - total return`)

                        itemDeductQty += totalReturnItem
                    }


                }


                self.form.cancellation.forEach((item) => {
                    if(item.items.length > 0){
                        item.items.forEach((innerItem) => {
                            getAllCancelItems.push(innerItem);
                        });
                    }
                });

                const cancelledItems = getAllCancelItems.filter((i) => {
                    return  i.transaction_item_id === row.id && (i.status === 'for-approval' || i.status ==='approved')
                });

                if(cancelledItems.length > 0){
                    const totalCancelItem = cancelledItems.map((i) => i.qty).reduce((a,b) => a + b);
                    console.log(`${totalCancelItem} - total cancel`)
                    itemDeductQty += totalCancelItem
                }

                const changeQty = parseInt(row.qty) - itemDeductQty


                self.$set(self.form.items,index,{...row, qty : changeQty, max : changeQty});

                self.calculatePrice(index)
                const lastTblKey = self.tblKey + 1;
                self.setTblKey(lastTblKey)
            })

            this.cancelItem = true;
        },
        exchangeItems(){
            const self = this;
            this.form.items.forEach((row, index) => {

                const exchangeItemsArray = [];

                let itemDeductQty = 0;

                if(self.form.credit_note_items.length > 0){
                    const returnItemsQty = self.form.credit_note_items.filter((i) => {
                        return  i.transaction_item_id === row.id && (i.status === 'for-approval' || i.status ==='approved')
                    });

                    if(returnItemsQty.length > 0){
                        const totalReturnItem = returnItemsQty.map((i) => i.qty).reduce((a,b) => a + b);
                        itemDeductQty += totalReturnItem
                    }


                }

                if(self.form.exchange.length > 0){
                    self.form.exchange.forEach((item) => {
                        if(item.items.length > 0){
                            item.items.forEach((innerItem) => {
                                exchangeItemsArray.push(innerItem);
                            });
                        }
                    });


                    const matchExchangeItem = exchangeItemsArray.filter((i) => {
                        return  i.transaction_item_id === row.id && (i.status === 'for-approval' || i.status ==='approved')
                    });


                    if(matchExchangeItem.length > 0){
                        const totalCancelItem = matchExchangeItem.map((i) => i.qty).reduce((a,b) => a + b);
                        itemDeductQty += totalCancelItem
                    }
                }


                const changeQty = parseInt(row.qty) - itemDeductQty
                console.log({...row, qty : changeQty, max : changeQty, remarks : ""})
                self.$set(self.form.items,index,{...row, qty : changeQty, max : changeQty, remarks : ""});

                self.calculatePrice(index)
                const lastTblKey = self.tblKey + 1;
                self.setTblKey(lastTblKey)
            })
            this.requestedExchange = true;
            this.exchangeItem = true;
        },

        refundItems(){
            const self = this;
            this.form.items.forEach((row, index) => {

                const refundItemsArray = [];

                let itemDeductQty = 0;

                if(self.form.hasOwnProperty('refund_item') && self.form.refund_item.length > 0){
                    const returnItemsQty = self.form.refund_item.filter((i) => {
                        return  i.transaction_item_id === row.id && (i.status === 'for-approval' || i.status ==='approved')
                    });

                    if(returnItemsQty.length > 0){
                        const totalReturnItem = returnItemsQty.map((i) => i.qty).reduce((a,b) => a + b);
                        itemDeductQty += totalReturnItem
                    }


                }

                if(self.form.hasOwnProperty('refund_item') &&  self.form.refund.length > 0){
                    self.form.refund.forEach((item) => {
                        if(item.items.length > 0){
                            item.items.forEach((innerItem) => {
                                refundItemsArray.push(innerItem);
                            });
                        }
                    });


                    const matchRefundItem = refundItemsArray.filter((i) => {
                        return  i.transaction_item_id === row.id && (i.status === 'for-approval' || i.status ==='approved')
                    });


                    if(matchRefundItem.length > 0){
                        const totalRefundItem = matchRefundItem.map((i) => i.qty).reduce((a,b) => a + b);
                        itemDeductQty += totalRefundItem
                    }
                }


                const changeQty = parseInt(row.qty) - itemDeductQty
                self.$set(self.form.items,index,{...row, qty : (changeQty < 0 ? 0: changeQty), max : changeQty});

                self.calculatePrice(index)
                const lastTblKey = self.tblKey + 1;
                self.setTblKey(lastTblKey)
            })
            this.refundRequested = true;
            this.refundItem = true;
        },
        cancelReturnItems(){
            this.returnItem = false;
            this.selected = [];
        },
        requestCreditNote(){
            const self = this;

            this.validated = true
            const isValid = this.$refs.observer.validate();

            console.log(isValid);
            this.showError = false
            if (isValid) {
                this.$refs.confirmCreditNoteForm.openDialog();
            }


            //
            /*
          self.dialogFormVisible = true;
          const params = {
            status : this.form.status,
            attr : this.form.attr,
            transaction_id : this.form.mdbID,
            items : this.selected,
            action : 'exchange'
          }

          self.formLoading = true
          this.storeCreditNote(params).then((response) => {

              self.$ThatsNotify.success({
                                message : response.message
                              })
              self.$emit('success')
              self.setFormRecord(response.data)
              self.formLoading = false
              self.newArrange = []
              self.selected = []
              self.returnItem = false
              self.modify = false
          }) */
        },
        canSelectRow(row, _index){
            const self = this;
            if(this.formStatus === 'update' && (this.form.invoice !== null && this.form.invoice.series !== null) && this.modify === false && (this.returnItem === true || this.cancelItem === true || this.exchangeItem === true || this.refundItem === true)){

                if(this.returnItem === true){


                    const transactionItemQty = self.form.credit_note_items.filter((itm) =>{
                        return itm.transaction_item_id == row.id && itm.status !== 'cancelled';
                    }).map(i => i.qty)

                    let  totalTransactionItemQty = 0

                    if(transactionItemQty.length > 0){
                        totalTransactionItemQty = transactionItemQty.reduce((a,b) => a + b);
                    }

                    const allowedReturnQty = row.qty - totalTransactionItemQty;



                    console.log(`${row.id} --- row.qty ${row.qty} - totalTransactionItemQty ${totalTransactionItemQty} = allowedReturnQty - ${allowedReturnQty}`)
                    // const credi${}tNotes = this.form.credit_note_items.map(i => i.code);

                    // console.log(row)
                    //creditNotes.includes(row.code) ||
                    if(totalTransactionItemQty >= row.qty){
                        return false
                    }
                    return true
                }

                /*  if(this.cancelItem === true){

                      if(row.qty <= 0){
                        return false
                      }
                  return true;
              }
              if(this.exchangeItem === true){
                      if(row.qty <= 0){
                        return false
                      }
                  return true;
              }
              if(this.refundItem === true){
                      if(row.qty <= 0){
                        return false
                      }
                  return true;
              } */
                return false;
            }else{
                return true
            }
        },
        enableRow(row){

            if(this.formStatus === 'update' && (this.form.invoice !== null && this.form.invoice.series !== null) && this.modify === false && this.returnItem === true){
                const creditNotes = this.form.credit_note_items.map(i => i.code);
                if(creditNotes.includes(row.code)){
                    return false
                }else{
                    return true
                }
            }else{
                return true
            }
        },

        enableModifyAndCreditNote(row){
            if(this.disabled === false ||
                ((this.invoice.series !== null && (this.enableReturn.includes(this.form.status) && (['full-payment','partial-payment','cancelled'].includes(this.form.attr.payment_status)))) &&

                    (this.cancelItem === true || this.returnItem === true || this.modify === true))){
                // return false

                if(this.formStatus === 'update' && (this.form.invoice !== null && this.form.invoice.series !== null) && this.modify === false && this.returnItem === true){
                    const creditNotes = this.form.credit_note_items.map(i => i.code);
                    if(creditNotes.includes(row.code)){
                        return false
                    }

                }

                return false

            }
            return true
        },

        enableQuantity(row){
            // console.log(row)
            if(this.disabled === false ||
                (this.invoice.series !== null &&
                    ((this.enableReturn.includes(this.form.status) || this.cancelItemEnable.includes(this.form.status) || this.refundItemEnable.includes(this.form.status)) &&
                        (['full-payment','partial-payment','cancelled'].includes(this.form.attr.payment_status)) &&
                        (this.cancelItem === true || this.returnItem === true || this.modify === true || this.refundItem === true)
                    ))){


                return false;
            }


            /* if(this.disabled === false ||
            ((this.invoice.series !== null && (this.enableReturn.includes(this.form.status) && (['full-payment','partial-payment','cancelled'].includes(this.form.attr.payment_status)))) &&

            (this.cancelItem === true || this.returnItem === true || this.modify === true))){
              // return false

              if(this.formStatus === 'update' && (this.form.invoice !== null && this.form.invoice.series !== null) && this.modify === false && this.returnItem === true){
                  const creditNotes = this.form.credit_note_items.map(i => i.code);
                  if(creditNotes.includes(row.code)){
                    return false
                  }

               }

              return false

          } */
            return true
        },

        handleCheckAllChange(val) {
            var self = this;
            this.isIndeterminate = false;
            this.form.items.forEach((row,index) => {
                self.$set(self.form.items,index, {...row,tbo : val});
            })


        },
        handleCheckedTboChange(_value) {
            const self = this;
            const checked = this.form.items.filter(row => {
                return row.tbo === true
            }).map(e => e.tbo).length;

            const isIndeterminate = checked > 0 && checked < this.form.items.length;

            const checkAllTbo = checked === this.form.items.length

            this.$nextTick(() => {
                self.isIndeterminate = isIndeterminate
                self.checkAllTbo = checkAllTbo
            });

        },
        handleCheckAllChangeDelivered(val) {
            var self = this;
            this.isIndeterminateDelivered = false;
            this.form.items.forEach((row,index) => {
                self.$set(self.form.items,index, {...row,delivered : val});
            })


        },
        handleCheckedTboChangeDelivered(_value) {
            const self = this;
            const checked = this.form.items.filter(row => {
                return row.delivered === true
            }).map(e => e.delivered).length;

            const isIndeterminate = checked > 0 && checked < this.form.items.length;

            const checkAll = checked === this.form.items.length

            this.$nextTick(() => {
                self.isIndeterminateDelivered = isIndeterminate
                self.checkAllDelivered = checkAll
            });

        },

        renderHeader(h,{column}){
            return h(
                'div',
                [
                    h('span', column.label),
                    h('el-checkbox',{
                        style:'display:inline-flex;margin-left:5px;',
                        on:{
                            Change: ($event, column) => this.select($event, column), // check the event $event, column, where $event=true, column is the input dom when printing in select
                        }
                    }),
                ],
            )
        },

        select(obj, $event = this.targetEv){
            console.log(obj)
            console.log($event)
        },


        enablePrice(row){

            if( this.$helpers.isSystemAdministrator() && this.modify == true){
                return true
            }else{
                const cat = [137, 136, 126, 83, 127, 129, 132, 130, 135, 128,142, 143,95,133, 87];

                if((this.$helpers.isDeliveryTeam() || this.$helpers.isInteriorDesigner() || this.$helpers.isSalesExecutive() || this.$helpers.isSystemAdministrator()) && row.category !== null && cat.includes(row.category) && this.modify === true){
                    return true;
                }
                return false
            }
        },

        quantityChangeOnReturn(v){
            if(this.returnItem === true || this.cancelItem === true){
                const qty = parseInt(v.qty);
                if(qty > v.max){
                    v.qty = v.max;
                }
                if(qty < 1){
                    v.qty = 1;
                }
            }
        },
        quantityChange(v){
            const code = v.code;
            const qty = parseInt(v.qty);
            if(qty > v.max){
                v.qty = v.max;
            }
            if(qty < 1){
                v.qty = 1;
            }
        },

        handleOutsideClick(){
            const self = this;
            if(this.form.items.length > 0){
                this.form.items.forEach((_item,index) => {
                    self.$set(self.form.items[index], 'show_search', false);
                })
            }
        },

        clickShowSearch(row,index){
            if(this.modify === true && (row.category === 95 || row.category === 132 || row.category === 127)){

                this.$set(this.form.items[index], 'show_search', true);
            }
        },

        calculatePrice(index){
            const self = this
            const item = self.form.items[index];

            this.$set(self.form.items[index], 'price',unformat(item.formatted_price))

            if(typeof self.form.items[index] !== 'undefined') {
                const item = self.form.items[index];

                let discount = 0;
                let remarks = null;

                if(item.promotions.length > 0){
                    const itemPromo = item.promotions[0];
                    discount = this.computeDiscount(item.qty,item.price,itemPromo,item.discount_type,item.custom_discount);
                    // remarks = this.setRemarks(item.qty,itemPromo)
                }

                if(item.discount_type === 'discount'){
                    const itemPromo = item.promotions[0];
                    discount = this.computeDiscount(item.qty,item.price,itemPromo,item.discount_type,item.custom_discount);
                    // remarks = this.setRemarks(item.qty,item.custom_discount);
                }


                if(item.code !== null && item.name !== null){
                    const amount = this.computeAmount(item.qty,item.price);
                    const totalAmount = this.computeTotalAmount(item.qty,item.price,discount);
                    //  this.$set(self.form.items[index], 'remarks',remarks)
                    this.$set(self.form.items[index], 'discount', this.formatNumber(discount))
                    this.$set(self.form.items[index], 'amount', amount)
                    this.$set(self.form.items[index], 'total', totalAmount)

                }
            }


        },

        removeFormat(index){
            const self = this;
            const item = self.form.items[index];
            let amount = 0
            if (item.price !== 0) {
                amount = formatMoney(item.price, {
                    symbol: '',
                    format: '%v',
                    thousand: '',
                    decimal: '.',
                    precision: 2
                })
            }

            this.$set(self.form.items[index], 'formatted_price',amount)
        },
        formatOnBlur(index){
            const self = this;
            const item = self.form.items[index];

            let amount = 0
            if (item.formatted_price !== 0) {
                amount = formatMoney(item.formatted_price, {
                    symbol: '',
                    format: '%v',
                    thousand: ',',
                    decimal: '.',
                    precision: 2
                })
            }

            this.$set(self.form.items[index], 'formatted_price',amount)
            this.$set(self.form.items[index], 'price',unformat(amount))

        },

        closeFabric(index){
            this.$set(this.form.items[index],'show_search',false);
        },

        enablePullOut(){
            this.pullout = true;
            this.modify = false;
            this.modifyReturmItem = false
            this.returnItem = false
        },
        requestPullOut(){
            const self = this;

            const frm = {
                transaction_id : this.form.mdbID,
                description : "",
                attr : this.form.attr,
                pullout : this.creditNoteSelection
            }
            self.formLoading = true
            this.storePullOut(frm).then((response) => {
                self.$ThatsNotify.success({
                    message : response.message
                })
                self.pullout = false;
                self.$emit('success')
                // self.setFormRecord(response.data)
                self.formLoading = false
                self.newArrange = []
                self.selected = []
                self.returnItem = false
                self.modify = false
                self.pullout = false
            })

            /*  self.formLoading = true
          this.storeCreditNote(params).then((response) => {

              self.$ThatsNotify.success({
                                message : response.message
                              })
              self.$emit('success')
              self.setFormRecord(response.data)
              self.formLoading = false
              self.newArrange = []
              self.selected = []
              self.returnItem = false
              self.modify = false
          }) */
        },

        handleCreditNoteSelectionChange(v){
            // console.log(v)

            this.creditNoteSelection = v
        },


        openPullOut(v){

            const pullOutData = {items:[],transactions: {series : null,invoice: { series : null},...v}}
            const frmOriginal = JSON.parse(JSON.stringify(pullOutData));
            this.setPullOutStatusUpdate()
            this.setPullOutFormRecord(frmOriginal)
            this.getPullOutRecord({token_id : v.token_id});
            this.$refs.pullOutForm.openDialog()
        },

        getPurchaseRequestSummaries(param) {
            const self = this;
            const { columns, data } = param;
            const sums = [];
            // const numberColumns = [2,3,4,5,6,7,8]

            let totalCol = 2;
            let totalQty= 3;
            // let totalPrice= 4;
            let totalAmount= 5;
            // let totalDiscount= 6;
            let totalTotal= 7;

            columns.forEach((_column, index) => {
                if (index === totalCol) {
                    sums[index] = 'Total';
                    return;
                }


                if (index === totalQty) {
                    const qty = data.map(item => {
                        return (item.code !== null && item.name !== null) ? parseInt(item.qty) : 0
                    })

                    if (!qty.every(qty => isNaN(qty))) {
                        sums[index] = qty.reduce((prev, curr) => {
                            const value = Number(curr);
                            if (!isNaN(value)) {
                                return prev + curr;
                            } else {
                                return prev;
                            }
                        }, 0);
                    }
                    return;
                }

                if (index === totalAmount) {
                    const amount = data.map(item => {
                        return parseInt(item.qty) * parseFloat(item.price);
                    });

                    if (!amount.every(amount => isNaN(amount))) {
                        const sum = amount.reduce((prev, curr) => {
                            const value = Number(curr);
                            if (!isNaN(value)) {
                                return prev + curr;
                            } else {
                                return prev;
                            }
                        }, 0);

                        sums[index] = this.formatNumber(sum)

                    }

                    return;
                }


                if (index === totalTotal) {
                    const total = data.map(item => {
                        let d = 0;


                        if(item.hasOwnProperty("promotions") && item.promotions.length > 0){
                            const itemPromo = item.promotions[0];
                            d = this.computeDiscount(item.qty,item.price,itemPromo,item.discount_type,item.custom_discount);
                        }

                        if(item.discount_type === 'discount'){
                            const itemPromo = item.promotions[0];
                            d = this.computeDiscount(item.qty,item.price,itemPromo,item.discount_type,item.custom_discount);

                        }


                        return self.computeTotalAmountNumber(item.qty,item.price,d);;
                    });

                    if (!total.every(total => isNaN(total))) {
                        const sum = total.reduce((prev, curr) => {
                            const value = Number(curr);
                            if (!isNaN(value)) {
                                return prev + curr;
                            } else {
                                return prev;
                            }
                        }, 0);


                        //  this.form.total =  this.formatNumber(sum)
                        this.total =  this.formatNumber(sum)
                        sums[index] = this.formatNumber(sum)

                    }


                    return;
                }



            });

            // this.toggleCheck()

            return sums;
        },

        editCustomer(){
            // this.customerField = true
            const self = this;
            const {address,mobile, name,email} = this.form.attr

            const em = (typeof email === 'undefined') ? "" : email
            const addr = (typeof address === 'undefined') ? "" : address

            if(this.form.customer_id !== null){
                this.setFormContactStatusUpdate()
                this.getContact({mdbID : this.form.customer_id,mobile : mobile,address : addr, name : name, email : email}).then((response) => {
                    // self.setFormRecordUpdate(response)

                    const contactFormUpdate = {
                        row : response
                    }
                    self.setFormRecordUpdate(contactFormUpdate)
                });
                this.$refs.contactForm.openDialog()
            }else{
                const contacts = {
                    series : 0,
                    name : name,
                    mobile : mobile,
                    email : em,
                    reference : '',
                    description : null,
                    image : [],
                    attr : {
                        address : addr,
                        email : em,
                        origin : 'portal',
                    },
                    status : 'active'
                }

                this.storeContactRecord(contacts).then((response) => {

                    const v = response.data;

                    self.form.customer_id = v.mdbID
                    self.form.mobile = v.mobile
                    self.form.attr.name = v.name;
                    self.form.attr.mobile = v.mobile;
                    self.form.attr.address = v.address;
                    self.form.attr.cx_series = v.series;

                    self.setFormContactStatusUpdate()
                    self.setFormRecordUpdate(response.data)
                    self.$refs.contactsFormUpdate.openDialog()
                })
            }


        },


        contactUpdateSuccess(v){
            // console.log(v)/

            // const {address, mobile, name, email, series} = v
            // this.form.attr = {...this.form.attr, address : address, mobile : mobile, name : name, email : email, cx_series : series}

            const {mobile, name} = v
            const {address, email, address2} = v.attr
            this.form.attr = {...this.form.attr, address : address,address2 : address2, mobile : mobile, name : name, email : email}
            let ndx = null
            const con = this.customer.filter((e,index) => {
                ndx = index
                return e.value === v.mdbID
            })[0];

            const finalContact = {
                opt : name + " "+ mobile,
                label :name,
                value : con.value,
                mobile : mobile,
                address : address,
            }



            if(ndx !== null){


                this.$set(this.customer,ndx,finalContact)
                let add = ""
                add += (v.attr.address != "" && v.attr.address !== null) ? v.attr.address  : "";

                add += (v.attr.address2.street != "" && v.attr.address2.street !== null) ? ","+v.attr.address2.street  : "";
                add += (v.attr.address2.building!= "" && v.attr.address2.building!== null) ? ","+v.attr.address2.building : "";
                add += (v.attr.address2.zone != "" && v.attr.address2.zone !== null) ? ","+v.attr.address2.zone  : "";
                add += (v.attr.address2.area != "" && v.attr.address2.area !== null) ? "/"+v.attr.address2.area  : "";
                add += (v.attr.address2.city != "" && v.attr.address2.city !== null && typeof v.attr.address2.city !== 'undefined') ? "/"+v.attr.address2.city  : "";

                this.displayAddress = add.trim();

            }
        },

        computeDiscountForLoop(form){
            const self = this;

            const itemForm = form
            const {custom_discount, price} = itemForm;
            const {discount, apply} = custom_discount;
            const unFormatPrice = unformat(price);
            let unFormatDiscount = unformat(discount);

            if(unFormatDiscount !== null){



                if(parseInt(unFormatDiscount) > 0){
                    itemForm.discount_type = 'discount'
                }else{
                    itemForm.discount_type = null
                }

                if(unFormatDiscount === ""){
                    unFormatDiscount = 0
                }


                if(apply === true || apply === 1){
                    const off = (unFormatDiscount / 100) * unFormatPrice;
                    const promo = unFormatPrice - off;

                    itemForm.custom_discount.promo = self.formatNumber(promo)
                    itemForm.custom_discount.off_price = self.formatNumber(off)
                }else{

                    if(unFormatDiscount === ""){
                        unFormatDiscount = 0;
                    }
                    const promo = unFormatPrice - unFormatDiscount;
                    itemForm.custom_discount.promo = self.formatNumber(promo)
                    itemForm.custom_discount.off_price = self.formatNumber(unFormatDiscount)
                }


            }
            return itemForm;
        },
        openNewItem(){
            this.setItemFormStatusCreate()

            if(this.checkSystemSupport){
                this.$refs.itemForm.openDialog()
            }else{
                this.$refs.promptPasswordForm.openDialog()
            }
        },

        isAuthorized(value){
            if(value){
                this.$refs.itemForm.openDialog()
            }
        },

        requestItemCancellation(){
            const self = this;
            const params = {
                status : this.form.status,
                attr : this.form.attr,
                transaction_id : this.form.mdbID,
                items : this.selected,
            }

            self.formLoading = true
            this.storeItemCancellation(params).then((response) => {

                self.$ThatsNotify.success({
                    message : response.message
                })
                self.$emit('success')
                self.setFormRecord(response.data)
                self.formLoading = false
                self.newArrange = []
                self.selected = []
                self.returnItem = false
                self.modify = false
                self.cancelItem = false
            })
        },
        /* ITEM CANCEL */
        requestCancelItem(){
            const self = this;
            const params = {
                status : this.form.status,
                attr : this.form.attr,
                transaction_id : this.form.mdbID,
                items : this.selected,
            }

            self.cancelItemLoading = true
            self.requestedCancel = true
            self.requestedExchange = true
            this.storeItemCancellation(params).then((response) => {
                self.$ThatsNotify.success({
                    message : response.message
                })
                self.$emit('success')
                self.setFormRecord(response.data)
                self.requestedExchange = false
                self.cancelItemLoading = false
                self.requestedCancel = false
                self.cancelItem = false
                self.newArrange = []
                self.selected = []
                self.returnItem = false
                self.modify = false
            }).catch((error) => {
                console.log(error)
            })
        },
        requestExchangeItem(){
            const self = this;
            const params = {
                status : this.form.status,
                attr : this.form.attr,
                transaction_id : this.form.mdbID,
                items : this.selected,
            }

            // self.exchangeItemLoading = true

            this.setItemToExchange(this.selected);
            this.$refs.exchangeForm.openDialog();
            // this.storeItemExchange(params).then((response) => {
            //     self.$ThatsNotify.success({
            //                       message : response.message
            //                     })
            //     self.$emit('success')
            //     self.setFormRecord(response.data)
            //     self.exchangeItemLoading = false
            //     self.cancelItem = false
            //     self.newArrange = []
            //     self.selected = []
            //     self.returnItem = false
            //     self.modify = false
            // }).catch((error) => {
            //     console.log(error)
            // })
        },

        requestExchange(){
            const self = this;
            const params = {
                status : this.form.status,
                attr : this.form.attr,
                transaction_id : this.form.mdbID,
                token_id : this.form.invoice.token_id,
                exchange : this.selected,
            }
            self.exchangeItemLoading = true
            this.storeItemExchange(params).then((response) => {
                self.$ThatsNotify.success({
                    message : response.message
                })
                self.$emit('success')
                // self.setFormRecord(response.data)
                self.exchangeItemLoading = false
                self.requestedExchange = false
                self.cancelItem = false
                self.newArrange = []
                self.selected = []
                self.returnItem = false
                self.exchangeItem = false
                self.modify = false
            }).catch((error) => {
                console.log(error)
            })
            this.$refs.exchangeForm.closeDialog();
        },
        requestRefund(){
            const self = this;
            const params = {
                status : this.form.status,
                attr : this.form.attr,
                transaction_id : this.form.mdbID,
                token_id : this.form.invoice.token_id,
                items : this.selected,
            }
            self.refundItemLoading = true
            this.storeRefund(params).then((response) => {
                self.$ThatsNotify.success({
                    message : response.message
                })
                self.$emit('success')
                self.refundItemLoading = false
                self.refundRequested = false
                self.refundItem = false
                self.cancelItem = false
                self.newArrange = []
                self.selected = []
                self.returnItem = false
                self.exchangeItem = false
                self.modify = false
            }).catch((error) => {
                console.log(error)
            })
        },

        viewExchange(series,row,index,view){
            const self = this;

            this.setViewForm({...this.form,index : index,exchange_row : row})
            this.getExchangeRecord({series : series}).then((response) => {

                if(response.reference_transaction !== null){
                    self.exchangeForm(response.reference_transaction.transaction);
                }
                self.setItemToExchange(response.items);
                self.setItemToReplace(response.replace);
            })

            if(view){
                self.$refs.exchangeViewForm.openDialog();

            }else{
                self.$refs.exchangeForm.openDialog();
            }
        },

        requestExchangeReplacement(v){
            // console.log(v)

            const self = this;
            // const params = {
            //   status : this.form.status,
            //   attr : this.form.attr,
            //   transaction_id : this.form.mdbID,
            //   token_id : this.form.invoice.token_id,
            //   replace : v,
            // }
            self.exchangeItemLoading = true
            this.storeItemReplacement(v).then((response) => {
                self.$ThatsNotify.success({
                    message : response.message
                })
                self.$emit('success')
                // self.setFormRecord(response.data)
                self.exchangeItemLoading = false
                self.requestedExchange = false
                self.cancelItem = false
                self.newArrange = []
                self.selected = []
                self.returnItem = false
                self.exchangeItem = false
                self.modify = false
                self.$refs.exchangeForm.closeDialog();
            }).catch((error) => {
                console.log(error)
            })
        },

        unformatBalance(value){
            return unformat(value)
        },

        orderStatusSelectable(v){


            // (v) => v.seperator !== true
            const {settings, user} = JSON.parse(localStorage.getItem('user'));

            const useRole = user.role_slugs[0];
            if(settings.userDisallowInvoiceCancel.includes(useRole) && v.code  === 'cancel' && v.seperator === false){
                return false
            }

            // console.log(v)

            return v.seperator !== true;
        },

        openHistory(){
            // console.log(this.form)
            this.setModule('sales-transaction')
            this.setRecordId(this.form.mdbID)
            this.$refs.historyWindow.openDialog()
        },

        openReferenceInvoice(v){
            this.$emit('open-window',v);
        },
        enableReason(row){
            // console.log(row)
            if(this.disabled === false ||
                (this.invoice.series !== null && ['full-payment','partial-payment','cancelled'].includes(this.form.attr.payment_status) && (this.cancelItem === true || this.returnItem === true || this.modify === true || this.exchangeItem === true || this.refundItem === true))
            ){
                return false;
            }
            return true
        },

        confirmCreditNote(v){
            // alert(v)
            const self = this;

            const params = {
                status : this.form.status,
                attr : this.form.attr,
                transaction_id : this.form.mdbID,
                items : this.selected,
                action : v
            }

            self.formLoading = true
            this.storeCreditNote(params).then((response) => {

                self.$ThatsNotify.success({
                    message : response.message
                })
                self.$emit('success',"Successfully created")
                self.$emit('open-window',response)
                self.$refs.confirmCreditNoteForm.closeDialog();
                // self.setFormRecord(response.data)
                const lastTblKey = self.tblKey + 1;
                self.setTblKey(lastTblKey)

                // self.closeDialog();

                self.formLoading = false
                self.newArrange = []
                self.selected = []
                self.returnItem = false
                self.modify = false


            })
        },

        checkIfRowSelected(transactionItemId){
            const hasSelected = this.selected.filter((i) => i.id === transactionItemId).length

            return (hasSelected > 0) ? true : false
        },
        removeDiscountFormat(){
            this.form.more_discount.discount = this.$helpers.unformat(this.form.more_discount.discount )
        },
        formatOnBlurMoreDiscount(){
            this.form.more_discount.discount = this.$helpers.formatMoney(this.form.more_discount.discount)
        },
        invoiceDiscountPercent(){
            const percent = parseFloat(this.form.more_discount.percent) / 100;

            const totalItems = this.form.items.map((i) => unformat(i.total),[]).reduce((a,b) => a + b, 0)
            const total  = totalItems * percent;
            if(total > 0){
                this.form.more_discount.discount = this.$helpers.formatMoney(total)
            }else{
                this.form.more_discount.discount = 0;
            }
        },

        invoiceDiscount(){
            const discount = this.$helpers.unformat(this.form.more_discount.discount)
            const totalItems = this.form.items.map((i) => unformat(i.total),[]).reduce((a,b) => a + b, 0)
            const percent = discount / totalItems * 100
            if(discount > 0){

                if(Number.isInteger(percent)){
                    this.form.more_discount.percent = percent
                }else{
                    this.form.more_discount.percent = formatMoney(percent, {
                        symbol: '',
                        format: '%v',
                        thousand: '',
                        decimal: '.',
                        precision: 6
                    })
                }

            }else{
                this.form.more_discount.percent = 0;
            }
        },

        openAddressForm(){
            this.$refs.addressForm.openDialog();
        },

        updateAddress(v){
            console.log("update Address")
            console.log(v)


            let add = ""
            add += (v.attr.address != "" && v.attr.address !== null) ? v.attr.address  : "";

            add += (v.attr.address2.street != "" && v.attr.address2.street !== null) ? ","+v.attr.address2.street  : "";
            add += (v.attr.address2.building!= "" && v.attr.address2.building!== null) ? ","+v.attr.address2.building : "";
            add += (v.attr.address2.zone != "" && v.attr.address2.zone !== null) ? ","+v.attr.address2.zone  : "";
            add += (v.attr.address2.area != "" && v.attr.address2.area !== null) ? "/"+v.attr.address2.area  : "";
            add += (v.attr.address2.city != "" && v.attr.address2.city !== null) ? "/"+v.attr.address2.city  : "";



            this.displayAddress = add.trim();

        },

        requestDelivery(){
            this.requestDeliveryDialog = true

            // this.sendDeliveryRequest();
        },

        getText() {
            let [,text] = this.$refs.descriptionEditor.$children;
            text = text.$el.innerHTML;
            return text;
        },

        clearText() {
            let [,text] = this.$refs.descriptionEditor.$children;
            text.$el.innerHTML = "";
        },
        setDescription(v) {
            let [,text] = this.$refs.descriptionEditor.$children;
            text.$el.innerHTML = v;
        },

        sendDeliveryRequestForm(){
            const self = this;
            const getMessage = this.getText();
            this.requestDeliveryForm.message = getMessage
            this.requestDeliveryForm.invoice = this.form.invoice.series
            this.requestDeliveryForm.id = this.form.mdbID
            if(this.requestDeliveryLoading == false){
                this.requestDeliveryLoading = true
                this.sendDeliveryRequest(this.requestDeliveryForm).then((res) => {
                    self.requestDeliveryLoading = false
                    self.requestDeliveryDialog = false
                    self.$ThatsNotify.success({
                        message : "Request successfully sent."
                    })
                }).catch((error) => {
                    self.$ThatsNotify.warning({
                        message : error.response.data.message
                    })
                })
            }
        },

        handleCheckAllFromDisplayChange(val) {
            var self = this;
            this.isIndeterminateFromDisplay = false;
            this.form.items.forEach((row,index) => {
                self.$set(self.form.items,index, {...row,from_display : val});
            })


        },
        handleCheckedFromDisplayChange(value) {
            const self = this;
            const checked = this.form.items.filter(row => {
                return row.from_display === true
            }).map(e => e.from_display).length;

            const isIndeterminate = checked > 0 && checked < this.form.items.length;

            const checkAllFromDisplay = checked === this.form.items.length

            this.$nextTick(() => {
                self.isIndeterminateFromDisplay = isIndeterminate
                self.checkAllFromDisplay = checkAllFromDisplay
            });

            /* this.sele
        console.log((checked > 0 && checked < this.form.items.length)) */
        },

        issueVoucher(){
            const validAmount = this.form.payments.filter((i) => {
                return ['cash','credit-card','wire-transfer','cheque','tap'].includes(i.method) && ['no'].includes(i.canceled)
            },[]).map((i) => {
                return unformat(i.amount)
            },[]).reduce((a,b) => a + b, 0);

            const voucher = (this.form.attr.hasOwnProperty('voucher')) ? this.form.attr.voucher : null;

            this.setIssueVoucherFormData({
                customer : {
                    customer_id : this.form.customer_id,
                    name : this.form.attr.name,
                    mobile : this.form.attr.mobile,
                    cx_series : this.form.attr.cx_series
                },
                document : {
                    series : this.form.invoice.series,
                    total : this.form.total,
                    valid_amount : validAmount,
                    attr : this.form.attr,
                    id : this.form.mdbID,

                },
            })

            this.$refs.issueVoucherDialogForm.openDialog();

        },
        issueVoucherSuccess(v){
            console.log("IssueVoucher")
            console.log(v)
            console.log("v.data.document_attr")
            console.log(v.data.document_attr)

            if(v.hasOwnProperty('document_attr')){
                this.form.attr = {...this.form.attr,...v.document_attr}

                console.log(this.form.attr)
                this.$forceUpdate();
            }
        },

    },

    computed: {

        ...mapGetters('InvoiceModule',
            [
                'formStatus',
                'formRecord',
                'viewLoading',
                'tblKey',
            ]),

        ...mapGetters('AuthModule',[ 'user', ]),

        itemSeries : function(){
            var self = this;
            const filteredSeries = self.form.items.filter((item) => {
                return (item.code !== "" && item.code !== null)
            }).map(function(key){
                return key.code
            })


            return filteredSeries;
        },

        noInvoiceDisabled : function(){
            if(this.formStatus === 'create' || this.form.invoice === null || (this.form.invoice !== null && this.form.invoice.series === null)){
                return true;
            }
            return false
        },


        invoice : function(){
            let inv = {
                series : null,
                date : "",
                balance : 0,
                paid : 0,
                attachment : [],
                payments : [],
            }

            if(this.formStatus == 'update'){
                if(this.formRecord != null && this.formRecord.invoice !== null){
                    inv = this.formRecord.invoice
                }

            }

            inv.balance = unformat(inv.balance)
            return inv
        },


        showSalespersonCard : function(){
            return this.checkSystemSupport || this.checkShowroomManager;
        },

        checkSalesExecutive : function(){
            return this.$helpers.isSalesExecutive();
        },
        checkSystemSupport : function(){
            return this.$helpers.isSystemAdministrator();
        },
        allowEditAddress : function(){
            // return this.$helpers.isSystemAdministrator() || this.$helpers.isDeliveryTeam();
            return true;
        },
        checkShowroomManager : function(){
            return this.$helpers.isShowroomManager();
        },
        checkAccounting : function(){
            return this.$helpers.isAccounting();
        },
        showCheckBox : function(){
            if(this.formStatus == 'create' || (this.formStatus == 'update' && (this.returnItem === true || this.cancelItem === true || this.exchangeItem === true || this.refundItem === true))){
                return true;
            }

            return false;
        },
        showCheckBoxCreditNote : function(){
            if(this.formStatus == 'update' && this.modifyReturmItem === true){
                return true;
            }

            return false;
        },
        compareValues(key, order = 'asc') {
            return function innerSort(a, b) {
                if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
                    // property doesn't exist on either object
                    return 0;
                }

                const varA = (typeof a[key] === 'string')
                    ? a[key].toUpperCase() : a[key];
                const varB = (typeof b[key] === 'string')
                    ? b[key].toUpperCase() : b[key];

                let comparison = 0;
                if (varA > varB) {
                    comparison = 1;
                } else if (varA < varB) {
                    comparison = -1;
                }
                return (
                    (order === 'desc') ? (comparison * -1) : comparison
                );
            };
        },

        hasSelected(){
            if(this.selected.length > 0){
                return false;
            };
            return true
        },



        hasInvoice(){
            if(this.form.invoice !== null && this.form.invoice.series !== null){
                return true;
            }
            return false;
        },

        toBeOrder(){
            return this.form.items.map(i => i.tbo).lenth;
        },

        creditNoteSelectionLength(){
            return this.creditNoteSelection.length;
        },


        editCustomerField(){
            return this.disabled && this.customerField
        },

        documentEntry(){
            const {document} = this.formOriginal.attr;


            if(document.entry !== null && document.entry !== ""){
                return true;
            }
            return false
        },

        remainingCreditNote(){

            if(this.form.credit_notes.length > 0){



                const creditNoteItems = this.form.credit_notes.filter((i) => i.status === 'approved',[]).map((i) => i.total,0).reduce((a,b) => a + b, 0)



                /*           const creditNoteItems = this.form.credit_notes.map(i => {
            const itemTotal = i.items.map((innerItem) =>unformat(innerItem.total),[]).reduce((a,b) => a + b);
            let total = 0;
              if(i.attr.hasOwnProperty('more_discount') && i.attr.more_discount.enable === true){
                total = itemTotal - (itemTotal * (i.attr.more_discount.percent/ 100));
              }
            return total
          },[]) */


                /* console.log(creditNoteItems)

          const total = this.form.credit_notes.map(i => {
            const itemTotal = i.items.map((innerItem) =>unformat(innerItem.total),[]).reduce((a,b) => a + b);

              if(i.attr.hasOwnProperty('more_discount') && i.attr.more_discount.enable === true){
                total = itemTotal - (itemTotal * (i.attr.more_discount.percent/ 100));
              }
            return total
          },[]).reduce((a,b) => a + b); */

                return creditNoteItems - this.form.credit_note.credit_note_reference.map(i => i.amount).reduce((a,b) => a + b);
            }

            return 0;



            // return (this.form.credit_note_items.map(i => unformat(i.total)).reduce((a,b) => a + b)) - this.form.credit_note.credit_note_reference.map(i => i.amount).reduce((a,b) => a + b);
        },

        contactPersonName: {
            get(){
                return (this.form.attr.contact_person.name !== null && this.form.attr.contact_person.name !== null) ?  `${this.form.attr.contact_person.name}/${this.form.attr.contact_person.mobile}` : ""

            },
            set(value){
                return value
            }
        }



    },

    watch: {
        viewLoading : function(v){
            this.formLoading = v
        },
        tblKey : function(){
            // this.initSortable();
        },

/*         'form.total': function(newAmount) {
            if (newAmount) {
                let amountWithoutCommas = this.removeCommas(newAmount);
                if (!isNaN(amountWithoutCommas)) {
                    // let roundedAmount = this.roundAmount(amountWithoutCommas);
                    this.form.total = this.addCommas(amountWithoutCommas);
                } else {
                    this.form.total = '0.00';
                }
            } else {
                this.form.total = '0.00';
            }
        }, */

        // viewLoading : function(v){
        //     if(v === false && this.formStatus === 'update'){
        //          if(this.form.invoice !== null  && (v.invoice.hasOwnProperty('series') && v.invoice.series !== null)){
        //            alert("watch viewloading invoiced")

        //          }else{
        //            alert("watch viewloading update quotation")
        //          }
        //     }
        // },
        formRecord : function(v){
            const self = this;
            this.active = 0;
            this.disabled = false
            // alert(this.formStatus);

            if(this.formStatus == 'update'){
                // alert("update")
                // if(!this.viewLoading){
                if(v.invoice !== null && (v.invoice.hasOwnProperty('series') && v.invoice.series !== null)){
                    // alert()
                    if(this.modify === false){
                        this.disableSortable();
                    }
                    this.disabled = true
                    this.tabs = [
                        { text: 'Sales Details', slot: 'form-slot' },
                        { text: 'Payments', slot: 'invoice-slot' },
                        { text: 'Attachments', slot: 'form-attachment' }
                    ]

                }else{
                    this.form.invoice = {
                        series : null,
                        date : "",
                        balance : 0,
                        paid : 0,
                        attachment : [],
                        payments : [],
                    }

                }
                // }


                const jsonParse = JSON.parse(JSON.stringify(this.formRecord));

                if(jsonParse.hasOwnProperty('attr')){
                    jsonParse.attr = {...this.form.attr,...jsonParse.attr}
                }
                jsonParse.more_discount = {...this.form.more_discount,...jsonParse.more_discount}


                jsonParse.credit_note = {...this.form.credit_note,...jsonParse.credit_note}
                // jsonParse.credit_memo = [...this.form.credit_memo,...jsonParse.credit_memo]
                // jsonParse.credit_note_items = [...this.form.credit_note_items,...jsonParse.credit_note_items]


                // const sortItems =

                // jsonParse.items = jsonParse.items.sort((a, b) => a.sort > b.sort);
                jsonParse.items = jsonParse.items.sort((a, b) => (a.sort > b.sort ? 1 : -1));
                // jsonParse.items = jsonParse.items.sort(self.compareValues('sort','asc'));


                jsonParse.attr = {
                    ...jsonParse.attr,
                    address2 : {
                        street : null,
                        building : null,
                        zone : null,
                        area : null,
                        city : null,
                        ...jsonParse.attr.address2
                    },
                }


                console.log("jsonParse.attr")
                console.log(jsonParse.attr)
                if(!jsonParse.attr.hasOwnProperty('customer_service')){
                    jsonParse.attr.customer_service = null;
                }


                this.form = jsonParse;
                this.formOriginal = JSON.parse(JSON.stringify(jsonParse));

                // this.newArrange = jsonParse.items;

                if(this.form.hasOwnProperty('credit_note_items') && this.form.credit_note_items.length > 0){
                    this.tabs = [...this.tabs,{ text: 'Credit Notes', slot: 'credit-notes' }];
                }
                if(this.form.hasOwnProperty('delivery_notes') && this.form.delivery_notes.length > 0){
                    this.tabs = [...this.tabs,{ text: 'Delivery Notes', slot: 'delivery-notes' }];
                }

                if(this.form.hasOwnProperty('purchase_request_item') && this.form.purchase_request_item.length > 0){
                    const tbs = this.tabs.map(e => e.slot);

                    if(!tbs.includes('purchase-request')){
                        this.tabs = [...this.tabs,{ text: 'Purchase Request', slot: 'purchase-request' }];
                    }

                }

                if(this.form.hasOwnProperty('cancellation') && this.form.cancellation.length > 0){
                    const tbs = this.tabs.map(e => e.slot);

                    if(!tbs.includes('cancelled-items')){
                        this.tabs = [...this.tabs,{ text: 'Cancelled Items', slot: 'cancelled-items' }];
                    }

                }

                if(this.form.hasOwnProperty('exchange') && this.form.exchange.length > 0){
                    const tbs = this.tabs.map(e => e.slot);

                    if(!tbs.includes('exchange-items')){
                        this.tabs = [...this.tabs,{ text: 'Exchange Items', slot: 'exchange-items' }];
                    }

                }

                if(this.form.hasOwnProperty('refund') && this.form.refund.length > 0){
                    const tbs = this.tabs.map(e => e.slot);

                    if(!tbs.includes('refund-items')){
                        this.tabs = [...this.tabs,{ text: 'Refund Items', slot: 'refund-items' }];
                    }

                }



                let add = ""
                add += (v.attr.address != "" && v.attr.address !== null) ? v.attr.address  : "";

                add += (v.attr.address2.street != "" && v.attr.address2.street !== null) ? ","+v.attr.address2.street  : "";
                add += (v.attr.address2.building!= "" && v.attr.address2.building!== null) ? ","+v.attr.address2.building : "";
                add += (v.attr.address2.zone != "" && v.attr.address2.zone !== null) ? ","+v.attr.address2.zone  : "";
                add += (v.attr.address2.area != "" && v.attr.address2.area !== null) ? "/"+v.attr.address2.area  : "";
                add += (v.attr.address2.city != "" && v.attr.address2.city !== null) ? "/"+v.attr.address2.city  : "";



                this.displayAddress = add.trim();



                if(!this.disabled){
                    const formItemVar = this.formItemVar();
                    this.form.items.push(formItemVar)
                }


                if(Object.keys(v).length !== 0 && v.constructor === Object){
                    this.customer = [{
                        opt : this.form.attr.name + " "+ this.form.attr.mobile,
                        label :this.form.attr.name,
                        value : this.form.customer_id,
                        mobile : this.form.attr.mobile,
                        address : this.form.attr.address,
                    }];
                }


            }

            if(this.formStatus == 'create'){
                this.FormResetter();
                // this.initSortable();
                // alert("watch create")
            }

            this.getCustomerService().then((response) => {
                this.customerService = response;
            });
        },

        'form.items' : {
            handler: function(v) {
                if(v.length > 0){
                    const total = v.map((i) => {
                        return unformat(i.total);
                    },[]).reduce((a,b) => {
                        return a + b;
                    },0)


                    const percent = unformat(this.form.more_discount.percent);
                    const discount = total * (percent / 100);

                    const formTotal = formatMoney(total, { symbol: "", precision: 2, thousand: ",", decimal: "." });
                    if(this.form.more_discount.enable){
                        // const discount = unformat(discount);
                        this.form.total = formatMoney((total - discount), { symbol: "", precision: 2, thousand: ",", decimal: "." });

                        console.log("watch enable")
                        console.log(this.form.total)

                    }else{
                        this.form.total = formTotal

                        console.log("watch not")
                        console.log(this.form.total)
                    }

                    this.form.more_discount.discount = discount;
                    this.form.attr.more_discount.discount = discount;


                }

            },
            deep: true
        },
        "form.more_discount.discount" : function(v){
            if(this.form !== null){
                const discount = unformat(v);
                const total = this.form.items.map((i) => unformat(i.total),[]).reduce((a, b) => a + b,0);
                let formattedTotal = formatMoney((total - discount), { symbol: "", precision: 2, thousand: ",", decimal: "." });

                if(this.form.more_discount.enable === false){
                    formattedTotal = formatMoney(total, { symbol: "", precision: 2, thousand: ",", decimal: "." });

                }


                this.form.total = formattedTotal

            }
        },
        "form.more_discount.enable" : function(v){
            if(this.form !== null){
                const percent = unformat(this.form.more_discount.percent);
                const total = this.form.items.map((i) => unformat(i.total),[]).reduce((a, b) => a + b,0);
                // const total = unformat(this.total);

                const discount = total * (percent/100);

                let formattedTotal = formatMoney((total - discount), { symbol: "", precision: 2, thousand: ",", decimal: "." });


                this.form.more_discount.discount = discount;
                if(this.form.attr.hasOwnProperty("more_discount") && this.form.more_discount !== null){
                    this.form.attr.more_discount.discount = discount;
                }
                if(v === false){
                    formattedTotal = formatMoney(total, { symbol: "", precision: 2, thousand: ",", decimal: "." });
                }


                this.form.total = formattedTotal
            }

        },

    },




}
</script>

<style scoped>
.click-to-add-logo{
    font-size: 14px;cursor: pointer;
}



.custom-control {
    position: absolute!important;
    margin: 10px!important;
}

.custom-control.image-delete{
    right: 0;cursor: pointer;
}

.custom-checkbox .custom-control-label::before {
    border-radius: .50rem!important;cursor: pointer;
}

.no-image-gallery{
    padding: 65px;
    text-align: center;
}

.no-image-gallery i.far {
    font-size: 42px;
    color: #959595;
}
a.discount-a{
    margin : 5px;
}

.tab-content{
    height: auto!important;
}

.search-fabric{
    position: absolute;
    width: 250px;
    z-index: 999;
    top: 0;
    left: 0;
    padding: 5px;
    background: #ddd;
    border-radius: 3px;
    margin: 0px 0 0 20px;
}
</style>


<template>
    <div class="page-container">

       <!--  <button type="button" @click="openQuotationForm" class="btn btn-sm btn-elegant">Quotation</button> -->
        <ThatsTable title="Sales Transactions" ref="table" :multipleSelection="checkSystemSupport" :endpoint="endpoint" @openModal="openWindow" @deleteYes="DispatchDelete" @openUpdateModal="OpenUpdateModal" :columns="columns" :height="'calc(100vh - 155px)'" :newBtn="enableNew" :importBtn="enableImport" @import="openUploadWindow" :enableFilter="enableFilter" @print="fnPrint" @openPushModal="openPushModal" @openPaymentPushModal="openPaymentPushModal" @openCreditNotePushModal="openCreditNotePushModal">

            <template #tabs>
                           <!-- <button type="button" @click="active=0">0</button>
           <button type="button" @click="active=1">1</button>
           <button type="button" @click="active=2">2</button>
           <button type="button" @click="active=3">3</button> -->
                <mdb-tabs class="table-tabs" :active="active" default :links="[{ text: 'Invoices', disabled : tableLoading }]"  @activeTab="getActiveTabIndex" />
            </template>
            <template #filter="{dispatchFilter}">
                    <!-- <template v-if="activeTab ==='sales'"> -->
                    <SalesFilter @filter="dispatchFilter" :tabIndex="active" :filterWhere="'invoice'"></SalesFilter>
                <!-- </template> -->
            </template>

            <template #top-right>
                <mdb-dropdown multiLevel class="m-0 barcode-dropdown-toggle" end style="margin-top:-7px!important">
                    <mdb-dropdown-toggle slot="toggle" class="m-0" color="elegant" size="sm">Data Integration</mdb-dropdown-toggle>
                    <mdb-dropdown-menu >
                    <div class="px-2 py-2">
                        <label class="mt-1 mb-1 text-muted small">Live Standard Table</label>
                    </div>
                    <a class="dropdown-item" href="javascript:void(0)" @click="openPushModal">Invoice</a>
                    <a class="dropdown-item" href="javascript:void(0)" @click="openPaymentPushModal">Payments</a>
                    <a class="dropdown-item" href="javascript:void(0)" @click="openCreditNotePushModal">Credit Note</a>
                   
                    <div class="dropdown-divider"></div>

                    <div class="px-2 py-2">
                        <label class="mt-1 mb-1 text-muted small">Temporary Table</label>
                    </div>
                    <a class="dropdown-item" href="javascript:void(0)" @click="openPushModalTempTable">Invoice</a>
                    <a class="dropdown-item" href="javascript:void(0)" @click="openPaymentPushModalTempTable">Payments</a>       
                    </mdb-dropdown-menu>
                </mdb-dropdown>
            </template>
        </ThatsTable>
        <TransactionSalesOrderForm ref="transactionSalesOrderForm" @success="refreshTable" @delete="formDelete"  :group="3"></TransactionSalesOrderForm>
        <InvoiceForm ref="invoiceForm" @success="refreshTable" @delete="formDelete"  :group="3" @open-window="openInvoice"></InvoiceForm>
        <TransactionForm ref="dialogForm" @success="refreshTable" @payment="successPayment" @delete="formDelete"  :group="3" :key="transactionTblKey"></TransactionForm>
        <TransactionCommonForm ref="transactionCommonForm" @success="refreshTable" @payment="successPayment" @delete="formDelete"  :group="3"></TransactionCommonForm>
        <OnlineOrderForm ref="onlineOrderForm" @success="refreshTable" @delete="formDelete"  :group="3"></OnlineOrderForm>
        <SalesOrderForm ref="salesOrderForm" @success="refreshTable" @delete="formDelete"  :group="3"></SalesOrderForm>
        <CustomerOrderForm ref="customerOrderForm" @success="refreshTable" @delete="formDelete"  :group="3"></CustomerOrderForm>

        <FileUpload @uploadSuccess="fileData" ref="uploadForm" :group="3" :endpoint="'sales/import'"></FileUpload>
        <TableImport @import="importNow" :data="importData" ref="tableImport" :group="3" :loading="importLoading"></TableImport>
        <TableError :data="errorData" ref="tableError" :group="3"></TableError>

        <PushForm ref="pushForm" :group="4"></PushForm>
        <!-- <PaymentPushForm ref="paymentPushForm" :group="4"></PaymentPushForm> -->
        <!-- <CreditNotePushForm ref="creditNotePushFormx" :group="4"></CreditNotePushForm> -->
        <ImportCreditNoteForm ref="creditNotePushForm" :group="4"></ImportCreditNoteForm>

        <ImportInvoiceForm ref="importInvoiceForm" :group="4"></ImportInvoiceForm>
        <ImportPaymentForm ref="paymentPushForm" :group="4"></ImportPaymentForm>

        <IntegrationWindow ref="invoiceIntegrationWindow" :endpoint="'invoices'"></IntegrationWindow>
        <IntegrationWindow ref="paymentIntegrationWindow" :endpoint="'payments'"></IntegrationWindow>


        <el-dialog :title="'Loyalty Program'" :visible.sync="showLoyaltyProgram" :close-on-click-modal="false" width="30%" height="300" class="text-left loyalty-suggestion-form">
        <div class="container text-center">

            <img src="/images/gift-box.png"  class="mb-3"/>
            <h3>Congratulations!</h3>
            <p>The customer qualifies for our loyalty program.</p>


            <mdb-alert color="warning">
                Great news! Our Loyalty Program is now in the testing phase. Your feedback can make it even better! Please try it out and let us know your thoughts. Together, we'll make it amazing!
            </mdb-alert>

            <mdb-btn color="elegant" class="ml-0 mt-5 mb-0" size="md" @click="registerNow" ><i class="el-icon-save text-white cursor-pointer"></i> Register Now</mdb-btn>

        </div>
        </el-dialog>

        <LoyaltyMemberCreateForm ref="loyaltyRegistration"></LoyaltyMemberCreateForm>
    </div>
</template>
<script>


import { mapGetters, mapMutations,mapActions } from 'vuex';
import SalesOrderForm from './SalesOrderForm'
import CustomerOrderForm from '../customer-order/CustomerOrderForm'
import InvoiceForm from './InvoiceForm'
import InvoiceMultipleForm from './InvoiceMultipleForm'
import TransactionForm from './TransactionForm'
import OnlineOrderForm from './OnlineOrderForm'
import TransactionSalesOrderForm from './TransactionSalesOrderForm'
import ThatsTable from './Table'
import FileUpload from '../../components/filemanager/FileUpload'
import {mdbTabs, mdbDropdown,
    mdbDropdownItem,
    mdbDropdownMenu,
    mdbDropdownToggle,mdbBtn, mdbAlert} from 'mdbvue'
import { MessageBox } from 'element-ui';
import SalesFilter from './SalesFilter'
import TableImport from './TableImport'
import TableError from './TableError'
import { userData } from '../../helpers/user'
import PushForm from './PushForm';
import PaymentPushForm from './PaymentPushForm';
/* import CreditNotePushForm from './CreditNotePushForm'; */
import ImportCreditNoteForm from './ImportCreditNoteForm';
import ImportInvoiceForm from './ImportInvoiceForm';
import ImportPaymentForm from './ImportPaymentForm';
import TransactionCommonForm from './TransactionCommonForm';
import IntegrationWindow from '../../components/thats/IntegrationWindow'
import LoyaltyMemberCreateForm from '../loyalty-members/LoyaltyMemberCreateForm'
const demoSalesMethods = {
    testing(){
        alert(1)
    },

    testing2(){
        alert(2)
    }
}
export default {
    data(){
        return {
            showLoyaltyProgram : false,
            endpoint : 'sales/data?view=invoice',    
            activeTab : 'sales',    
            enableFilter : true,    
            columns : [],               
            importLoading : false,        
            active : 0,        
            token : null,
            enableNew : false,        
            enableImport : true,
            importData : [
                {
                    name : "HEAD",
                    slug : "head",
                    columns : [
                        {
                            field: "ecount_code",
                            label: "ecount_code",
                            width: 150
                        }
                    ],
                    rows : [
                        {
                            ecount_code: "20383BH"
                        }
                    ],
                    errors : []
                },
                {
                    name : "DETAILS",
                    slug : "details",
                    columns : [
                        {
                            field: "ecount_code",
                            label: "ecount_code",
                            width: 150
                        }
                    ],
                    rows : [
                        {
                            ecount_code: "20383BH"
                        }
                    ],
                    errors : []
                }
            ],        
            errorData : [
                {
                    name : "HEAD",
                    slug : "head",
                    columns : [
                        {
                            field: "ecount_code",
                            label: "ecount_code",
                            width: 150
                        }
                    ],
                    rows : [
                        {
                            ecount_code: "20383BH"
                        }
                    ],
                    errors : []
                },
                {
                    name : "DETAILS",
                    slug : "details",
                    columns : [
                        {
                            field: "ecount_code",
                            label: "ecount_code",
                            width: 150
                        }
                    ],
                    rows : [
                        {
                            ecount_code: "20383BH"
                        }
                    ],
                    errors : []
                }
            ],             
            importLoading : false,             
            // invoicesWindow : []
        }
    },
    components:{
        PushForm,
        PaymentPushForm,
        /* CreditNotePushForm, */
        OnlineOrderForm,
        SalesFilter,
        CustomerOrderForm,
        SalesOrderForm,
        InvoiceForm,
        TransactionForm,
        TransactionSalesOrderForm,
        ThatsTable,
        FileUpload,
        mdbTabs,
        TableImport,
        TableError,
        InvoiceMultipleForm,
        ImportInvoiceForm,
        ImportPaymentForm,
        ImportCreditNoteForm,
        IntegrationWindow, mdbDropdown,
        mdbDropdownItem,
        mdbDropdownMenu,
        mdbDropdownToggle,
        TransactionCommonForm,
        LoyaltyMemberCreateForm,
        mdbBtn,
        mdbAlert

    },
    created(){
        this.columns = this.salesColumns;
         const loggedUser = userData()
        this.endpoint =  `invoices?user=${loggedUser.user.id}`;
    },   

    mounted(){
        console.log("this.successPayment")
            console.log(this.successPaymentObj)
            const v = this.successPaymentObj.data


           
        if(this.successPaymentObj.success === true){
            
            
            
            if(v.hasOwnProperty('loyalty_membership') && v.loyalty_membership === true){
                this.showLoyaltyProgram = true
            } 

            this.invoiceSetRecord(v)
            this.invoiceStatusUpdate()
            this.$refs.invoiceForm.openDialog(); 
            this.invoiceGetRecordSeries({token_id : v.token_id, series : v.invoice.series})
            this.setSuccessPayment({
                success : false,
                data : {}
            })
        }
        
        
    },
    methods: {
        // 

        ...mapActions('TableV1Module',[
            'resetData',
        ]),
        ...mapMutations('TableV1Module',[
            'SetTotal',
            'SetPage',
            'SetTerm',
            'SetFilters',
            
        ]),
        ...mapActions('DataTableModule',{
            resetDataTableModule :'resetData'
        }),
        ...mapMutations('DataTableModule',{
            setTotalDataTableModule : 'setTotal',
            setPageDataTableModule : 'setPage',
            setTermDataTableModule : 'setTerm',
            setFiltersDataTableModule : 'setFilters',
        }),

        ...mapActions('TransactionModule',[ 'deleteRecord','saveImport','getRecord']),
        ...mapMutations('TransactionModule',[ 'setFormRecord', 'setFormStatusUpdate','setFormStatusCreate','setTblKey']),

        ...mapActions('InvoiceModule',{
            invoiceDelete : 'deleteRecord',
            invoiceGetRecord : 'getRecord',
            invoiceGetRecordSeries : 'getRecordSeries',
        }),
        ...mapMutations('InvoiceModule',{
            invoiceSetRecord : 'setFormRecord',
            invoiceStatusUpdate : 'setFormStatusUpdate',
            invoiceSetTblKey : 'setTblKey',
            setSuccessPayment : 'setSuccessPayment',
        }),

        ...mapActions('SalesOrderModule',{
            salesOrderDelete : 'deleteRecord',
            salesOrderGetRecord : 'getRecord',
        }),
        ...mapMutations('SalesOrderModule',{
            salesOrderSetFormRecord : 'setFormRecord',
            salesOrderSetFormStatusUpdate : 'setFormStatusUpdate',
        }),

        ...mapActions('OnlineOrderModule',{
            customerOrderDelete : 'deleteRecord',
            customerOrderGetRecord : 'getRecord',
        }),
        ...mapMutations('OnlineOrderModule',{
            customerOrderSetFormRecord : 'setFormRecord',
            customerOrderSetFormStatusUpdate : 'setFormStatusUpdate',
        }),

        ...demoSalesMethods,


        formDelete(record){
            const items = [];
            items.push(record.row);
            this.DispatchDelete(items);
        },
        DispatchDelete(items){
            var self = this;
/* invoiceDelete
 */
            if(this.activeTab === 'sales'){
                self.invoiceDelete(items).then((response) => {
                    self.$ThatsNotify.success({
                        message : `(${response.deleted}) record was successfully deleted.`
                    })
                self.refreshTable();
                })
               
            }
            if(this.activeTab === 'sales-quotation'){
                self.deleteRecord(items).then((response) => {
                    self.$ThatsNotify.success({
                        message : `(${response.deleted}) record was successfully deleted.`
                    })
                self.refreshTable();
                })
               
            }

            if(this.activeTab === 'sales-order'){  
                self.salesOrderDelete(items).then((response) => {
                    self.$ThatsNotify.success({
                        message : `(${response.deleted}) record was successfully deleted.`
                    })
                self.refreshTable();
                })
            }

            if(this.activeTab === 'online-orders'){

                self.customerOrderDelete(items).then((response) => {
                    self.$ThatsNotify.success({
                        message : `(${response.deleted}) record was successfully deleted.`
                    })
                self.refreshTable();
                })
               /*  const form  = {...obj.row,items : [],attachment : []}           
                self.customerOrderSetFormStatusUpdate()
                self.customerOrderSetFormRecord(form)
                self.customerOrderGetRecord({token_id : obj.row.token_id})
                this.$refs.customerOrderForm.openDialog() */
            
            }

            
            this.closeWindow()
        },
        OpenUpdateModal(obj){
            var self = this;

            console.log("open update modal",this.activeTab,obj)
            if(this.activeTab === 'sales'){
                    const form  = {...obj.row,items : [],attachment : [],invoice : {
                        series : null,
                        date : "",
                        balance : 0,
                        paid : 0,
                        attachment : [],
                        payments : [],
                    },
                    credit_note : {
                        total : 0,
                        status : null,
                        credit_note_reference : []
                    },
                    creditnotes : [],
                    credit_note_items : [],
                    cancellation : [],
                    cancellation_item : [],
                    refund : [],
                    refund_item : [],
                    exchange : [],
                    credit_memo : [],
                    exchange_item : [],
                    enable_push : false,
                    location : null,
                    } 


                    if(!form.attr.hasOwnProperty('corporate')){
                            form.attr.corporate = false
                        }
                        if(!form.attr.hasOwnProperty('contact_person')){
                            form.attr.contact_person = {
                                name : null,
                                mobile : null,
                                attr : {
                  
                                  address: null,
                                  address2 : {
                                      street : null,
                                      building : null,
                                      zone : null,
                                      area : null,
                                      city : null,
                                  },
                                  designation : null,
                                  email : null,
                                  first_name : null,
                                  middle_name : null,
                                  last_name : null,
                                  salutation : null
                                }
                              }
                        }
                    self.invoiceStatusUpdate()
                    self.invoiceSetRecord(form)
                    self.invoiceGetRecordSeries({token_id : obj.row.token_id, series : obj.row.series})
                        self.$refs.invoiceForm.openDialog()

                    this.invoiceSetTblKey(this.salesTblKey + 1);
                    this.$refs.invoiceForm.openDialog();

                    // self.openWindow()
          
            }
            if(this.activeTab === 'sales-quotation'){
                    const form  = {...obj.row,items : [],attachment : [],invoice : {
                        series : null,
                        date : "",
                        balance : 0,
                        paid : 0,
                        attachment : [],
                        payments : [],
                    },
                    showroom : {
                                location : null,
                                prefix : null
                            },
                    credit_notes : [],
                    credit_note : {
                        total : 0,
                        status : null,
                        credit_note_reference : []
                    }
                    } 
                    self.setFormStatusUpdate()
                    self.setFormRecord(form)
                    self.getRecord({token_id : obj.row.token_id})
                    self.setTblKey(this.transactionTblKey + 1);
                    self.$refs.dialogForm.openDialog();
               
            }

            if(this.activeTab === 'sales-order'){
                const form  = {...obj.row,items : [],attachment : []}           
                self.salesOrderSetFormStatusUpdate()
                self.salesOrderSetFormRecord(form)
                self.salesOrderGetRecord({token_id : obj.row.token_id})
                this.$refs.salesOrderForm.openDialog()
            }

            if(this.activeTab === 'online-orders'){
                const form  = {...obj.row,items : [],attachment : [],invoice : {
                    series : null,
                    date : "",
                    balance : 0,
                    paid : 0,
                    attachment : [],
                    payments : [],
                }}           
                self.customerOrderSetFormStatusUpdate()
                self.customerOrderSetFormRecord(form)
                self.customerOrderGetRecord({token_id : obj.row.token_id})
                this.$refs.onlineOrderForm.openDialog()
            
            }
            
        },
        openUploadWindow(){
             var self = this;
            if(this.activeTab === 'sales' || this.activeTab === 'sales-quotation'){

                this.$refs.uploadForm.openDialog()
            }

            if(this.activeTab === 'sales-order'){
                // this.$refs.salesOrderForm.openDialog()
            }

            if(this.activeTab === 'online-orders'){
                    
            }
            
           
        },
        
        openPushModalTempTable(){
           this.$refs.importInvoiceForm.openDialog();       
        },
        openPaymentPushModalTempTable(){
           this.$refs.paymentPushForm.openDialog();       
        },
        openPushModal(){
            // this.$refs.importInvoiceForm.openDialog();
            this.$refs.invoiceIntegrationWindow.openDialog();
                     
        },
        openPaymentPushModal(){
            this.$refs.paymentIntegrationWindow.openDialog();
                     
        },
        openCreditNotePushModal(){
            this.$refs.creditNotePushForm.openDialog();
        },
        openWindow(){

            var self = this;
            if(this.activeTab === 'sales'){

                console.log("this.$refs")
                console.log(this.$refs.dialogForm)
                //this.invoiceSetTblKey(this.salesTblKey + 1);
                this.setTblKey(this.transactionTblKey + 1);
                // this.setFormStatusCreate();
                // this.$refs.invoiceForm.openDialog();

                this.$nextTick(() => {
                    this.$refs.dialogForm.openDialog();
                })
                //this.$refs.dialogForm.openDialog();
            }
            if(this.activeTab === 'sales-quotation'){
                this.setTblKey(this.transactionTblKey + 1);
                /* transactionColumns
transactionData
transactionPage
transactionTotal
transactionLength
transactionGetLoading
transactionTblKey */
                // this.setFormStatusCreate();
                // this.$refs.invoiceForm.openDialog();
                this.$refs.dialogForm.openDialog();
            }

            if(this.activeTab === 'sales-order'){
                this.$refs.salesOrderForm.openDialog()
            }

            if(this.activeTab === 'online-orders'){
            
            }
            
        },
        closeWindow(){
            this.$refs.dialogForm.closeDialog()
        },
        refreshTable(){
            
            this.setter()
            this.$refs.table.refreshTable()
        },
        importProduct(){
           this.$refs.uploadForm.openDialog()
        },
        fileData(v){
            this.importData = v
            // console.log(v)
           
           this.$refs.tableImport.openDialog()
        },

        importNow(v){
            if(!this.importLoading){
                this.importLoading = true
                this.saveImport({ import : v}).then((response) => {
                    this.$refs.table.refreshTable()

                    this.importLoading = false;

                    if(response.success > 0){
                        this.$ThatsNotify.success({
                            message : response.message,
                            timeOut : 15000
                        })
                    }
                    this.$refs.tableImport.closeDialog();

                    const err = response.errors.map((i) => {
                        return i.rows.length
                    }).reduce((a,b) =>{
                        return a + b;
                    }); 

                    if(err > 0){
                        this.errorData = response.errors
                        this.$refs.tableError.openDialog()
                    }

                    return;

                }).catch((error) => {
                    this.importLoading = false,
                    this.$ThatsNotify.warning({
                        message : error.message,
                        timeOut : 15000
                    })
                    this.$refs.table.refreshTable()
                })
            }
            
        },

        setter(){
             const loggedUser = userData()
            let resetDataEndpoint = `invoices?user=${loggedUser.user.id}`;
            if(this.activeTab === 'sales'){
                this.columns = this.salesColumns
                resetDataEndpoint = `invoices?user=${loggedUser.user.id}`;
                this.enableNew = true;
                this.enableImport = true;
                this.enableFilter = true;
            }
            
            if(this.activeTab === 'sales-quotation'){
                this.columns = this.transactionColumns
                resetDataEndpoint = 'sales/quotation?user='+loggedUser.user.id+'&role='+this.checkSystemSupport;
                this.enableNew = true;
                this.enableImport = false;
                this.enableFilter = true;
            }

            if(this.activeTab === 'sales-order'){
                this.columns = this.salesOrderColumns
                resetDataEndpoint = 'sales-order?view='+loggedUser.user.id+'&role='+this.checkSystemSupport;
                this.enableNew = false;
                this.enableImport = false;
                this.enableFilter = false;
            }

            if(this.activeTab === 'online-orders'){
               
                this.columns = this.onlineOrderColumns
                resetDataEndpoint = 'online-order?view='+loggedUser.user.id+'&role='+this.checkSystemSupport;
                this.enableNew = false;
                this.enableImport = false;
                this.enableFilter = false;
            }

            this.endpoint = resetDataEndpoint
            
            this.resetDataTableModule(resetDataEndpoint);
        },

        getActiveTabIndex(index){


            const tabs = ['sales','sales-quotation'];
            this.activeTab = tabs[index];
            this.active = index;
            this.SetPage(1)
            this.SetTotal(0);
            this.SetFilters({});
            this.setter()
            // commit('SetTotal', 0)
            /* SetFilters: (state, payload) => {
            state.filters = payload
        }, */
        },

        fnPrint(obj){
        var strWindowFeatures = "location=yes,height=600,width=900,scrollbars=yes,status=yes";

        let session = JSON.parse(localStorage.getItem('user'));
        let token = session.token;

        const locationId = session.location.id

        if(this.activeTab === 'sales'){
            const win = window.open(`/sales/invoice/${obj.row.token_id}?location=${locationId}&token=${token}`, "_blank", strWindowFeatures);
            win.document.title = 'Print';
            win.print()
        }
        
        if(this.activeTab === 'sales-quotation'){
                const win = window.open(`/sales/transactions-v3/${obj.row.token_id}?location=${locationId}&token=${token}`, "_blank", strWindowFeatures);
            win.document.title = 'Print';
            win.print()
        }

       

        // this.printUrl = url
        // this.$refs.printWindow.openDialog();
      },

      successPayment(v){
        //   console.log(v)
        // let resetDataEndpoint = 'sales/invoice';
        this.active = 0;
        this.invoiceSetTblKey(this.salesTblKey + 1);
        this.invoiceSetRecord(v)
        this.invoiceStatusUpdate()
        this.$refs.dialogForm.closeDialog();
        // this.SetPage(1)
        // this.SetTotal(0);
        // this.SetFilters({});
        // this.resetData('sales/invoice');
        // self.getRecord({token_id : obj.row.token_id})
        this.$refs.invoiceForm.openDialog();


      },

      openInvoice(v){
        //   console.log(v)

        this.invoiceGetRecordSeries({series : v}).then((res) => {
                console.log("open window");
                console.log(res);
        })
      },
      openQuotationForm(){
        this.$refs.transactionCommonForm.openDialog()
      },
      registerNow(){
        this.$refs.loyaltyRegistration.openDialog();
        this.$refs.loyaltyRegistration.triggerFindCustomer(this.currentTransaction);
        this.showLoyaltyProgram = false
      }

    },
    computed: {
        ...mapGetters('InvoiceModule',{
            salesColumns : 'columns',
            salesData : 'data',
            salesPage: 'page',
            salesTotal: 'total',
            salesLength: 'length',
            salesGetLoading: 'getLoading',
            salesTblKey: 'tblKey',
            invoicesWindow: 'invoiceData',
        }),
        ...mapGetters('TransactionModule',{
            transactionColumns : 'columns',
            transactionData : 'data',
            transactionPage: 'page',
            transactionTotal: 'total',
            transactionLength: 'length',
            transactionGetLoading: 'getLoading',
            transactionTblKey: 'tblKey',
        }),
        ...mapGetters('SalesOrderModule',{
            salesOrderColumns : 'columns',
            salesOrderData : 'data',
            salesOrderPage: 'page',
            salesOrderTotal: 'total',
            salesOrderLength: 'length',
            salesOrderGetLoading: 'getLoading',
        }),
        ...mapGetters('OnlineOrderModule',{
            onlineOrderColumns : 'columns',
            onlineOrderData : 'data',
            onlineOrderPage: 'page',
            onlineOrderTotal: 'total',
            onlineOrderLength: 'length',
            onlineOrderGetLoading: 'getLoading',
        }),
        ...mapGetters('TableV1Module',[ 'tableLoading']),
        ...mapGetters('InvoiceModule',[ 'data', 'page', 'total', 'page', 'length', 'getLoading']),
        ...mapGetters('InvoiceModule',{successPaymentObj : 'successPayment'}),

        checkSystemSupport : function(){
            const systemSupport = this.$helpers.isSystemAdministrator();
            return systemSupport
        }
    },
}


</script>
<style scoped>
.container {
    position: relative;
    min-height: 340px;
  }
  .placement {
    position: absolute;
    right: 0;
  }

  .display-block{
      opacity: 1!important;pointer-events : initial!important
  }
</style>
import { ApiService } from '../../services'
export const DataIntegrationModule = {
    namespaced: true,
    state: {},
    actions: {
        integrate({}, payload) {
            return new Promise((resolve, reject) => {
                ApiService.store(`data-integration/${payload.endpoint}`,payload).then((response) => {
                    resolve(response.data)
                }).catch((error) => {
                    reject(error)
                })
            })
        },
      
    },

}


